import { Block } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import {
  mkKayttovaratTestId,
  mkYleiskatsausTestId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import MuutVaratForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/OmassaKaytossaMuutVaratForm'
import KayttovaratForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/KayttovaratForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { TilintarkastusForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'
import VahvistaEsitaytetytTiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/VahvistaEsitaytetytTiedot'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const KayttovaratPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const verticalMarginToken = 'xl'
  const onErrorRef = useRef<HTMLDivElement>(null)
  const {
    vaatimus: { isPrincipalUnderaged },
  } = getTilintarkastusStore()

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock
        data-test-id={mkKayttovaratTestId('page')}
      >
        <PageStepHeading
          heading={t('kayttovarat')}
          steps={TILINTARKASTUS_STEPS}
        />
        <FormErrorSummary ref={onErrorRef} />
        <MultiParagraphText
          text={t(
            isOmaisuusluettelo()
              ? 'ohjeKayttovaratInfo1'
              : 'ohjeKayttovaratTilikausiInfo1'
          )}
        />
        {!isPrincipalUnderaged && (
          <Block mt="s">
            <MultiParagraphText text={t('ohjeKayttovaratTaysi-ikaisenInfo')} />
          </Block>
        )}
        <VahvistaEsitaytetytTiedot
          tiedot={['kayttovarakaytanto', 'muutOmassaKaytossaOlevatVarat']}
          dataTestId={mkYleiskatsausTestId('kayttovaratPage-isTarkistettu')}
        >
          <DividerLine mb={verticalMarginToken} mt={verticalMarginToken} />
          {!isPrincipalUnderaged && (
            <>
              <KayttovaratForm />
              <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            </>
          )}

          <MuutVaratForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        </VahvistaEsitaytetytTiedot>

        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default KayttovaratPage
