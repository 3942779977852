import keyMirror from 'keymirror'
import { addYears, format, startOfDay } from 'date-fns'
import {
  AsiaServiceArea,
  AsiaServiceAreaId,
  AsiaTypeId,
  AsiaTypeServiceArea,
} from 'asia-common/src/vtj/koodisto/asia-enums'

export const Retention = keyMirror({
  PYSYVA: null,
  SAILYTYSAIKA_0_VUOTTA: null,
  SAILYTYSAIKA_4_VUOTTA: null,
  SAILYTYSAIKA_5_VUOTTA: null,
  SAILYTYSAIKA_10_VUOTTA: null,
  SAILYTYSAIKA_20_VUOTTA: null,
  SAILYTYSAIKA_50_VUOTTA: null,
})
export type RetentionId = (typeof Retention)[keyof typeof Retention]
export const allRetentionIds = Object.keys(Retention) as RetentionId[]

export const RetentionInYears: { [key in RetentionId]: number } = {
  [Retention.PYSYVA]: -1,
  [Retention.SAILYTYSAIKA_0_VUOTTA]: 0,
  [Retention.SAILYTYSAIKA_4_VUOTTA]: 4,
  [Retention.SAILYTYSAIKA_5_VUOTTA]: 5,
  [Retention.SAILYTYSAIKA_10_VUOTTA]: 10,
  [Retention.SAILYTYSAIKA_20_VUOTTA]: 20,
  [Retention.SAILYTYSAIKA_50_VUOTTA]: 50,
}

export const RetentionReason = keyMirror({
  TOIMINTATAPA: null,
  KANSALLISARKISTON_PAATOS: null,
})
export type RetentionReasonId = (typeof RetentionReason)[keyof typeof RetentionReason]
export const allRetentionReasonIds = Object.keys(RetentionReason) as RetentionReasonId[]

export const RetentionToReason: { [key in RetentionId]: RetentionReasonId } = {
  [Retention.PYSYVA]: RetentionReason.KANSALLISARKISTON_PAATOS,
  [Retention.SAILYTYSAIKA_0_VUOTTA]: RetentionReason.TOIMINTATAPA,
  [Retention.SAILYTYSAIKA_4_VUOTTA]: RetentionReason.TOIMINTATAPA,
  [Retention.SAILYTYSAIKA_5_VUOTTA]: RetentionReason.TOIMINTATAPA,
  [Retention.SAILYTYSAIKA_10_VUOTTA]: RetentionReason.TOIMINTATAPA,
  [Retention.SAILYTYSAIKA_20_VUOTTA]: RetentionReason.TOIMINTATAPA,
  [Retention.SAILYTYSAIKA_50_VUOTTA]: RetentionReason.TOIMINTATAPA,
}

export interface RetentionMetadata {
  defaultRetentionId: RetentionId
  retentionIds: RetentionId[]
}

const holhousEdunvalvonnanSeurantaServiceAreas: AsiaServiceAreaId[] = [
  AsiaServiceArea.EDUNVALVONTAVALTUUTUS_JA_EDUNVALVONNAN_ALKAMINEN,
  AsiaServiceArea.LUVAT,
  AsiaServiceArea.TILINTARKASTUS,
]

export interface RetentionDates {
  retentionStartDate: string
  retentionEndDate: string | null
}

export const getRetentionDates = (
  retentionStartTimestamp: Date,
  asiaTypeId: AsiaTypeId,
  retentionId: RetentionId | null
): RetentionDates => {
  const start = startOfDay(retentionStartTimestamp)
  if (
    retentionId !== null &&
    retentionId !== Retention.PYSYVA &&
    !holhousEdunvalvonnanSeurantaServiceAreas.includes(AsiaTypeServiceArea[asiaTypeId])
  ) {
    const end = addYears(start, RetentionInYears[retentionId])
    return {
      retentionStartDate: format(start, 'yyyy-MM-dd'),
      retentionEndDate: format(end, `yyyy-MM-dd`),
    }
  } else {
    return {
      retentionStartDate: format(start, 'yyyy-MM-dd'),
      retentionEndDate: null,
    }
  }
}
