import React, { PropsWithChildren } from 'react'
import { observer } from 'mobx-react'
import { useLocation } from 'react-router-dom'
import {
  Block,
  Button,
  Expander,
  ExpanderContent,
  ExpanderGroup,
  ExpanderTitleButton,
  ExternalLink,
  Heading,
  IconLogin,
  InlineAlert,
  Link,
  Paragraph,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import { useTranslation } from 'react-i18next'
import QueryParam from 'common/src/vtj/QueryParam.enum'
import { mkLandingPageTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-test-id'
import {
  getLandingLupaHelpUrl,
  getPaatostiliHelpUrl,
  getOverviewAdultHelpUrl,
  getOverviewEvTasksHelpUrl,
  getOverviewMinorHelpUrl,
  getOverviewWhenLupaHelpUrl,
  lomakkeetUrl,
  omaisuusluetteloOhjeUrl,
  suomifiViestitUrl,
  valtuuttajanTehtavatOhjeUrl,
  vuositiliOhjeAlaikaiselleUrl,
  vuositiliOhjeTaysikaiselleUrl,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import {
  BorderedContentBlock,
  CenteredWidthLimitedDiv,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { styled } from 'styled-components'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { useGetLoginUri } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/login-redirect'
import { DiscList } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/common'
import { VuositiliAsiakirjatOhje } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/common/VuositiliAsiakirjatOhje'
import { PaatostiliAsiakirjatOhje } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/common/PaatostiliAsiakirjatOhje'
import { OmaisuusluetteloOhje } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/common/OmaisuusluetteloOhje'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const LandingPage: React.FC = observer(() => {
  const [t] = useTranslation()
  const location = useLocation()
  const { lang } = useAsiointiUserStore()
  const isAuthenticationCancelled = location.search.includes(
    `${QueryParam.SUOMIFI_AUTH_CANCELED}=true`
  )
  const isAuthenticationFailed = location.search.includes(
    `${QueryParam.SUOMIFI_AUTH_FAILURE}=true`
  )

  return (
    <>
      <HeroBackground>
        <CenteredWidthLimitedDiv>
          <Heading variant="h1hero" color="whiteBase">
            {t('edunvalvontaHeader')}
          </Heading>
          <Block mt="xl" />
          <HeroText>{t('landingHeroDescription')}</HeroText>
        </CenteredWidthLimitedDiv>
      </HeroBackground>

      <CenteredWidthLimitedDiv>
        <Heading variant="h1" as={'h2'}>
          {t('landingMitaVoitTehdaHeader')}
        </Heading>

        <BorderedContentBlock mt={'m'} padding={'xl'}>
          {isAuthenticationCancelled && (
            <>
              <InlineAlert status="warning" mb="s">
                {t('authenticationCanceled')}
              </InlineAlert>
            </>
          )}
          {isAuthenticationFailed && (
            <>
              <InlineAlert
                mb="s"
                status="error"
                labelText={t('authenticationFailure_1')}
              >
                <Paragraph>{t('authenticationFailure_1')}</Paragraph>
                <Paragraph>{t('authenticationFailure_2')}</Paragraph>
                <Paragraph>{t('authenticationFailure_3')}</Paragraph>
              </InlineAlert>
            </>
          )}

          <Text variant="body">
            {t('asiointiIntro1') + ' '}
            <Link underline="initial" href={lomakkeetUrl(lang)}>
              {t('asiointiIntroLink')}
            </Link>
            {' ' + t('asiointiIntro2')}
          </Text>

          <ExpanderGroup
            ariaCloseAllText={t('suljeKaikki')}
            ariaOpenAllText={t('suljeKaikki')}
            openAllText={t('avaaKaikki')}
            closeAllText={t('suljeKaikki')}
          >
            <OmaisuusluetteloOsio />
            <VuositiliOsio />
            <PaatostiliOsio />
            <LupahakemusOsio />
          </ExpanderGroup>

          <DividerLine mt="xl" mb="xl" />
          <Block mt="m" />
          <ToimiNainOsio />
          <DividerLine mt="xl" mb="xl" />
          <TarvitsekoApuaOsio />
        </BorderedContentBlock>
      </CenteredWidthLimitedDiv>
    </>
  )
})

const ToimiNainOsio = observer(() => {
  const [t] = useTranslation()
  const { lang } = useAsiointiUserStore()
  const loginUri = useGetLoginUri()

  const toimet = ['toimiNain1', 'toimiNain2', 'toimiNain3', 'toimiNain4']
  return (
    <section data-test-id={mkLandingPageTestId('toimi-nain-section')}>
      <Heading variant={'h2'}>{t('toimiNainHeading')}</Heading>
      <Block mt="l" />
      <NumberedList>
        {toimet.map((ohje) => (
          <li key={ohje}>
            <Text variant={'body'}>{t(ohje)}</Text>
          </li>
        ))}
      </NumberedList>
      <AlignedDiv>
        <ExternalLink
          toNewWindow
          labelNewWindow={t('otaViestitKayttoon')}
          href={suomifiViestitUrl(lang)}
        >
          {t('otaViestitKayttoon')}
        </ExternalLink>
      </AlignedDiv>
      <Block mt="xl" />
      <Button
        data-test-id={mkLandingPageTestId('login-button')}
        onClick={() => (window.location.href = loginUri)}
        icon={<IconLogin />}
        fullWidth={false}
      >
        {t('tunnistauduPalveluun')}
      </Button>
    </section>
  )
})

const TarvitsekoApuaOsio = observer(() => {
  const { lang } = useAsiointiUserStore()
  const [t] = useTranslation()

  return (
    <section data-test-id={mkLandingPageTestId('tarvitse-apua-section')}>
      <Heading variant={'h2'}>{t('overviewHelpHeader')}</Heading>
      <Paragraph mt="m" mb="m">
        <Text>{t('overviewHelpDescription')}</Text>
      </Paragraph>
      <Linkit>
        <ExternalLink
          href={getOverviewAdultHelpUrl(lang)}
          variant="accent"
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
        >
          {t('overviewHelpAdultLinkTitle')}
        </ExternalLink>
        <ExternalLink
          href={getOverviewMinorHelpUrl(lang)}
          variant="accent"
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
        >
          {t('overviewHelpMinorLinkTitle')}
        </ExternalLink>
        <ExternalLink
          href={getOverviewEvTasksHelpUrl(lang)}
          variant="accent"
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
        >
          {t('overviewHelpTasksLinkTitle')}
        </ExternalLink>
        <ExternalLink
          href={getOverviewWhenLupaHelpUrl(lang)}
          variant="accent"
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
        >
          {t('overviewHelpWhenLupaLinkTitle')}
        </ExternalLink>
      </Linkit>

      <Block mt="xl" />

      <Puhelinnumerot>
        <Text variant="bold">{t('overviewHelpSupportNumbersHeader')}</Text>
        <BreakspaceText>
          {t('overviewHelpSupportNumbersDescription')}
        </BreakspaceText>

        <Paragraph>
          <Text variant="bold">
            {t('overviewHelpSupportNumbersFinishHeader')}
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>0295 536 256</Text>
        </Paragraph>

        <Paragraph>
          <Text variant="bold">
            {t('overviewHelpSupportNumbersSwedishHeader')}
          </Text>
        </Paragraph>
        <Paragraph>
          <Text>0295 536 305</Text>
        </Paragraph>
      </Puhelinnumerot>
    </section>
  )
})

const LupahakemusOsio: React.FC = observer(() => {
  const [t] = useTranslation()
  const { lang } = useAsiointiUserStore()
  return (
    <Expander data-test-id={mkLandingPageTestId('lupa-section')}>
      <ExpanderTitleButton>{t('lupahakemus')}</ExpanderTitleButton>
      <ExpanderContent>
        <Paragraph>
          <BreakspaceText>{t('luvanHakeminenOhje')}</BreakspaceText>
        </Paragraph>
        <Paragraph mt="s" mb="xxs">
          <Text variant="bold">{t('milloinTarvitsetluvan')}</Text>
        </Paragraph>
        <DiscList>
          <li>{t('lupaTieto1')}</li>
          <li>{t('lupaTieto2')}</li>
          <li>{t('lupaTieto3')}</li>
          <li>{t('lupaTieto4')}</li>
          <li>{t('lupaTieto6')}</li>
          <li>{t('lupaTieto7')}</li>
          <li>{t('lupaTieto8')}</li>
          <li>{t('lupaTieto9')}</li>
        </DiscList>
        <Block mt="m" />
        <ExternalLink
          href={getLandingLupaHelpUrl(lang)}
          variant="accent"
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
        >
          {t('landingLuvatExternalInstructionsLinkTitle')}
        </ExternalLink>
        <Block mt="s" />
      </ExpanderContent>
    </Expander>
  )
})

const PaatostiliOsio = observer(() => {
  const [t] = useTranslation()
  const { lang } = useAsiointiUserStore()

  return (
    <Expander data-test-id={mkLandingPageTestId('paatostili-section')}>
      <ExpanderTitleButton>{t('paatostili')}</ExpanderTitleButton>
      <ExpanderContent>
        <MultiParagraphText
          text={
            t('mitaTarvitsenPaatostilinTekoon-milloin') +
            ' ' +
            t('mitaTarvitsenPaatostilinTekoon-kuvaus')
          }
        />
        <PaatostiliAsiakirjatOhje />
        <Block mt="m" />
        <ExternalLink
          href={getPaatostiliHelpUrl(lang)}
          variant="accent"
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
        >
          {t('landingTiliExternalInstructionsLinkTitle')}
        </ExternalLink>
      </ExpanderContent>
    </Expander>
  )
})

const VuositiliOsio = observer(() => {
  const [t] = useTranslation()
  const { lang } = useAsiointiUserStore()

  return (
    <Expander data-test-id={mkLandingPageTestId('vuositili-section')}>
      <ExpanderTitleButton>{t('vuositili')}</ExpanderTitleButton>
      <ExpanderContent>
        <MultiParagraphText
          text={
            t('mitaTarvitsenVuositilinTekoon-milloin') +
            ' ' +
            t('mitaTarvitsenVuositilinTekoon-kuvaus')
          }
        />
        <VuositiliAsiakirjatOhje />
        <Block mt="m" />
        <Linkit>
          <ExternalLink
            href={vuositiliOhjeAlaikaiselleUrl(lang)}
            variant="accent"
            toNewWindow
            labelNewWindow={t('avautuuUuteenValilehteen')}
          >
            {t('alaikaisenVuositili')}
          </ExternalLink>

          <ExternalLink
            href={vuositiliOhjeTaysikaiselleUrl(lang)}
            variant="accent"
            toNewWindow
            labelNewWindow={t('avautuuUuteenValilehteen')}
          >
            {t('taysiIkaisetVuositili')}
          </ExternalLink>

          <ExternalLink
            href={valtuuttajanTehtavatOhjeUrl(lang)}
            variant="accent"
            toNewWindow
            labelNewWindow={t('avautuuUuteenValilehteen')}
          >
            {t('valtuuttajanVuositili')}
          </ExternalLink>
        </Linkit>
      </ExpanderContent>
    </Expander>
  )
})

const OmaisuusluetteloOsio = observer(() => {
  const { lang } = useAsiointiUserStore()
  const [t] = useTranslation()
  return (
    <Expander data-test-id={mkLandingPageTestId('omaisuusluettelo-section')}>
      <ExpanderTitleButton>{t('omaisuusluettelo')}</ExpanderTitleButton>
      <ExpanderContent>
        <OmaisuusluetteloOhje />
        <Block mt="m" />
        <ExternalLink
          data-test-id={mkLandingPageTestId('ohje-link')}
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
          href={omaisuusluetteloOhjeUrl(lang)}
        >
          {t('lueLisaaOmaisuusluettelosta')}
        </ExternalLink>
      </ExpanderContent>
    </Expander>
  )
})

const HeroText: React.FC<PropsWithChildren> = ({ children }) => (
  <Paragraph mb="m">
    <Text variant="lead" color="whiteBase">
      {children}
    </Text>
  </Paragraph>
)

const HeroBackground = styled.div`
  background-color: ${suomifiDesignTokens.colors.brandBase};
`

const NumberedList = styled.ul`
  list-style: decimal outside;
  margin: 0 0 0 ${suomifiDesignTokens.spacing.xl};
`

const AlignedDiv = styled.div`
  margin-left: ${suomifiDesignTokens.spacing.xl};
`

const Linkit = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${suomifiDesignTokens.spacing.xs};
`
const BreakspaceText = styled(Text)`
  white-space: pre-line;
`

const Puhelinnumerot = Linkit

export default LandingPage
