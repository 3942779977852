import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { FrontPankkitili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { Block, Heading } from 'suomifi-ui-components'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { EiIlmoitettujaTietoja } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const Pankkitili: React.FC<{ p: FrontPankkitili }> = ({ p }) => {
  const [t] = useTranslation()

  const content = [
    p.iban ? `${t('tilinumero')}: ${p.iban}` : undefined,
    p.kayttovaratili !== undefined
      ? `${t('tamaOnHenkilonKayttotili')}: ${
          p.kayttovaratili ? t('kylla') : t('ei')
        }`
      : undefined,
    p.isKayttoOikeuksia !== undefined
      ? `${t('onkoTilinKayttooikeuksiaMuilla')}: ${
          p.isKayttoOikeuksia ? t('kylla') : t('ei')
        }. ${p.isKayttoOikeuksia ? p.kayttooikeudet : ''}`
      : undefined,
  ]

  return (
    <Block>
      <Heading variant="h5" as="h4" mt="xs">
        {p.pankinNimi}
      </Heading>
      {content.map((row, index) => (
        <MultiParagraphText key={index} text={row} />
      ))}
      <AttachmentList attachments={p.sopimukset} />
    </Block>
  )
}

const OmassaKaytossaPankkitilit: React.FC = observer(() => {
  const [t] = useTranslation()
  const { tili, lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake

  return (
    <>
      <Heading variant="h3">{t('pankkitilitHeading')}</Heading>
      {lomakkeelleValitutTiedot.pankkitilit ? (
        tili.paamiehenOmaisuus.pankkitilit.map((p, index) => (
          <Pankkitili p={p} key={index} />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
    </>
  )
})

export default OmassaKaytossaPankkitilit
