import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  edunvalvonta10VuottaRetention,
  edunvalvonta50VuottaRetention,
  edunvalvontaJulkinenEiHenkilotietojaRestriction,
  edunvalvontaJulkinenHenkilotietojaRestriction,
  edunvalvontaOsittainSalassaPidettava100VuottaKohta23ErityisiaHenkilotietojaRestriction,
  edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
  edunvalvontaPysyvaRetention,
  edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta28ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/common-edunvalvontavaltuutus-metadata'
import {
  AsiaHandlerRole,
  AsiaPersonRole,
  AsiaPersonType,
  AsiaStatus,
  RatkaisuType,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { OrganizationType } from 'asia-common/src/vtj/koodisto/organization-enums'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'

export const muuHolhousasiaMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.ALKUPERAINEN_EDUNVALVONTAVALTAKIRJA,
      restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c473de51-3071-41c9-91cf-964ddb248d13'],
      defaultToivoAsiakirjaTypeId: 'c473de51-3071-41c9-91cf-964ddb248d13',
    },
    {
      asiakirjaTypeId: AsiakirjaType.EDUNVALVOJAN_TAI_EDUNVALVONTAVALTUUTETUN_LAUSUMA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['262f1a5e-d612-490c-b1f9-6eaf77032cbd'],
      defaultToivoAsiakirjaTypeId: '262f1a5e-d612-490c-b1f9-6eaf77032cbd',
    },
    {
      asiakirjaTypeId: AsiakirjaType.EDUNVALVONTAVALTAKIRJAN_JALJENNOS,
      restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['5ac75ed8-5cd6-4024-8b67-cb648d7686c3'],
      defaultToivoAsiakirjaTypeId: '5ac75ed8-5cd6-4024-8b67-cb648d7686c3',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ESITTELIJAN_ERIAVA_MIELIPIDE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['0da1cfa9-abfb-4a89-9d27-5de7ea9558f6'],
      defaultToivoAsiakirjaTypeId: '0da1cfa9-abfb-4a89-9d27-5de7ea9558f6',
    },
    {
      asiakirjaTypeId: AsiakirjaType.HAKEMUKSEN_PERUUTUS_KARAJAOIKEUDELLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['ca807ec1-3467-4f19-b58a-1e59f720ea23'],
      defaultToivoAsiakirjaTypeId: 'ca807ec1-3467-4f19-b58a-1e59f720ea23',
    },
    {
      asiakirjaTypeId: AsiakirjaType.HAKEMUS,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['9cdc6fa4-8a26-4da4-8025-3ef1335980f2'],
      defaultToivoAsiakirjaTypeId: '9cdc6fa4-8a26-4da4-8025-3ef1335980f2',
    },
    {
      asiakirjaTypeId: AsiakirjaType.HAKEMUS_KARAJAOIKEUDELLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['faf3d89c-6828-426e-af6e-9da0b4b57538'],
      defaultToivoAsiakirjaTypeId: 'faf3d89c-6828-426e-af6e-9da0b4b57538',
    },
    {
      asiakirjaTypeId: AsiakirjaType.HALLINTOTUOMIOISTUIMEN_LAUSUNTOPYYNTO_LIITTEINEEN,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['3fe16187-7456-482a-8b5c-13fd9b8308dc'],
      defaultToivoAsiakirjaTypeId: '3fe16187-7456-482a-8b5c-13fd9b8308dc',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_ASIAN_JATTAMISESTA_SILLENSA,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['ad31559e-3f19-4834-a750-6cb52bba7ab4'],
      defaultToivoAsiakirjaTypeId: 'ad31559e-3f19-4834-a750-6cb52bba7ab4',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_ASIAN_RAUKEAMISESTA,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['e68d3151-611e-44d8-a262-383c14bb4ec0'],
      defaultToivoAsiakirjaTypeId: 'e68d3151-611e-44d8-a262-383c14bb4ec0',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_KUOLEMASTA_KARAJAOIKEUDELLE,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['35f58b18-4cee-41e8-b65f-c8667b5557ac'],
      defaultToivoAsiakirjaTypeId: '35f58b18-4cee-41e8-b65f-c8667b5557ac',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_RATKAISUN_ANTAMISESTA,
      restrictionMetadata: edunvalvontaJulkinenEiHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['669a1994-253a-4fe8-9e7d-7d322e07a503'],
      defaultToivoAsiakirjaTypeId: '669a1994-253a-4fe8-9e7d-7d322e07a503',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_TAI_MUU_VIREILLEPANOASIAKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['1860fe17-40cf-4bd4-a137-4dc3463865ff'],
      defaultToivoAsiakirjaTypeId: '1860fe17-40cf-4bd4-a137-4dc3463865ff',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ISTUNTOKUTSU,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['cdb724e6-681e-4095-928d-5f1f4a522bbd'],
      defaultToivoAsiakirjaTypeId: 'cdb724e6-681e-4095-928d-5f1f4a522bbd',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KARAJAOIKEUDEN_LAUSUMAPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['3cca9ccb-a2de-4408-a3be-cb8cf08b35bd'],
      defaultToivoAsiakirjaTypeId: '3cca9ccb-a2de-4408-a3be-cb8cf08b35bd',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KARAJAOIKEUDEN_TAYDENNYSPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['3f558e20-d4fb-4e91-aea1-25f286ae30be'],
      defaultToivoAsiakirjaTypeId: '3f558e20-d4fb-4e91-aea1-25f286ae30be',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KUULEMISKIRJE_EDUNVALVOJALLE_TAI_EDUNVALVONTAVALTUUTETULLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['1486411a-2fbb-4a34-b233-715db67fe7f9'],
      defaultToivoAsiakirjaTypeId: '1486411a-2fbb-4a34-b233-715db67fe7f9',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KUULEMISKIRJE_MUULLE_HENKILOLLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['35697e76-5933-439d-aa3d-0f1776002066'],
      defaultToivoAsiakirjaTypeId: '35697e76-5933-439d-aa3d-0f1776002066',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KUULEMISKIRJE_PAAMIEHELLE_TAI_VALTUUTTAJALLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['c2608a3f-c0f7-4d93-bdd6-f648564d3ac8'],
      defaultToivoAsiakirjaTypeId: 'c2608a3f-c0f7-4d93-bdd6-f648564d3ac8',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['fbd26381-08cf-4026-ae66-c731c568bb5e'],
      defaultToivoAsiakirjaTypeId: 'fbd26381-08cf-4026-ae66-c731c568bb5e',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTOPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['a3f89a6d-9b0a-4b02-8063-83640b7de384'],
      defaultToivoAsiakirjaTypeId: 'a3f89a6d-9b0a-4b02-8063-83640b7de384',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAUSUMA_KARAJAOIKEUDELLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['ddbe9b2a-6a27-4c42-9eac-07af928525a6'],
      defaultToivoAsiakirjaTypeId: 'ddbe9b2a-6a27-4c42-9eac-07af928525a6',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAUSUNTO_HALLINTOTUOMIOISTUIMELLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['5a247f79-9650-486b-b5f1-c8d7928674d5'],
      defaultToivoAsiakirjaTypeId: '5a247f79-9650-486b-b5f1-c8d7928674d5',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA_KARAJAOIKEUTEEN,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['93e6902e-360c-4a21-a0bc-1e4962f5eda4'],
      defaultToivoAsiakirjaTypeId: '93e6902e-360c-4a21-a0bc-1e4962f5eda4',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LAUSUNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['41f670f3-79bd-46e4-a4ad-02fe9f960666'],
      defaultToivoAsiakirjaTypeId: '41f670f3-79bd-46e4-a4ad-02fe9f960666',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LAUSUNTOPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['6a38fa1b-e4f9-4032-99e2-8a33bc4eb2c8'],
      defaultToivoAsiakirjaTypeId: '6a38fa1b-e4f9-4032-99e2-8a33bc4eb2c8',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['a6c07a82-588d-498c-818a-86ad7003aff8'],
      defaultToivoAsiakirjaTypeId: 'a6c07a82-588d-498c-818a-86ad7003aff8',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_TUOMIOISTUIMEN_LAATIMA_ASIAKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['b674b40f-ff88-4eb8-b18d-946eb8f1afe6'],
      defaultToivoAsiakirjaTypeId: 'b674b40f-ff88-4eb8-b18d-946eb8f1afe6',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['94ca79e6-8b74-4567-b8f5-8b16634a975d'],
      defaultToivoAsiakirjaTypeId: '94ca79e6-8b74-4567-b8f5-8b16634a975d',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c16ba7a3-c231-4686-85a2-98c17f29a0c1'],
      defaultToivoAsiakirjaTypeId: 'c16ba7a3-c231-4686-85a2-98c17f29a0c1',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUUN_HENKILON_LAUSUMA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['006d1ba0-61d3-43c4-af39-2cfc9b379d9f'],
      defaultToivoAsiakirjaTypeId: '006d1ba0-61d3-43c4-af39-2cfc9b379d9f',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OIKAISUVAATIMUS,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['9683e412-0d60-4e39-80f4-3cb7cc455b70'],
      defaultToivoAsiakirjaTypeId: '9683e412-0d60-4e39-80f4-3cb7cc455b70',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OIKAISUVAATIMUSOSOITUS,
      restrictionMetadata: edunvalvontaJulkinenEiHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['ecccd01e-84b6-4208-98c4-b72ca4696f52'],
      defaultToivoAsiakirjaTypeId: 'ecccd01e-84b6-4208-98c4-b72ca4696f52',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OTE_HOLHOUSASIOIDEN_REKISTERISTA,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['e1c81704-c0c2-4d9a-9650-d5f4465f78f5'],
      defaultToivoAsiakirjaTypeId: 'e1c81704-c0c2-4d9a-9650-d5f4465f78f5',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAAMIEHEN_TAI_VALTUUTTAJAN_LAUSUMA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['71e324ee-ff2c-4116-a0d9-b813fc8c17c3'],
      defaultToivoAsiakirjaTypeId: '71e324ee-ff2c-4116-a0d9-b813fc8c17c3',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['64ecbb77-054c-402a-8db2-5d6d658df905'],
      defaultToivoAsiakirjaTypeId: '64ecbb77-054c-402a-8db2-5d6d658df905',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['66b4f1ce-af5d-4d9f-a8dc-d3aca2e14083'],
      defaultToivoAsiakirjaTypeId: '66b4f1ce-af5d-4d9f-a8dc-d3aca2e14083',
    },
    {
      asiakirjaTypeId: AsiakirjaType.RATKAISUPYYNTO_KARAJAOIKEUDELLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['ddc2172d-a41b-418d-bcb0-5938bd45c991'],
      defaultToivoAsiakirjaTypeId: 'ddc2172d-a41b-418d-bcb0-5938bd45c991',
    },
    {
      asiakirjaTypeId: AsiakirjaType.RATKAISUPYYNTOOSOITUS,
      restrictionMetadata: edunvalvontaJulkinenEiHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['94f6fa60-09ef-44ab-923a-1e5f910f2f9f'],
      defaultToivoAsiakirjaTypeId: '94f6fa60-09ef-44ab-923a-1e5f910f2f9f',
    },
    {
      asiakirjaTypeId: AsiakirjaType.RIKOSREKISTERIOTE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta28ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['731b5672-210e-4f05-b40e-3122a9cf53f6'],
      defaultToivoAsiakirjaTypeId: '731b5672-210e-4f05-b40e-3122a9cf53f6',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['c90f3feb-895c-4865-9254-756c95186e9b'],
      defaultToivoAsiakirjaTypeId: 'c90f3feb-895c-4865-9254-756c95186e9b',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SOPIMUS,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['db05be28-e949-4caa-a78d-bb0ee25351be'],
      defaultToivoAsiakirjaTypeId: 'db05be28-e949-4caa-a78d-bb0ee25351be',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SUOSTUMUS_EDUNVALVOJAN_TEHTAVAAN,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['9113b9b4-6ec9-40c1-b644-0f5cbf2af9ec'],
      defaultToivoAsiakirjaTypeId: '9113b9b4-6ec9-40c1-b644-0f5cbf2af9ec',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYS,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['9a91aa77-fba8-4446-8408-3602c244a8fd'],
      defaultToivoAsiakirjaTypeId: '9a91aa77-fba8-4446-8408-3602c244a8fd',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYS_KARAJAOIKEUDELLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['2157c6aa-3a71-4df3-b8d2-bd5495ad641f'],
      defaultToivoAsiakirjaTypeId: '2157c6aa-3a71-4df3-b8d2-bd5495ad641f',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['86769e50-bd4a-441b-bd01-4fe0349986c4'],
      defaultToivoAsiakirjaTypeId: '86769e50-bd4a-441b-bd01-4fe0349986c4',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TILIEN_SALDOT_JA_TILITAPAHTUMAT,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['463e4f43-51e2-49d6-b65f-9ad57e099725'],
      defaultToivoAsiakirjaTypeId: '463e4f43-51e2-49d6-b65f-9ad57e099725',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TODISTUS_ULOSOTTOREKISTERISTA,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['e21bb872-dc1e-4947-a053-f8373b17cb30'],
      defaultToivoAsiakirjaTypeId: 'e21bb872-dc1e-4947-a053-f8373b17cb30',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TUOMIOISTUIMEN_LAUSUMAPYYNTO_LIITTEINEEN_UUDESSA_ASIASSA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['bab60580-2bb1-49f4-92e3-69871b9e5c60'],
      defaultToivoAsiakirjaTypeId: 'bab60580-2bb1-49f4-92e3-69871b9e5c60',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TUOMIOISTUIMEN_PAATOS,
      restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c69450cc-13f0-49fb-8796-3fe83087e864'],
      defaultToivoAsiakirjaTypeId: 'c69450cc-13f0-49fb-8796-3fe83087e864',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TUOMIOISTUIMEN_VALIAIKAISMAARAYS,
      restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['4bb3fb07-a2bf-4d6d-9c1a-3f54e010b0de'],
      defaultToivoAsiakirjaTypeId: '4bb3fb07-a2bf-4d6d-9c1a-3f54e010b0de',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VAATIMUS_VIRHEEN_KORJAAMISESTA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['d8971b68-e0d1-42f5-be1c-9f5793c35b23'],
      defaultToivoAsiakirjaTypeId: 'd8971b68-e0d1-42f5-be1c-9f5793c35b23',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VALITUSOSOITUS,
      restrictionMetadata: edunvalvontaJulkinenEiHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['d72a7a11-3dee-408e-9d4d-22f19c144203'],
      defaultToivoAsiakirjaTypeId: 'd72a7a11-3dee-408e-9d4d-22f19c144203',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VALTAKIRJA,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['6c0b68b0-c628-45d9-b699-85bf0c5751d0'],
      defaultToivoAsiakirjaTypeId: '6c0b68b0-c628-45d9-b699-85bf0c5751d0',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIREILLETULOILMOITUS,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['1dea0840-1a7c-4e14-bb97-b64516a9e59f'],
      defaultToivoAsiakirjaTypeId: '1dea0840-1a7c-4e14-bb97-b64516a9e59f',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.PAATETTAVANA_KARAJAOIKEUDESSA,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.RATKAISUEHDOTUS_ANNETTU,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_LAINVOIMAISUUTTA,
      postDueDateHuomioTypeId: HuomioType.LAINVOIMAINEN,
    },
    {
      huomioTypeId: HuomioType.LAINVOIMAINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREHDITTY,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA,
      postDueDateHuomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.HOLLE_OTTEEN_LAHETYS,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [
      AsiaPersonRole.HAKIJA,
      AsiaPersonRole.ILMOITTAJA,
      AsiaPersonRole.EDUNVALVOJA,
      AsiaPersonRole.EDUNVALVOJAN_SIJAINEN,
      AsiaPersonRole.VALTUUTETUN_TEHTAVIIN_MAARATTY_EDUNVALVOJA,
      AsiaPersonRole.MUU_HENKILO,
    ],
    organizationTypeIds: [
      OrganizationType.OSTOPALVELU_EDUNVALVONTATOIMISTO,
      OrganizationType.VALTION_EDUNVALVONTATOIMISTO,
    ],
  },
  personRoleMetadata: [
    AsiaPersonRole.HAKIJA,
    AsiaPersonRole.ILMOITTAJA,
    AsiaPersonRole.PAAMIES,
    AsiaPersonRole.VALTUUTTAJA,
    AsiaPersonRole.EDUNVALVOJA,
    AsiaPersonRole.EDUNVALVONTAVALTUUTETTU,
    AsiaPersonRole.TOISSIJAINEN_VALTUUTETTU,
    AsiaPersonRole.VARAVALTUUTETTU,
    AsiaPersonRole.EDUNVALVOJAN_SIJAINEN,
    AsiaPersonRole.VALTUUTETUN_TEHTAVIIN_MAARATTY_EDUNVALVOJA,
    AsiaPersonRole.HUOLTAJA,
    AsiaPersonRole.MUU_HENKILO,
  ],
  personTypeMetadata: [
    AsiaPersonType.IHMINEN,
    AsiaPersonType.ORGANISAATIO,
    AsiaPersonType.AMMATTIHENKILO,
    AsiaPersonType.YRITYS,
  ],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.MYONTEINEN_PAATOS,
    manualRatkaisuTypeIds: [
      RatkaisuType.MYONTEINEN_PAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.JATETTY_TUTKIMATTA,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.REKISTERISTA_POISTAMINEN,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: true,
    specifierTypeIds: [
      SpecifierType.EDUNVALVONNAN_LAKKAUTTAMINEN,
      SpecifierType.EDUNVALVOJAN_VAIHTAMINEN,
      SpecifierType.EDUNVALVOJAN_SIJAISEN_MAARAAMINEN,
      SpecifierType.EDUNVALVOJAN_JA_SIJAISEN_MAARAAMINEN,
      SpecifierType.EDUNVALVONNAN_JATKAMINEN,
      SpecifierType.EDUNVALVONNAN_MUUTTAMINEN,
      SpecifierType.EDUNVALVONTAVALTUUTUKSEN_PERUUTTAMISEN_VAHVISTAMINEN,
      SpecifierType.EDUNVALVONTAVALTUUTUKSEN_VAHVISTAMINEN_VARAVALTUUTETUN_OSALTA,
      SpecifierType.EDUNVALVOJAN_MAARAAMINEN_VALTUUTETUN_TEHTAVIIN,
      SpecifierType.ALAIKAISEN_EDUNVALVONNAN_REKISTEROINTI,
      SpecifierType.ALAIKAISEN_EDUNVALVONNAN_REKISTERISTA_POISTAMINEN,
      SpecifierType.EDUNVALVONNAN_SIIRROT_AHVENANMAA,
      SpecifierType.EDUNVALVONNAN_SIIRROT_POHJOISMAAT,
      SpecifierType.VALVONNAN_LOPETTAMINEN,
      SpecifierType.TILIN_TAI_OMAISUUSLUETTELON_MAARAAJAN_PIDENTAMINEN,
      SpecifierType.MUUTOS_TILIVELVOLLISUUTEEN,
      SpecifierType.OMAISUUDENHOITOSUUNNITELMAN_HYVAKSYMINEN,
      SpecifierType.VAROJEN_TALLETTAMINEN_ESTOTILILLE,
      SpecifierType.VAROJEN_NOSTO_ESTOTILILTA,
      SpecifierType.EDUNVALVOJIEN_VALISEN_ERIMIELISYYDEN_RATKAISEMINEN,
      SpecifierType.REKISTERIVIRHEEN_KORJAAMINEN,
      SpecifierType.ASIA_TAI_KIRJOITUSVIRHEEN_KORJAUS_OIKAISUVAATIMUS_MUUTOKSENHAKU,
      SpecifierType.TIEDON_TAI_ASIAKIRJAN_LUOVUTTAMINEN,
      SpecifierType.LAUSUMAPYYNTO_TUOMIOISTUIMELTA,
      SpecifierType.KANTELU_EDUNVALVOJAN_TOIMINNASTA,
      SpecifierType.KANTELU_TAI_MUU_YHTEYDENOTTO_EDUNVALVOJAN_TOIMINNASTA,
      SpecifierType.PAKKOKEINOASIA,
      SpecifierType.MAKSUA_KOSKEVA_OIKAISUVAATIMUS,
      SpecifierType.KANSAINVALINEN_REKISTEROINTIASIA,
      SpecifierType.KANSAINVALINEN_ASIA,
    ],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [
        AsiakirjaType.PAATOS,
        AsiakirjaType.ILMOITUS_ASIAN_RAUKEAMISESTA,
        AsiakirjaType.ILMOITUS_ASIAN_JATTAMISESTA_SILLENSA,
        AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
      ],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.TAYDENNYSPYYNTO],
      prioritizedSaapunutAsiakirjaTypeIds: [
        AsiakirjaType.TAYDENNYS,
        AsiakirjaType.VALTAKIRJA,
        AsiakirjaType.PAAMIEHEN_TAI_VALTUUTTAJAN_LAUSUMA,
        AsiakirjaType.EDUNVALVOJAN_TAI_EDUNVALVONTAVALTUUTETUN_LAUSUMA,
        AsiakirjaType.MUUN_HENKILON_LAUSUMA,
        AsiakirjaType.ALKUPERAINEN_EDUNVALVONTAVALTAKIRJA,
        AsiakirjaType.EDUNVALVONTAVALTAKIRJAN_JALJENNOS,
        AsiakirjaType.LAAKARINLAUSUNTO,
        AsiakirjaType.MUU_LAUSUNTO,
        AsiakirjaType.SUOSTUMUS_EDUNVALVOJAN_TEHTAVAAN,
        AsiakirjaType.PERUKIRJA,
        AsiakirjaType.SOPIMUS,
        AsiakirjaType.MUU_LIITE,
      ],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.TAYDENNYS,
    },
    {
      toimenpideTypeId: ToimenpideType.KUULEMINEN,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [
        AsiakirjaType.KUULEMISKIRJE_MUULLE_HENKILOLLE,
        AsiakirjaType.KUULEMISKIRJE_EDUNVALVOJALLE_TAI_EDUNVALVONTAVALTUUTETULLE,
        AsiakirjaType.KUULEMISKIRJE_PAAMIEHELLE_TAI_VALTUUTTAJALLE,
      ],
      prioritizedSaapunutAsiakirjaTypeIds: [
        AsiakirjaType.MUUN_HENKILON_LAUSUMA,
        AsiakirjaType.EDUNVALVOJAN_TAI_EDUNVALVONTAVALTUUTETUN_LAUSUMA,
        AsiakirjaType.PAAMIEHEN_TAI_VALTUUTTAJAN_LAUSUMA,
      ],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.MUUN_HENKILON_LAUSUMA,
    },
    {
      toimenpideTypeId: ToimenpideType.SELVITYSPYYNTO,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSPYYNTO],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
    },
    {
      toimenpideTypeId: ToimenpideType.LAUSUNTOPYYNTO,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.LAAKARINLAUSUNTOPYYNTO, AsiakirjaType.MUU_LAUSUNTOPYYNTO],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.LAAKARINLAUSUNTO, AsiakirjaType.MUU_LAUSUNTO],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.MUU_LAUSUNTO,
    },
    {
      toimenpideTypeId: ToimenpideType.LISATIETOPYYNTO,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.VALMISTELTAVANA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMISTELTAVANA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.ESITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
  retentionMetadata: edunvalvontaPysyvaRetention,
  restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23ErityisiaHenkilotietojaRestriction,
}
