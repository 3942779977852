import _ from 'lodash'
import keyMirror from 'keymirror'
import { ViestintaTypeId } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import { AsiakirjaTypeId } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { HuomioType, HuomioTypeId, ToimenpideSaapunutHuomioTypeId } from 'asia-common/src/vtj/koodisto/huomio-enums'

export const InternalToimenpideType = keyMirror({
  VIREILLETULO: null,
  VALMISTELU: null,
  PAATOKSENTEKO: null,
})
export type InternalToimenpideTypeId = (typeof InternalToimenpideType)[keyof typeof InternalToimenpideType]
export const allInternalToimenpideTypeIds = _.keys(InternalToimenpideType) as InternalToimenpideTypeId[]

export const DueDateToimenpideType = keyMirror({
  TAYDENTAMINEN: null,
  KUULEMINEN: null,
  SELVITYSPYYNTO: null,
  LAUSUNTOPYYNTO: null,
  LYKKAYS: null,
  LISATIETOPYYNTO: null,
  TUNNISTAUTUMINEN: null,
})
export type DueDateToimenpideTypeId = (typeof DueDateToimenpideType)[keyof typeof DueDateToimenpideType]
export const allDueDateToimenpideTypeIds = _.keys(DueDateToimenpideType) as DueDateToimenpideTypeId[]
export const TiedoksiantoToimenpideType = keyMirror({
  TIEDOKSIANTO: null,
})
export type TiedoksiantoToimenpideTypeId = (typeof TiedoksiantoToimenpideType)[keyof typeof TiedoksiantoToimenpideType]
export const allTiedoksiantoToimenpideTypeIds = _.keys(TiedoksiantoToimenpideType) as ToimenpideTypeId[]
export const ExternalToimenpideType = keyMirror({
  ...DueDateToimenpideType,
  ...TiedoksiantoToimenpideType,
})
export type ExternalToimenpideTypeId = (typeof ExternalToimenpideType)[keyof typeof ExternalToimenpideType]
export const allExternalToimenpideTypeIds = _.keys(ExternalToimenpideType) as ExternalToimenpideTypeId[]
export const ToimenpideType = keyMirror({
  ...InternalToimenpideType,
  ...ExternalToimenpideType,
})
export type ToimenpideTypeId = (typeof ToimenpideType)[keyof typeof ToimenpideType]
export const allToimenpideTypeIds = _.keys(ToimenpideType) as ToimenpideTypeId[]

export const ToimenpideStatus = keyMirror({
  AVOIN: null,
  LUKITTU: null,
  SULJETTU: null,
})
export type ToimenpideStatusId = (typeof ToimenpideStatus)[keyof typeof ToimenpideStatus]
export const allToimenpideStatusIds = _.keys(ToimenpideStatus) as ToimenpideStatusId[]

export const ToimenpideHuomioPhase = keyMirror({
  ODOTTAA: null,
  SAAPUNUT: null,
  MAARAAIKA: null,
})
export type ToimenpideHuomioPhaseId = (typeof ToimenpideHuomioPhase)[keyof typeof ToimenpideHuomioPhase]
export const allToimenpideHuomioPhaseIds = _.keys(ToimenpideHuomioPhase) as ToimenpideHuomioPhaseId[]

export type ToimenpideHuomioMetadata = {
  [ToimenpideHuomioPhase.ODOTTAA]: HuomioTypeId
  [ToimenpideHuomioPhase.SAAPUNUT]?: ToimenpideSaapunutHuomioTypeId
  [ToimenpideHuomioPhase.MAARAAIKA]: HuomioTypeId
}

export type ToimenpideMetadata = {
  toimenpideTypeId: ExternalToimenpideTypeId
  internalViestintaTypeIds: ViestintaTypeId[]
  externalViestintaTypeIds: ViestintaTypeId[]
  prioritizedLaadittuAsiakirjaTypeIds: AsiakirjaTypeId[]
  prioritizedSaapunutAsiakirjaTypeIds: AsiakirjaTypeId[]
  automationSaapunutAsiakirjaTypeId: AsiakirjaTypeId | null
}

export const toimenpideHuomioMetadata: { [key in DueDateToimenpideTypeId]: ToimenpideHuomioMetadata } = {
  [ExternalToimenpideType.TAYDENTAMINEN]: {
    [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TAYDENNYSTA,
    [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.TAYDENNYS_SAAPUNUT,
    [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT,
  },
  [ExternalToimenpideType.KUULEMINEN]: {
    [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_KUULEMISTA,
    [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.KUULEMINEN_SAAPUNUT,
    [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT,
  },
  [ExternalToimenpideType.SELVITYSPYYNTO]: {
    [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_SELVITYSTA,
    [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.SELVITYS_SAAPUNUT,
    [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT,
  },
  [ExternalToimenpideType.LAUSUNTOPYYNTO]: {
    [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_LAUSUNTOA,
    [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.LAUSUNTO_SAAPUNUT,
    [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.LAUSUNNON_MAARAAIKA_YLITTYNYT,
  },
  [ExternalToimenpideType.LISATIETOPYYNTO]: {
    [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_LISATIETOA,
    [ToimenpideHuomioPhase.SAAPUNUT]: HuomioType.LISATIETO_SAAPUNUT,
    [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.LISATIEDON_MAARAAIKA_YLITTYNYT,
  },
  [ExternalToimenpideType.LYKKAYS]: {
    [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_LYKKAYSTA,
    // no SAAPUNUT huomio
    [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.LYKKAYKSEN_MAARAAIKA_YLITTYNYT,
  },
  [ExternalToimenpideType.TUNNISTAUTUMINEN]: {
    [ToimenpideHuomioPhase.ODOTTAA]: HuomioType.ODOTTAA_TUNNISTAUTUMISTA,
    // no SAAPUNUT huomio
    [ToimenpideHuomioPhase.MAARAAIKA]: HuomioType.TUNNISTAUTUMISEN_MAARAPAIVA_YLITTYNYT,
  },
}

export const getToimenpideHuomioMetadata = (toimenpideTypeId: ToimenpideTypeId): ToimenpideHuomioMetadata | null => {
  const candidateId = <DueDateToimenpideTypeId>toimenpideTypeId
  if (allDueDateToimenpideTypeIds.includes(candidateId)) {
    return toimenpideHuomioMetadata[candidateId]
  } else {
    return null
  }
}
