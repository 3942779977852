import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import {
  getAddressAsArray,
  getPaivamaaraString,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'
import { AsiointiHabitationType } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { FrontAsuinpaikka } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const Asuinpaikka: React.FC<{ ap: FrontAsuinpaikka }> = ({ ap }) => {
  const [t] = useTranslation()
  const lang = useAsiointiUserStore().lang
  const fullDetails =
    ap.habitationType !== AsiointiHabitationType.HOUSELESS &&
    ap.habitationType !== AsiointiHabitationType.UNKNOWN

  const addressString = fullDetails
    ? getAddressAsArray(
        {
          streetAddress: ap.streetAddress,
          streetAddressExtra: ap.streetAddressExtra,
          postalCode: ap.postalCode,
          postOffice: ap.postOffice,
          countryId: ap.countryCode,
        },
        lang
      ).join(', ') || ''
    : ''

  return (
    <Block>
      <Heading variant="h5" as="h4" mt="xs">
        {t(`habitationType_${ap.habitationType}`)}
      </Heading>
      <Paragraph>
        {`${addressString} ${
          fullDetails && ap.habitationPeriodType
            ? t(`habitationPeriodType_${ap.habitationPeriodType}`)
            : ''
        }
        ${getPaivamaaraString(t, ap.beginDate, ap.endDate)}`}
      </Paragraph>
    </Block>
  )
}

const Asuminen: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    vaatimus: { isPrincipalUnderaged },
    lomake: {
      tili: { asuminen },
      lomakkeelleValitutTiedot,
    },
  } = getTilintarkastusStore()

  return (
    <section data-test-id={mkYhteenvetoTestId('asuminen-container')}>
      <Heading variant="h3">{t('asuminenHeading')}</Heading>

      {isPrincipalUnderaged ? (
        <>
          <Heading variant="h5" as="h4" mt="s">
            {t('alaikainenAsuinpaikkaOhje')}
          </Heading>
          <Paragraph>
            {lomakkeelleValitutTiedot.huoltajalla ? t('kylla') : t('ei')}
          </Paragraph>
          {lomakkeelleValitutTiedot.huoltajalla ? (
            <Block mt="s">
              <Heading variant="h5" as="h4">
                {t('kenenKanssaHenkiloAsuu')}
              </Heading>
              <MultiParagraphText text={asuminen.huoltajalla} mb={'s'} />
            </Block>
          ) : (
            asuminen.asuinpaikat.map((ap, index) => (
              <Asuinpaikka ap={ap} key={index} />
            ))
          )}
        </>
      ) : (
        <>
          {asuminen.asuinpaikat.map((ap, index) => (
            <Asuinpaikka ap={ap} key={index} />
          ))}
          <Heading variant="h5" as="h4" mt="s">
            {t('hoitoJaPalvelusuunnitelmaHeading')}
          </Heading>
          <Paragraph>
            {lomakkeelleValitutTiedot.hoitoPalveluSuunnitelma
              ? t('kylla')
              : t('ei')}
          </Paragraph>
          {lomakkeelleValitutTiedot.hoitoPalveluSuunnitelma && (
            <AttachmentList attachments={asuminen.hoitoPalveluSuunnitelma} />
          )}
        </>
      )}
    </section>
  )
})

export default Asuminen
