import { suomifiDesignTokens } from 'suomifi-ui-components'
import React from 'react'
import { observer } from 'mobx-react'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { styled } from 'styled-components'
import { Outlet, useLocation } from 'react-router-dom'
import FormNavigationWizard from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationWizard'
import {
  resolveNavigationStep,
  TILINTARKASTUS_ROUTES,
  TILINTARKASTUS_STEPS,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { TopHeading } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/TopHeading'
import { TilintarkastusRouteFormControls } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'

const TilintarkastusAppPage: React.FC<{
  forms: TilintarkastusRouteFormControls
}> = observer(({ forms }) => {
  const location = useLocation()
  const currentStep = resolveNavigationStep(location)
  return (
    <>
      <TopHeading
        showEsikatseleYhteenveto={
          currentStep !== TILINTARKASTUS_ROUTES.VALMIS &&
          currentStep !== TILINTARKASTUS_ROUTES.YHTEENVETO
        }
      />
      <MainContent>
        <NavigationAndNotifications>
          <FormNavigationWizard
            forms={forms}
            routes={TILINTARKASTUS_ROUTES}
            steps={TILINTARKASTUS_STEPS}
          />
        </NavigationAndNotifications>
        <ApplicationDetails>
          <Outlet />
        </ApplicationDetails>
      </MainContent>
    </>
  )
})

export default TilintarkastusAppPage

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${suomifiDesignTokens.spacing.m};

  @media screen and ${device.tablet} {
    flex-direction: row;
    gap: ${suomifiDesignTokens.spacing.s};
  }
`

const ApplicationDetails = styled.section`
  flex: 1;
`
const NavigationAndNotifications = styled.div`
  margin: 0 ${suomifiDesignTokens.spacing.s};

  @media ${device.tablet} {
    max-width: 330px;
    margin: 0;
  }
`
