import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getPaivamaaraString } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { Heading } from 'suomifi-ui-components'
import {
  EiIlmoitettujaTietoja,
  YhteenvetoTilirivi,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { AsiointiElaketyyppi } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

const SaannollisetTulot: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    vaatimus: { isPrincipalUnderaged },
    lomake: {
      tili: { tulotiedot },
      lomakkeelleValitutTiedot,
    },
  } = getTilintarkastusStore()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const elaketulot = tulotiedot.saannollisetTulot
    .filter((tulo) =>
      tulo.tyyppi ? AsiointiElaketyyppi.includes(tulo.tyyppi) : []
    )
    .reduce((acc, tulo) => (acc += tulo.nettosumma || 0), 0)

  return (
    <section data-test-id={mkYhteenvetoTestId('saannolliset-tulot-container')}>
      <Heading variant="h3" mb={verticalMarginToken}>
        {isOmaisuusluettelo()
          ? t('elakkeetJaEtuudet')
          : t('saannollisetTulotHeading')}
      </Heading>

      <Heading variant="h4">{t('elakkeetJaEtuudetHeading')}</Heading>

      {lomakkeelleValitutTiedot.saannollisetTulot ? (
        tulotiedot.saannollisetTulot.map((st, i) => (
          <YhteenvetoTilirivi
            heading={t(`incomeType_${st.tyyppi}`)}
            content={[
              [
                st.maksaja,
                `${getPaivamaaraString(t, st.beginDate, st.endDate)}`,
              ]
                .filter((s) => s)
                .join(','),
              isPrincipalUnderaged &&
              st.mihinPankkitililleMaksetaan !== undefined
                ? `${t('elakkeenMaksaminen')}: ${t(
                    `AlaikaisenTilinTyyppi_${st.mihinPankkitililleMaksetaan}`
                  )}`
                : '',
            ]}
            attachments={st.asiakirjat}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      {isPrincipalUnderaged &&
        tulotiedot.saannollisetTulot.find(
          ({ tyyppi }) => tyyppi && AsiointiElaketyyppi.includes(tyyppi)
        ) && (
          <>
            <Heading variant="h4" mt="xs">
              {t('elinkustannuksiinKaytettavatElaketulot')}
            </Heading>
            <YhteenvetoTilirivi
              heading={`${t('elakkeetYhteensa')}: ${t('elaketulotSum', {
                elaketulot: formatNumberWithComma(elaketulot),
              })}`}
              content={[
                tulotiedot.elaketulotElinkustannuksiin
                  .enKaytaElakettaElinkustannuksiin
                  ? t('enKaytaElinkustannuksiin')
                  : `${t('kustannuksiinKaytettavaMaara')}: ${
                      tulotiedot.elaketulotElinkustannuksiin
                        .elinkustannuksiinKaytettavatElaketulot !== undefined
                        ? formatNumberWithComma(
                            tulotiedot.elaketulotElinkustannuksiin
                              .elinkustannuksiinKaytettavatElaketulot
                          )
                        : ''
                    }`,
              ]}
            />
          </>
        )}
    </section>
  )
})

export default SaannollisetTulot
