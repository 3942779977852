import { observer } from 'mobx-react'
import { I18nextProvider, useTranslation } from 'react-i18next'
import {
  IsoDatetimeToUiDate,
  LongIsoDateString,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import {
  Block,
  Button,
  ExternalLink,
  Heading,
  IconError,
  LoadingSpinner,
  Paragraph,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import { mkOmaisuudenHallintaTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-test-id'
import React, { FC, PropsWithChildren, useState } from 'react'
import { styled } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { OverviewButton } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/PaamiesFrontPage'
import { HolhousAsiaType } from 'tilintarkastus-common/src/vtj/types/holhous-asia/holhous-asia-enums'
import { AsiointiSeurantaAsiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/edunvalvontasuhteet/edunvalvontasuhteet-api.type'
import { useDraftStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/draft-store'
import TiliStatusChip from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/TiliStatusChip'
import {
  resolveTilintarkastusNavigationPath,
  TILINTARKASTUS_ROUTES,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import {
  useVaatimukset,
  useValittuHenkilo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/evtv-store'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { BulletList } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { kasittelyAjatUrl } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { SeurantaAsiaVaatimusTila } from 'tilintarkastus-common/src/vtj/types/seuranta-asiavaatimus/seuranta-asiavaatimus-enums'
import { PaatostiliAsiakirjatOhje } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/common/PaatostiliAsiakirjatOhje'
import { VuositiliAsiakirjatOhje } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/common/VuositiliAsiakirjatOhje'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'
import { TulostaYhteenvetoModal } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/TulostaYhteenvetoModal'
import { initTilinpaatosAsiointiI18n } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/localization/i18next'
import { LanguageCode } from 'common/src/vtj/LanguageCode.enum'

const OmaisuudenHallinta = observer(() => {
  const [t] = useTranslation()
  const evtvVaatimukset = useVaatimukset()
  const valittuPaamies = useValittuHenkilo()
  const henkilonVaatimukset = evtvVaatimukset?.filter(
    ({ personId }) => personId === valittuPaamies.personId
  )
  const avoimet = henkilonVaatimukset.filter((vaatimus) => !vaatimus.sentDate)
  const lahetetyt = henkilonVaatimukset
    .filter((vaatimus) => vaatimus.sentDate)
    .reverse()

  return (
    <>
      <Heading variant="h3" mt="xxl" mb="m">
        {t('avoimetVaatimukset')}
      </Heading>
      <VaatimusContainer
        data-test-id={mkOmaisuudenHallintaTestId('avoimet-tehtavat-container')}
      >
        {avoimet.map((vaatimus) => (
          <VaatimusKortti
            vaatimus={vaatimus}
            key={vaatimus.seurantaAsiavaatimusId}
          />
        ))}
        {avoimet.length === 0 && (
          <Paragraph>
            <Text color="blackLight1">{t('eiAvoimiaVaatimuksia')}</Text>
          </Paragraph>
        )}
      </VaatimusContainer>

      {lahetetyt.length > 0 && (
        <>
          <Heading variant="h3" mt="xxl" mb="m">
            {t('lahetetyt')}
          </Heading>
          <VaatimusContainer
            data-test-id={mkOmaisuudenHallintaTestId(
              'lahetetyt-tehtavat-container'
            )}
          >
            {lahetetyt.map((vaatimus) => (
              <VaatimusKortti
                vaatimus={vaatimus}
                key={vaatimus.seurantaAsiavaatimusId}
              />
            ))}
          </VaatimusContainer>
        </>
      )}
    </>
  )
})

const VaatimusKortti: React.FC<{ vaatimus: AsiointiSeurantaAsiavaatimus }> =
  observer(({ vaatimus }) => {
    const [t] = useTranslation()
    const {
      isLoading: isLoadingForDrafts,
      isInitialized: isInitializedForDrafts,
      drafts,
    } = useDraftStore()
    const isDraftsReady = !isLoadingForDrafts && isInitializedForDrafts
    const translations = omaisuudenHallintaTranslationsByType[vaatimus.asiaType]
    const tiliDraft = drafts[vaatimus.seurantaAsiavaatimusId]
    return (
      <PaamiehenTili
        data-test-id={mkOmaisuudenHallintaTestId(
          'seuranta-asia',
          vaatimus.seurantaAsiavaatimusId
        )}
        key={vaatimus.seurantaAsiavaatimusId}
      >
        <Heading
          variant="h3"
          color="highlightBase"
          data-test-id={mkOmaisuudenHallintaTestId(
            'tilityyppi',
            vaatimus.seurantaAsiavaatimusId
          )}
          mb="xxs"
        >
          {t(translations.tilitype)}
        </Heading>
        <Row>
          <TehtavanAjanjakso
            asiaType={vaatimus.asiaType}
            alkupvm={vaatimus.accountingPeriodStartDate}
            loppupvm={vaatimus.accountingPeriodEndDate}
          />
          <TiliStatusChip vaatimus={vaatimus} draft={tiliDraft} />
        </Row>
        <Block mt="xs" />

        {!vaatimus.sentDate &&
          vaatimus.statusType === SeurantaAsiaVaatimusTila.VOIMASSA && (
            <>
              <TiliAvoinnaInfo vaatimus={vaatimus} />
              {vaatimus.asiaType === AsiointiType.PAATOSTILI && (
                <PaatostiliAsiakirjatOhje />
              )}
              {vaatimus.asiaType === AsiointiType.VUOSITILI && (
                <VuositiliAsiakirjatOhje />
              )}
            </>
          )}
        {vaatimus.sentDate &&
          (vaatimus.statusType === SeurantaAsiaVaatimusTila.VOIMASSA ||
            (vaatimus.statusType === SeurantaAsiaVaatimusTila.PAATETTY &&
              vaatimus.asiaType === AsiointiType.OMAISUUSLUETTELO)) && (
            <Paragraph>
              {t(translations.lahetetty, {
                pvm: IsoDatetimeToUiDate(vaatimus.sentDate),
              })}
            </Paragraph>
          )}
        {vaatimus.sentDate &&
          vaatimus.statusType === SeurantaAsiaVaatimusTila.VOIMASSA &&
          vaatimus.asiaType !== AsiointiType.OMAISUUSLUETTELO && (
            <MitaSeuraavaksiTapahtuuBlock />
          )}
        {vaatimus.sentDate &&
          vaatimus.statusType === 'PAATETTY' &&
          vaatimus.asiaType !== AsiointiType.OMAISUUSLUETTELO && (
            <Block mt="m">
              <Heading variant="h4" mb="xs">
                {t(translations.tiliOnTarkastettu)}
              </Heading>
              <Paragraph>{t('tilikorttiSaatTarkastetunTilin')}</Paragraph>
            </Block>
          )}
        {!isDraftsReady && (
          <Block mt="m">
            <LoadingSpinner text={t('ladataan')} textAlign="right" />
          </Block>
        )}
        {isDraftsReady && (
          <>
            {vaatimus.sentDate && tiliDraft && (
              <TulostaYhteenvetoBlock vaatimus={vaatimus} />
            )}
            {!vaatimus.sentDate && !vaatimus.lomakeBlockedReason && (
              <Block mt="m">
                <NavigateToTilintarkastusButton vaatimus={vaatimus}>
                  {tiliDraft ? t('jatkaTayttamista') : t('aloitaTayttaminen')}
                </NavigateToTilintarkastusButton>
              </Block>
            )}
          </>
        )}
      </PaamiehenTili>
    )
  })

interface TehtavanAjanjaksoProps {
  asiaType: AsiointiSeurantaAsiavaatimus['asiaType']
  alkupvm: LongIsoDateString
  loppupvm?: LongIsoDateString
}
const TehtavanAjanjakso: FC<TehtavanAjanjaksoProps> = ({
  asiaType,
  alkupvm,
  loppupvm,
}) => {
  const [t] = useTranslation()
  const tehtavanAlkamispaiva = `${t(
    'tehtavanAlkamispaiva'
  )}: ${IsoDatetimeToUiDate(alkupvm)}`
  const tilikausiPvm = `${t('tilikausi')}: ${IsoDatetimeToUiDate(
    alkupvm
  )} - ${IsoDatetimeToUiDate(loppupvm)}`

  return (
    <Text smallScreen variant="bold">
      {asiaType === HolhousAsiaType.OMAISUUSLUETTELO
        ? tehtavanAlkamispaiva
        : tilikausiPvm}
    </Text>
  )
}

const NavigateToTilintarkastusButton: FC<
  PropsWithChildren & {
    vaatimus: AsiointiSeurantaAsiavaatimus
  }
> = ({ children, vaatimus }) => {
  const navigate = useNavigate()
  return (
    <OverviewButton
      data-test-id={mkOmaisuudenHallintaTestId(
        'tee-tili-nappi',
        vaatimus.seurantaAsiavaatimusId
      )}
      onClick={() => {
        navigate(
          resolveTilintarkastusNavigationPath(
            vaatimus,
            TILINTARKASTUS_ROUTES.ALOITUS
          )
        )
      }}
      fullWidth={false}
      variant="default"
    >
      {children}
    </OverviewButton>
  )
}

const MitaSeuraavaksiTapahtuuBlock = () => {
  const [t, i18n] = useTranslation()
  return (
    <Block mt="m">
      <Heading variant="h4">{t('mitaSeuraavaksiTapahtuu')}</Heading>
      <Block mt="xs" />
      <BulletList>
        <li>
          <Text>{t('tilikorttiLahetyksenJalkeenTarkastammeTilin')}</Text>
        </li>
        <li>
          <Text>{t('tilikorttiSaatTarkastetunTilin')}</Text>
        </li>
        <li>
          <Text>
            {t('tilikorttiTarkastaKasittelyaika')}&nbsp;
            <ExternalLink
              href={kasittelyAjatUrl(
                i18n.language as HolhousAsiointiLanguageCode
              )}
              toNewWindow
              labelNewWindow={t('avautuuUuteenValilehteen')}
            >
              {t('henkiloAsiakkaidenKasittelyajatSivulta')}
            </ExternalLink>
          </Text>
        </li>
      </BulletList>
    </Block>
  )
}

const TulostaYhteenvetoBlock: FC<{
  vaatimus: AsiointiSeurantaAsiavaatimus
}> = ({ vaatimus }) => {
  const [t, i18n] = useTranslation()
  const translations = omaisuudenHallintaTranslationsByType[vaatimus.asiaType]
  const [yhteenvetoModalVisible, setYhteenvetoModalVisible] = useState(false)
  const tilintarkastusI18n = initTilinpaatosAsiointiI18n(
    i18n.language as LanguageCode
  )
  return (
    <Block
      mt="m"
      data-test-id={mkOmaisuudenHallintaTestId(
        'tulosta-yhteenveto-block',
        vaatimus.seurantaAsiavaatimusId
      )}
    >
      <Heading variant="h4" mb="xs">
        {t('otaYhteenvetoTalteen')}
      </Heading>
      <MultiParagraphText text={t(translations.otaYhteenvetoTalteen)} />
      <Block mt="m" />
      <Button
        onClick={() => setYhteenvetoModalVisible(true)}
        variant="secondary"
      >
        {t('avaaYhteenveto')}
      </Button>
      <I18nextProvider i18n={tilintarkastusI18n}>
        <TulostaYhteenvetoModal
          setVisible={setYhteenvetoModalVisible}
          visible={yhteenvetoModalVisible}
          vaatimus={vaatimus}
        />
      </I18nextProvider>
    </Block>
  )
}

const TiliAvoinnaInfo: FC<{ vaatimus: AsiointiSeurantaAsiavaatimus }> = ({
  vaatimus,
}) => {
  const [t] = useTranslation()
  const translations = omaisuudenHallintaTranslationsByType[vaatimus.asiaType]

  const isYllattavaPaatostili =
    vaatimus.asiaType === AsiointiType.PAATOSTILI &&
    vaatimus.guardianshipTerminationType !== 'PAAMIEHEN_TAYSI_IKAISYYS' &&
    vaatimus.guardianshipTerminationType !==
      'MAARAAJAN_VOIMASSA_OLEVA_VALTUUTUS' &&
    vaatimus.guardianshipTerminationType !== 'EDUNVALVONTA_MAARAAIKA_PAATTYNYT'

  if (vaatimus.lomakeBlockedReason) {
    return (
      <>
        {vaatimus.lomakeBlockedReason === 'ODOTTAA_EDELLISTA_VUOSITILIA' && (
          <Paragraph>
            {t(translations.tiliOdottaaEdellistaVuositilia)}
          </Paragraph>
        )}
        {vaatimus.lomakeBlockedReason === 'ODOTTAA_OMAISUUSLUETTELOA' && (
          <Paragraph>{t(translations.tiliOdottaaOmaisuusluetteloa)}</Paragraph>
        )}
        {vaatimus.lomakeBlockedReason === 'ODOTTAA_AIKARAJAA' && (
          <Paragraph>
            {t(translations.tiliOdottaaAikarajaa, {
              pvm: IsoDatetimeToUiDate(vaatimus.lomakeBlockedUntil),
            })}
          </Paragraph>
        )}
      </>
    )
  } else if (vaatimus.lahetysBlockedUntil) {
    return (
      <Paragraph>
        {t(translations.voitLahettaaTilinAlkaen, {
          pvm: IsoDatetimeToUiDate(vaatimus.lahetysBlockedUntil),
        })}
      </Paragraph>
    )
  } else if (vaatimus.isTiliLate) {
    return (
      <>
        <Heading variant="h4" mb="xxs">
          <MyohassaAlert color={suomifiDesignTokens.colors.alertBase} />
          {t(translations.tiliMyohassa)}
        </Heading>

        {(vaatimus.dueDateType === 'LYKKAYKSEN_MAARAPAIVA' ||
          vaatimus.dueDateType === 'MAARAPAIVA') && (
          <>
            <Paragraph mb="xs">
              {t(translations.etOleLahettanytTiliaMennessa, {
                pvm: IsoDatetimeToUiDate(vaatimus.dueDate),
              })}
            </Paragraph>
            <Paragraph>{t(translations.lahetaTiliPian)}</Paragraph>
          </>
        )}

        {vaatimus.dueDateType === 'KEHOTUS_MAARAPAIVA' && (
          <>
            <Paragraph mb="xs">
              {t(translations.ensimmainenKehotusInfo)}
            </Paragraph>
            <Paragraph>
              {t(translations.lahetaTiliPian).replace('.', ', ')}
              <Text variant="bold">
                {t('viimeistaanPvm', {
                  pvm: IsoDatetimeToUiDate(vaatimus.dueDate),
                })}
              </Text>
            </Paragraph>
          </>
        )}

        {vaatimus.dueDateType === 'TOINEN_KEHOTUS_MAARAPAIVA' && (
          <>
            <Paragraph mb="xs">
              {t(translations.toinenKehotusInfo1, {})}
            </Paragraph>
            <Paragraph>
              {t(translations.toinenKehotusInfo2, {
                pvm: IsoDatetimeToUiDate(vaatimus.dueDate),
              })}
            </Paragraph>
          </>
        )}
      </>
    )
  } else {
    return (
      <>
        {vaatimus.dueDateType === 'LYKKAYKSEN_MAARAPAIVA' && (
          <Heading variant="h4" mb="xxs">
            {t(translations.lykkaysHeading)}
          </Heading>
        )}

        <Paragraph mb="xs">
          {isYllattavaPaatostili && `${t('tehtavasiEdunvalvojanaPaattyi')} `}
          {t(translations.lahetaViimeistaan)}&nbsp;
          {IsoDatetimeToUiDate(vaatimus.sentDate ?? vaatimus.dueDate)}.
        </Paragraph>

        {isYllattavaPaatostili && (
          <Paragraph>
            {t('vuositilinTiedotSiirrettyPaatostiliin', {
              pvm: IsoDatetimeToUiDate(vaatimus.accountingPeriodStartDate),
            })}
          </Paragraph>
        )}
      </>
    )
  }
}

const PaamiehenTili = styled.div`
  border: 1px solid ${suomifiDesignTokens.colors.depthLight1};
  padding: ${suomifiDesignTokens.spacing.m};
`

const VaatimusContainer = styled.div`
  gap: ${suomifiDesignTokens.spacing.m};
  display: flex;
  flex-direction: column;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${suomifiDesignTokens.spacing.xxs};
`

const MyohassaAlert = styled(IconError)`
  display: inline-block;
  vertical-align: -3px;
  margin-right: ${suomifiDesignTokens.spacing.xxs};
`

export default OmaisuudenHallinta

const omaisuudenHallintaTranslationsByType = {
  OMAISUUSLUETTELO: {
    voitLahettaaTilinAlkaen: '',
    tilitype: 'omaisuusluettelo',
    lahetetty: 'omaisuusluetteloOnSaapunut',
    lahetaViimeistaan: 'lahetaOmaisuusluetteloViimeistaan',
    otaYhteenvetoTalteen: 'otaYhteenvetoTalteenOmaisuusluettelo',
    lykkaysHeading: 'maaraaikaOnMuuttunut_OMAISUUSLUETTELO',
    tiliMyohassa: 'tiliOnMyohassa_OMAISUUSLUETTELO',
    lahetaTiliPian: 'lahetaTiliPian_OMAISUUSLUETTELO',
    etOleLahettanytTiliaMennessa:
      'etOleLahettanytTiliaMennessa_OMAISUUSLUETTELO',
    ensimmainenKehotusInfo: 'ensimmainenKehotusInfo_OMAISUUSLUETTELO',
    toinenKehotusInfo1: 'toinenKehotusInfo1_OMAISUUSLUETTELO',
    toinenKehotusInfo2: 'toinenKehotusInfo2_OMAISUUSLUETTELO',
    tiliOdottaaAikarajaa: '',
    tiliOdottaaOmaisuusluetteloa: '',
    tiliOdottaaEdellistaVuositilia: '',
    tiliOnTarkastettu: '',
  },
  PAATOSTILI: {
    voitLahettaaTilinAlkaen: 'voitLahettaaTilinAlkaen_PAATOSTILI',
    tilitype: 'paatostili',
    lahetetty: 'paatostiliOnSaapunut',
    lahetaViimeistaan: 'lahetaPaatostiliViimeistaan',
    otaYhteenvetoTalteen: 'otaYhteenvetoTalteenPaatostili',
    lykkaysHeading: 'maaraaikaOnMuuttunut_PAATOSTILI',
    tiliMyohassa: 'tiliOnMyohassa_PAATOSTILI',
    lahetaTiliPian: 'lahetaTiliPian_PAATOSTILI',
    etOleLahettanytTiliaMennessa: 'etOleLahettanytTiliaMennessa_PAATOSTILI',
    ensimmainenKehotusInfo: 'ensimmainenKehotusInfo_PAATOSTILI',
    toinenKehotusInfo1: 'toinenKehotusInfo1_PAATOSTILI',
    toinenKehotusInfo2: 'toinenKehotusInfo2_PAATOSTILI',
    tiliOdottaaAikarajaa: 'tiliOdottaaAikarajaa_PAATOSTILI',
    tiliOdottaaOmaisuusluetteloa: 'tiliOdottaaOmaisuusluetteloa_PAATOSTILI',
    tiliOdottaaEdellistaVuositilia: 'tiliOdottaaEdellistaVuositilia_PAATOSTILI',
    tiliOnTarkastettu: 'tiliOnTarkastettu_PAATOSTILI',
  },
  VUOSITILI: {
    voitLahettaaTilinAlkaen: 'voitLahettaaTilinAlkaen_VUOSITILI',
    tilitype: 'vuositili',
    lahetetty: 'vuositiliOnSaapunut',
    lahetaViimeistaan: 'lahetaVuositiliViimeistaan',
    otaYhteenvetoTalteen: 'otaYhteenvetoTalteenVuositili',
    lykkaysHeading: 'maaraaikaOnMuuttunut_VUOSITILI',
    tiliMyohassa: 'tiliOnMyohassa_VUOSITILI',
    lahetaTiliPian: 'lahetaTiliPian_VUOSITILI',
    etOleLahettanytTiliaMennessa: 'etOleLahettanytTiliaMennessa_VUOSITILI',
    ensimmainenKehotusInfo: 'ensimmainenKehotusInfo_VUOSITILI',
    toinenKehotusInfo1: 'toinenKehotusInfo1_VUOSITILI',
    toinenKehotusInfo2: 'toinenKehotusInfo2_VUOSITILI',
    tiliOdottaaAikarajaa: 'tiliOdottaaAikarajaa_VUOSITILI',
    tiliOdottaaOmaisuusluetteloa: 'tiliOdottaaOmaisuusluetteloa_VUOSITILI',
    tiliOdottaaEdellistaVuositilia: 'tiliOdottaaEdellistaVuositilia_VUOSITILI',
    tiliOnTarkastettu: 'tiliOnTarkastettu_VUOSITILI',
  },
}
