import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { styled } from 'styled-components'
import { getStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import LupaCard from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-type-select/LupaCard'
import {
  AsiointiLupaInfo,
  asiointiLupaInfos,
  ExternalLupaInfo,
  externalLupaInfos,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-info'
import {
  AsiointiLupaKeywordId,
  AsiointiLupaType,
  AsiointiLupaTypeId,
  ExternalLupaTypeId,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import { toggleSelectedLupaType } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/lupatype-actions'
import {
  Block,
  Heading,
  InlineAlert,
  Paragraph,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import { mkHakemusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { LupaApplicationStep } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import LupaFilter from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-type-select/LupaFilter'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { useValidationErrorFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/validation/validation-error-focus.util'
import { FocusableDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'

type LupaTypeId = AsiointiLupaTypeId | ExternalLupaTypeId
type LupaInfo = AsiointiLupaInfo | ExternalLupaInfo

const allLupaTypeInfos: Readonly<Record<LupaTypeId, LupaInfo>> = {
  ...asiointiLupaInfos,
  ...externalLupaInfos,
}

const asiointiLupaTypeIds = new Set(Object.keys(asiointiLupaInfos))
const isAsiointiLupaTypeId = (
  lupaTypeId: LupaTypeId
): lupaTypeId is AsiointiLupaTypeId => asiointiLupaTypeIds.has(lupaTypeId)

const LupaTypeSelectPage: React.FC = observer(() => {
  const { batch, showValidationErrorsForSteps, validationFailedForSteps } =
    getStore()
  const selectedTypes = batch.applications.map((a) => a.typeId) as LupaTypeId[]
  const lupaTypeIds = Object.keys(allLupaTypeInfos) as LupaTypeId[]
  const [selectedLupaKeywords, setSelectedLupaKeywords] = useState<
    AsiointiLupaKeywordId[]
  >([])
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet

  const handleKeywordClick = (keyword: AsiointiLupaKeywordId) => {
    if (selectedLupaKeywords.includes(keyword)) {
      setSelectedLupaKeywords(
        selectedLupaKeywords.filter((item) => item !== keyword)
      )
    } else {
      setSelectedLupaKeywords([...selectedLupaKeywords, keyword])
    }
  }

  const ClearFilters = () => {
    setSelectedLupaKeywords([])
  }

  const getLupaTypeIdsWithKeywords = (keywords: AsiointiLupaKeywordId[]) => {
    if (keywords.length > 0) {
      return lupaTypeIds.filter((lupaTypeId) => {
        const lupaTypeInfo = allLupaTypeInfos[lupaTypeId]
        return keywords.some((keyword) =>
          lupaTypeInfo.keywords.includes(keyword)
        )
      })
    }
    return lupaTypeIds
  }

  const lupaFilterResult = getLupaTypeIdsWithKeywords(selectedLupaKeywords)

  const applicationError =
    showValidationErrorsForSteps.has(LupaApplicationStep.LUPA_TYPE_SELECT) &&
    validationFailedForSteps.has(LupaApplicationStep.LUPA_TYPE_SELECT) &&
    batch.validationErrors.find(({ field }) => field === 'applications')

  const alertMissingLupaRef = useValidationErrorFocus<HTMLDivElement>(
    mkHakemusTestId('alert-missing-lupa')
  )
  return (
    <section
      data-test-id={mkHakemusTestId(LupaApplicationStep.LUPA_TYPE_SELECT)}
    >
      <LupaFilter
        selectedKeywords={selectedLupaKeywords}
        onChange={handleKeywordClick}
        clearFilters={ClearFilters}
        data-test-id={mkHakemusTestId('lupa-filter')}
      />
      {!!applicationError && (
        <FocusableDiv
          ref={alertMissingLupaRef}
          tabIndex={-1}
          data-test-id={mkHakemusTestId('alert-missing-lupa')}
        >
          <Block mt="xs" />
          <InlineAlert
            status="error"
            labelText={t(applicationError.translationKey)}
          >
            {t('virheValitseVahintaaYksiLupaAsiaEsim', {
              lupaType: t(
                `asiointiLupaType-${AsiointiLupaType.ASUNNON_MYYNTI}`
              ),
            })}
          </InlineAlert>
        </FocusableDiv>
      )}
      <Block mt="m" />
      <Block
        data-test-id={mkHakemusTestId('lupa-filter-results')}
        aria-live="polite"
        aria-atomic="true"
        mx={isTablet ? '0' : 's'}
        mb="xs"
      >
        <Heading variant="h2">
          {t('result', { count: lupaFilterResult.length })}
        </Heading>
        <Block mt="xs" />
        <Paragraph>{t('valitseLupaDescription')}</Paragraph>
      </Block>
      <LupaCardList
        tabIndex={-1}
        data-test-id={mkHakemusTestId('lupien-valinta')}
      >
        {lupaFilterResult.map((lupaTypeId) => {
          const isApplicableInSystem = isAsiointiLupaTypeId(lupaTypeId)
          return (
            <li key={lupaTypeId}>
              <Block mt="xs" />
              <LupaCard
                lupaTypeId={lupaTypeId}
                lupaTypeInfo={allLupaTypeInfos[lupaTypeId]}
                isApplicableInSystem={isApplicableInSystem}
                isSelected={selectedTypes.includes(lupaTypeId)}
                onChange={(isSelected) => {
                  if (isApplicableInSystem) {
                    void toggleSelectedLupaType(lupaTypeId, isSelected)
                  }
                }}
              />
            </li>
          )
        })}
      </LupaCardList>
    </section>
  )
})

export const LupaCardList = styled.ul`
  margin: 0 ${suomifiDesignTokens.spacing.s};

  @media ${device.tablet} {
    margin: 0;
  }
`

export default LupaTypeSelectPage
