import { AsiakirjaMetadata } from 'asia-common/src/vtj/koodisto/asiakirja-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Retention, RetentionMetadata } from 'asia-common/src/vtj/koodisto/retention-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'
import { Vireilletulokanava, VireilletulokanavaId } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { AsiaPersonRole, AsiaPersonRoleId } from 'asia-common/src/vtj/koodisto/asia-enums'

export const nimiasiatJulkinenEiHenkilotietoja: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  publicityMetadata: {
    [PublicityClass.JULKINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.EI_SISALLA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.EI_SISALLA_HENKILOTIETOJA],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
  publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
  publicityMetadata: {
    [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatOsittainSalassaPidettavaErityisiaHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
  publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
  publicityMetadata: {
    [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatSalassaPidettavaErityisiaHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
      securityReasonIds: [
        SecurityReason.JULKL_621_1999_24_1_KOHTA_24,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_25,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B,
        SecurityReason.JULKL_621_1999_24_1_KOHTA_32,
      ],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const nimiasiatPysyvaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.PYSYVA,
  retentionIds: [Retention.PYSYVA],
}

// FIXME: Remove additional Toivo-asiakirjatypes https://jira.dvv.fi/browse/OIVA-5614
//
// Toivo-asiakirjatypes were taken from: 7.1 nimenmuutoshakemusasiat
// https://vrk-bitbucket-01.eden.csc.fi/projects/VTJ/repos/toivo/browse/src/common/toivo-document-types-staging.json
export const commonNimiasiatAsiakirjaMetadata: AsiakirjaMetadata[] = [
  {
    asiakirjaTypeId: AsiakirjaType.NIMENMUUTOSHAKEMUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Nimenmuutoshakemus
      `8ed5ef3f-bea9-4925-8f47-761ecd5f1991`,
      // Nimenmuutoshakemus (verkkolomake)
      `209f9ffd-979e-4636-9ab2-65549f85ff9d`,
    ],
    defaultToivoAsiakirjaTypeId: `8ed5ef3f-bea9-4925-8f47-761ecd5f1991`,
  },
  {
    asiakirjaTypeId: AsiakirjaType.LISASELVITYS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Lisäselvitys
      `bc2d1085-4ffb-4d9d-ba10-d4b6675b6296`,
      // Lisäselvitys tai täydennys (julkinen) (vanhentunut, poistuva)
      `45a8a13b-79f0-48f9-9be9-e8ba03e90c9b`,
    ],
    defaultToivoAsiakirjaTypeId: 'bc2d1085-4ffb-4d9d-ba10-d4b6675b6296',
  },
  {
    asiakirjaTypeId: AsiakirjaType.VIRKATODISTUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Virkatodistus
      `6206b5c2-a5cc-4466-a273-7468335d8377`,
      // Virkatodistus (julkinen) (vanhentunut, poistuva)
      `167e790d-6ed5-4bd7-905a-7c50ede3f3d4`,
    ],
    defaultToivoAsiakirjaTypeId: '6206b5c2-a5cc-4466-a273-7468335d8377',
  },
  {
    asiakirjaTypeId: AsiakirjaType.SUOSTUMUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Suostumus
      `61fb6467-158a-4a1b-9c96-690563990c4e`,
      // Suostumus (julkinen) (vanhentunut, poistuva)
      `9e0d0568-c90c-4fa4-9a14-34bd61d1e800`,
    ],
    defaultToivoAsiakirjaTypeId: '61fb6467-158a-4a1b-9c96-690563990c4e',
  },
  {
    asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
    restrictionMetadata: nimiasiatSalassaPidettavaErityisiaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Lääkärinlausunto
      'e2618123-5347-4893-9650-53ee7ef950b4',
    ],
    defaultToivoAsiakirjaTypeId: 'e2618123-5347-4893-9650-53ee7ef950b4',
  },
  {
    asiakirjaTypeId: AsiakirjaType.KARAJAOIKEUDEN_PAATOS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Käräjäoikeuden päätö
      `0ebb7182-c19b-4186-b366-569f31ac4c47`,
      //  Käräjäoikeuden päätös (julkinen) (vanhentunut, poistuva)
      `c0af0f61-43bf-4f4c-938a-ab47f5aa8202`,
    ],
    defaultToivoAsiakirjaTypeId: '0ebb7182-c19b-4186-b366-569f31ac4c47',
  },
  {
    asiakirjaTypeId: AsiakirjaType.LAUSUNTO_SOSIAALITOIMELTA,
    restrictionMetadata: nimiasiatSalassaPidettavaErityisiaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Lausunto sosiaalitoimelta
      '78c1ddd8-fb69-4b65-b5f6-244c08858ee4',
    ],
    defaultToivoAsiakirjaTypeId: '78c1ddd8-fb69-4b65-b5f6-244c08858ee4',
  },
  {
    asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Muu liite
      'a088b819-6f31-4c71-a837-bcdd1aac02df',
      // Muu liite (julkinen) (vanhentunut, poistuva)
      '9aaaa276-bdfc-40a7-aa8a-50c27a86e419',
      // Muu asiakirja (vanhentunut, poistuva)
      '357919fa-2fea-46eb-8f1b-42baa3636ea4',
    ],
    defaultToivoAsiakirjaTypeId: 'a088b819-6f31-4c71-a837-bcdd1aac02df',
  },
  {
    asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    // FIXME: https://jira.dvv.fi/browse/OIVA-5614
    // Only allowed origin type should be LAADITTU
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Täydennyspyyntö
      '33205813-5d50-46c7-ac35-4c084b3540a3',
      // Täydennyspyyntö (julkinen) (vanhentunut, poistuva)
      '9fb52111-1a74-442e-8636-b4239662afeb',
    ],
    defaultToivoAsiakirjaTypeId: '33205813-5d50-46c7-ac35-4c084b3540a3',
  },
  {
    asiakirjaTypeId: AsiakirjaType.LAUSUNTOPYYNTO,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    // FIXME: https://jira.dvv.fi/browse/OIVA-5614
    // Only allowed origin type should be LAADITTU
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Lausuntopyyntö
      'cc7155e3-c9d9-4173-a565-cd5e80bd0db0',
      // Lausuntopyyntö (julkinen) (vanhentunut, poistuva
      '3c08538b-7940-435d-8571-a637a1856148',
      // Lausuntopyyntö (salassa pidettävä) (vanhentunut, poistuva)
      '86d42f71-f883-4b9f-8fd0-7f3f400dd966',
    ],
    defaultToivoAsiakirjaTypeId: 'cc7155e3-c9d9-4173-a565-cd5e80bd0db0',
  },

  {
    asiakirjaTypeId: AsiakirjaType.TAYDENNYS_ASIAKKAALTA,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Täydennys asiakkaalta
      'df819c3d-a6c4-45d6-9512-d83b59e5d2d5',
      // Täydennys asiakkaalta (julkinen) (vanhentunut, poistuva)
      '46fccf28-4104-4869-a1b3-b410f26c122c',
    ],
    defaultToivoAsiakirjaTypeId: 'df819c3d-a6c4-45d6-9512-d83b59e5d2d5',
  },
  {
    asiakirjaTypeId: AsiakirjaType.HENKILON_NIMI_LAKI_VAATIMUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Henkilön nimi laki vaatimus
      'd907156a-0e0c-4591-8b38-92d95889f273',
    ],
    defaultToivoAsiakirjaTypeId: 'd907156a-0e0c-4591-8b38-92d95889f273',
  },
  {
    asiakirjaTypeId: AsiakirjaType.LAUSUNTOPYYNTO_NIMILAUTAKUNNALLE,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    // FIXME: https://jira.dvv.fi/browse/OIVA-5614
    // Only allowed origin type should be LAADITTU
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Lausuntopyyntö nimilautakunnalle
      '7ea414d6-8092-4c64-80d5-ed1b6d1700d1',
    ],
    defaultToivoAsiakirjaTypeId: '7ea414d6-8092-4c64-80d5-ed1b6d1700d1',
  },
  {
    asiakirjaTypeId: AsiakirjaType.NIMILAUTAKUNNAN_LAUSUNTO,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Nimilautakunnan lausunto
      'd76d1660-1e3c-4e01-967b-c7cb722386f0',
      // Nimilautakunnan lausunto (julkinen) (vanhentunut, poistuva)
      '9fd34560-516a-47ac-a0e2-4903fe595026',
    ],
    defaultToivoAsiakirjaTypeId: 'd76d1660-1e3c-4e01-967b-c7cb722386f0',
  },
  {
    asiakirjaTypeId: AsiakirjaType.VASTINEPYYNTO_ASIAKKAALLE,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    // FIXME: https://jira.dvv.fi/browse/OIVA-5614
    // Only allowed origin type should be LAADITTU
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Vastinepyyntö asiakkaalle
      'b089bb6a-9549-4d5b-b9e4-3dee3b199bd4',
      // Vastinepyyntö hakijalle (julkinen) (vanhentunut, poistuva)
      '56216e4e-41e8-44a6-aae2-da3332380fda',
    ],
    defaultToivoAsiakirjaTypeId: 'b089bb6a-9549-4d5b-b9e4-3dee3b199bd4',
  },
  {
    asiakirjaTypeId: AsiakirjaType.VASTINE_ASIAKKAALTA,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Vastine asiakkaalta
      '2a2ff73c-9492-4238-95cd-f79442ce5aed',
      // Vastine (julkinen) (vanhentunut, poistuva)
      'c90aa1db-7e52-4d8e-9300-971e8b54be15',
    ],
    defaultToivoAsiakirjaTypeId: '2a2ff73c-9492-4238-95cd-f79442ce5aed',
  },
  {
    asiakirjaTypeId: AsiakirjaType.MUISTUTUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    // FIXME: https://jira.dvv.fi/browse/OIVA-5614
    // Only allowed origin type should be LAADITTU
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Muistutus
      'bd39e2cc-8fa3-4e5a-bbff-f0371fa16748',
    ],
    defaultToivoAsiakirjaTypeId: 'bd39e2cc-8fa3-4e5a-bbff-f0371fa16748',
  },
  {
    asiakirjaTypeId: AsiakirjaType.PAATOS_NIMENMUUTOKSESTA,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaErityisiaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    // FIXME: https://jira.dvv.fi/browse/OIVA-5614
    // Only allowed origin type should be LAADITTU
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Päätös nimenmuutoksesta
      'bb9f436a-6eb3-4dd2-af23-a71c6c5387db',
      // Päätös nimenmuutoshakemusasiassa (julkinen) (vanhentunut, poistuva)
      'c0420101-ce83-465f-ba15-8094bd9ee3e2',
    ],
    defaultToivoAsiakirjaTypeId: 'bb9f436a-6eb3-4dd2-af23-a71c6c5387db',
  },
  {
    asiakirjaTypeId: AsiakirjaType.MUUTOKSENHAKUOHJE,
    restrictionMetadata: nimiasiatJulkinenEiHenkilotietoja,
    retentionMetadata: nimiasiatPysyvaRetention,
    // FIXME: https://jira.dvv.fi/browse/OIVA-5614
    // Only allowed origin type should be LAADITTU
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: ['6dae0105-29ea-4bfa-bdbd-3e6ae54f304d'],
    defaultToivoAsiakirjaTypeId: '6dae0105-29ea-4bfa-bdbd-3e6ae54f304d',
  },

  {
    asiakirjaTypeId: AsiakirjaType.MUISTIO,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Muistio
      'ca31282f-29a3-4190-9cce-5d35db07226b',
      // Muistio (julkinen) (vanhentunut, poistuva)
      'aa00ba01-008e-4857-bf80-ab38b6e74606',
    ],
    defaultToivoAsiakirjaTypeId: 'ca31282f-29a3-4190-9cce-5d35db07226b',
  },

  {
    asiakirjaTypeId: AsiakirjaType.SUKUSELVITYS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Sukuselvitys
      'b18c5ee5-49b1-4cb7-86c5-b41cb17e912e',
    ],
    defaultToivoAsiakirjaTypeId: 'b18c5ee5-49b1-4cb7-86c5-b41cb17e912e',
  },
  {
    asiakirjaTypeId: AsiakirjaType.KOPIO_HENKILOTODISTUKSESTA_TAI_PASSISTA,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Kopio henkilötodistuksesta tai passista
      'd162aa52-4d79-42b0-8d0b-7f10f50f8b3f',
    ],
    defaultToivoAsiakirjaTypeId: 'd162aa52-4d79-42b0-8d0b-7f10f50f8b3f',
  },
  {
    asiakirjaTypeId: AsiakirjaType.SYNTYMATODISTUS,
    restrictionMetadata: nimiasiatOsittainSalassaPidettavaHenkilotietojaRestriction,
    retentionMetadata: nimiasiatPysyvaRetention,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    toivoAsiakirjaTypeIds: [
      // Syntymätodistus
      'b45fb182-660f-4b69-8ff9-3ceb006e38be',
    ],
    defaultToivoAsiakirjaTypeId: 'b45fb182-660f-4b69-8ff9-3ceb006e38be',
  },
]

export const commonNimiasiatVireilletulokanavaMetadata: VireilletulokanavaId[] = [
  Vireilletulokanava.ITSEPALVELU,
  Vireilletulokanava.ELSA,
]

export const commonNimiasiatPersonRoleMetadata: AsiaPersonRoleId[] = [AsiaPersonRole.ASIAKAS]
