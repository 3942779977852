import { i18n } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { createI18nInstance } from 'common-translations/src/vtj/i18next-utils'
import commonTranslations from 'asia-common/src/vtj/localization/common.translations'
import holhousCommonTranslations from 'holhous-common/src/vtj/static/holhous-common.translations'
import { LanguageCode } from 'common/src/vtj/LanguageCode.enum'
import fi from 'edunvalvonta-asiointi/src/vtj/asiointi/localization/localization_fi.json'
import sv from 'edunvalvonta-asiointi/src/vtj/asiointi/localization/localization_sv.json'

export const edunvalvontaAsiointiTranslations = {
  namespace: 'edunvalvonta-asiointi',
  translations: { fi, sv },
}

type I18nCreateOptions = {
  language: LanguageCode
  initForReact?: boolean
}

let instance: i18n

export const initEdunvalvontaAsiointiI18n = (
  options: I18nCreateOptions
): i18n => {
  if (instance) {
    return instance
  }
  return createEdunvalvontaAsiointiI18n(options)
}

// Server uses an i18n instance per request
export function createEdunvalvontaAsiointiI18n({
  language,
  initForReact,
}: I18nCreateOptions): i18n {
  return createI18nInstance({
    language,
    defaultBundle: edunvalvontaAsiointiTranslations,
    additionalBundles: [holhousCommonTranslations, commonTranslations],
    modules: initForReact ? [initReactI18next] : [],
  })
}
