import createDomPurify, { DOMPurify, WindowLike } from 'dompurify'
import { isInsideBrowser } from 'common/src/vtj/browser/browser-util'

export const createDomPurifyForEnv = (): DOMPurify => {
  const isUnitTesting = process.env.NODE_ENV === 'test'
  if (!isInsideBrowser() || isUnitTesting) {
    // We are in the server bundle. Require JSDOM and use it as the window object
    // for DOMPurify. JSDOM cannot be required in the client bundle because JSDOM
    // is dependent on fs module which is only available on the server-side.
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const jsDom = require('jsdom')
    const window = new jsDom.JSDOM('').window
    return createDomPurify(window as unknown as WindowLike)
  } else {
    return createDomPurify
  }
}
