import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { Block, Heading } from 'suomifi-ui-components'
import { EiIlmoitettujaTietoja } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const Kayttovarat: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTilintarkastusStore().lomake.tili
  return (
    <section data-test-id={mkYhteenvetoTestId('kayttovarat-container')}>
      <Heading variant="h3">{t('kayttovaratHeading')}</Heading>

      {tili.paamiehenOmaisuus.kayttovarakaytanto ? (
        <Block mt="xs">
          <Heading variant="h5" as="h4">
            {t('mitenKayttovarojaOnHenkilolleAnnettu')}
          </Heading>
          <MultiParagraphText
            text={tili.paamiehenOmaisuus.kayttovarakaytanto}
          />
        </Block>
      ) : (
        <EiIlmoitettujaTietoja />
      )}
    </section>
  )
})

export default Kayttovarat
