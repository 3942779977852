import type { TFunction } from 'i18next'
import { LupaAsiointiAttachment } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import { lupaAsiointiFileApi } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/api/file-api'
import { FileBoxFile } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/file/FileBox'
import { getErrorMessage } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/file/file.util'
import { MimeType } from 'common/src/vtj/MimeType.enum'

export const lupaAllowedMimeTypes = [MimeType.PDF]
export const lupaMaxSizeInBytes = 4194304

export const attachmentToFileBoxFile = (
  t: TFunction,
  ak: LupaAsiointiAttachment
): FileBoxFile => ({
  id: ak.sourceFileId,
  name: ak.filename,
  mimeType: ak.mimeType,
  sizeBytes: ak.sizeBytes,
  status: ak.status === 'checking' ? 'loading' : ak.status,
  statusText: ak.status === 'checking' ? t('tiedostoaTarkistetaan') : undefined,
  isRemovable: true,
  downloadHref:
    ak.status === 'success'
      ? lupaAsiointiFileApi.getDownloadUrl(ak)
      : undefined,
  errorMessage:
    ak.status === 'failed' ? getErrorMessage(t, ak.error) : undefined,
})
