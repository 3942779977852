import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { OrganizationType } from 'asia-common/src/vtj/koodisto/organization-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import {
  edunvalvonta10VuottaRetention,
  edunvalvonta50VuottaRetention,
  edunvalvontaJulkinenEiHenkilotietojaRestriction,
  edunvalvontaJulkinenHenkilotietojaRestriction,
  edunvalvontaOsittainSalassaPidettava100VuottaKohta23ErityisiaHenkilotietojaRestriction,
  edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
  edunvalvontaPysyvaRetention,
  edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta28ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/common-edunvalvontavaltuutus-metadata'

export const selvitystehtavaMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    // Hakemus edunvalvontavaltuutuksen vahvistamiseksi
    {
      asiakirjaTypeId: AsiakirjaType.HAKEMUS_EDUNVALVONTAVALTUUTUKSEN_VAHVISTAMISEKSI,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['0e8d7be3-94c8-4e8a-8679-40c4cbf510c4'],
      defaultToivoAsiakirjaTypeId: '0e8d7be3-94c8-4e8a-8679-40c4cbf510c4',
    },
    // Hakemus edunvalvontavaltuutuksen peruuttamisen vahvistamiseksi
    {
      asiakirjaTypeId: AsiakirjaType.HAKEMUS_EDUNVALVONTAVALTUUTUKSEN_PERUUTTAMISEN_VAHVISTAMISEKSI,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['21d04099-6091-447f-9aae-cf1997e4b902'],
      defaultToivoAsiakirjaTypeId: '21d04099-6091-447f-9aae-cf1997e4b902',
    },
    // Valtuuttajan lausuma
    {
      asiakirjaTypeId: AsiakirjaType.VALTUUTTAJAN_LAUSUMA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['6d76d013-ac30-4c9d-88b5-abf8a24d79e6'],
      defaultToivoAsiakirjaTypeId: '6d76d013-ac30-4c9d-88b5-abf8a24d79e6',
    },
    // Alkuperäinen edunvalvontavaltakirja
    {
      asiakirjaTypeId: AsiakirjaType.ALKUPERAINEN_EDUNVALVONTAVALTAKIRJA,
      restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['851f7449-c1c8-440f-b40f-61668a8a361d'],
      defaultToivoAsiakirjaTypeId: '851f7449-c1c8-440f-b40f-61668a8a361d',
    },
    // Edunvalvontavaltakirjan jäljennös
    {
      asiakirjaTypeId: AsiakirjaType.EDUNVALVONTAVALTAKIRJAN_JALJENNOS,
      restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['6ae63178-337a-4db2-afdc-897f372bf23f'],
      defaultToivoAsiakirjaTypeId: '6ae63178-337a-4db2-afdc-897f372bf23f',
    },
    // Lääkärinlausunto
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['e988cd1f-2875-4e91-8a09-1c39a2fc0a4b'],
      defaultToivoAsiakirjaTypeId: 'e988cd1f-2875-4e91-8a09-1c39a2fc0a4b',
    },
    // Valtuuttajan läheisen lausuma
    {
      asiakirjaTypeId: AsiakirjaType.VALTUUTTAJAN_LAHEISEN_LAUSUMA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['812e0ce3-6de5-4801-957b-d43d245c3fab'],
      defaultToivoAsiakirjaTypeId: '812e0ce3-6de5-4801-957b-d43d245c3fab',
    },
    // Valtuutetun lausuma
    {
      asiakirjaTypeId: AsiakirjaType.VALTUUTETUN_LAUSUMA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['04abbd9a-5d1c-442c-bc9d-19ee90fe7b0e'],
      defaultToivoAsiakirjaTypeId: '04abbd9a-5d1c-442c-bc9d-19ee90fe7b0e',
    },
    // Muu liite
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['805c361b-2f72-46d0-853d-87ea7f564740'],
      defaultToivoAsiakirjaTypeId: '805c361b-2f72-46d0-853d-87ea7f564740',
    },
    // Täydennyspyyntö
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['0a906c70-35a6-4aba-9342-6825498c2fa4'],
      defaultToivoAsiakirjaTypeId: '0a906c70-35a6-4aba-9342-6825498c2fa4',
    },
    // Täydennys
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYS,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['e9d63ef8-5f2d-41d2-afd3-bfef1b1ebdba'],
      defaultToivoAsiakirjaTypeId: 'e9d63ef8-5f2d-41d2-afd3-bfef1b1ebdba',
    },
    // Kuulemiskirje valtuuttajalle
    {
      asiakirjaTypeId: AsiakirjaType.KUULEMISKIRJE_VALTUUTTAJALLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['662bfaa9-6c22-4937-9761-3a669e3a9110'],
      defaultToivoAsiakirjaTypeId: '662bfaa9-6c22-4937-9761-3a669e3a9110',
    },
    // Kuulemiskirje muulle henkilölle
    {
      asiakirjaTypeId: AsiakirjaType.KUULEMISKIRJE_MUULLE_HENKILOLLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['814141f9-2100-4902-a22d-88da877f2f05'],
      defaultToivoAsiakirjaTypeId: '814141f9-2100-4902-a22d-88da877f2f05',
    },
    // Lääkärinlausuntopyyntö
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTOPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['9ace728e-8ba5-441d-bab3-b0f33d0b86a2'],
      defaultToivoAsiakirjaTypeId: '9ace728e-8ba5-441d-bab3-b0f33d0b86a2',
    },
    // Muu lausuntopyyntö
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LAUSUNTOPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['460e8f93-783c-4866-a84a-d00b93409efc'],
      defaultToivoAsiakirjaTypeId: '460e8f93-783c-4866-a84a-d00b93409efc',
    },
    // Muu lausunto
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LAUSUNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['9b894ed3-cae0-4bc1-b084-dedc80f1c150'],
      defaultToivoAsiakirjaTypeId: '9b894ed3-cae0-4bc1-b084-dedc80f1c150',
    },
    // Selvityspyyntö
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['aed7a9d4-76b0-4061-a356-fffe4192ad1c'],
      defaultToivoAsiakirjaTypeId: 'aed7a9d4-76b0-4061-a356-fffe4192ad1c',
    },
    // Todistus ulosottorekisteristä
    {
      asiakirjaTypeId: AsiakirjaType.TODISTUS_ULOSOTTOREKISTERISTA,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['58c9e37f-d333-44fe-88b1-90926e40f9df'],
      defaultToivoAsiakirjaTypeId: '58c9e37f-d333-44fe-88b1-90926e40f9df',
    },
    // Rikosrekisteriote
    {
      asiakirjaTypeId: AsiakirjaType.RIKOSREKISTERIOTE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta28ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['f583a318-bd21-48fb-8e4a-f56f54ba6a30'],
      defaultToivoAsiakirjaTypeId: 'f583a318-bd21-48fb-8e4a-f56f54ba6a30',
    },
    // Tilien saldot ja tilitapahtumat
    {
      asiakirjaTypeId: AsiakirjaType.TILIEN_SALDOT_JA_TILITAPAHTUMAT,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['cf0e9c8a-dd44-47eb-a688-4ffdddfbcfcc'],
      defaultToivoAsiakirjaTypeId: 'cf0e9c8a-dd44-47eb-a688-4ffdddfbcfcc',
    },
    // Muu viraston laatima asiakirja
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['dfbff8e6-87a3-41d2-8716-2dc8576d933e'],
      defaultToivoAsiakirjaTypeId: 'dfbff8e6-87a3-41d2-8716-2dc8576d933e',
    },
    // Muu viraston saama asiakirja
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['96d1c7b3-3139-471a-ba0b-930b3a54a1b3'],
      defaultToivoAsiakirjaTypeId: '96d1c7b3-3139-471a-ba0b-930b3a54a1b3',
    },
    // Valitusosoitus
    {
      asiakirjaTypeId: AsiakirjaType.VALITUSOSOITUS,
      restrictionMetadata: edunvalvontaJulkinenEiHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['f8d0851e-b3e0-413e-8db9-3654d1330a18'],
      defaultToivoAsiakirjaTypeId: 'f8d0851e-b3e0-413e-8db9-3654d1330a18',
    },
    // Päätös
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS,
      restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['3ea3a173-c103-4f4d-861e-fb239a2987d0'],
      defaultToivoAsiakirjaTypeId: '3ea3a173-c103-4f4d-861e-fb239a2987d0',
    },
    // Esittelijän eriävä mielipide
    {
      asiakirjaTypeId: AsiakirjaType.ESITTELIJAN_ERIAVA_MIELIPIDE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['c3d50633-59d3-4807-82d5-f619064cfeb9'],
      defaultToivoAsiakirjaTypeId: 'c3d50633-59d3-4807-82d5-f619064cfeb9',
    },
    // Ilmoitus asian raukeamisesta
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_ASIAN_RAUKEAMISESTA,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['bc85316d-23f0-4a8e-b902-92ad1e63a7fd'],
      defaultToivoAsiakirjaTypeId: 'bc85316d-23f0-4a8e-b902-92ad1e63a7fd',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.PAKOLLISIA_ASIAKIRJOJA_PUUTTUU,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREHDITTY,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_KUULEMISTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [AsiaPersonRole.HAKIJA],
    organizationTypeIds: [
      OrganizationType.OSTOPALVELU_EDUNVALVONTATOIMISTO,
      OrganizationType.VALTION_EDUNVALVONTATOIMISTO,
    ],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.MUU_HENKILO],
  personTypeMetadata: [
    AsiaPersonType.IHMINEN,
    AsiaPersonType.ORGANISAATIO,
    AsiaPersonType.AMMATTIHENKILO,
    AsiaPersonType.YRITYS,
  ],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.EI_TOIMENPITEITA,
    manualRatkaisuTypeIds: [
      RatkaisuType.EI_TOIMENPITEITA,
      RatkaisuType.UUSI_ASIA_VIREILLE,
      RatkaisuType.YHDISTETTY_TOISEEN_ASIAAN,
      RatkaisuType.LOPPULAUSUNTO,
      RatkaisuType.MUU_TOIMENPIDE,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [
      SpecifierType.TILI_SAAPUMATTA,
      SpecifierType.TOISSIJAINEN_VALTUUTETTU,
      SpecifierType.VALTUUTETTU_KUOLLUT,
      SpecifierType.VALTUUTETUN_LUOPUMINEN,
      SpecifierType.KV_ASIA,
      SpecifierType.KARHUN_JALKEINEN_ASIAKASYHTEYDENOTTO,
      SpecifierType.EDUNVALVOJA_KUOLLUT,
      SpecifierType.NELIVUOTISSELVITYS,
      SpecifierType.UO_VIRANOMAISEN_ILMOITUS_ALAIKAISESTA_VELALLISESTA,
      SpecifierType.ALAIKAISEN_VAROJEN_ILMOITTAMINEN,
      SpecifierType.REKISTEROIDYN_ALAIKAISEN_HUOLTAJIEN_MUUTOS,
      SpecifierType.LAAKARINLAUSUNTO_OIKEUSTOIMIKELPOISUUDESTA,
    ],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS],
      prioritizedLaadittuAsiakirjaTypeIds: [
        AsiakirjaType.PAATOS,
        AsiakirjaType.ILMOITUS_ASIAN_RAUKEAMISESTA,
        AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
      ],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.TAYDENNYSPYYNTO],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.TAYDENNYS],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.TAYDENNYS,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA, Vireilletulokanava.VAESTOTIETOJARJESTELMA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  emailMetadata: {
    fromAddress: 'noreply@dvv.fi',
    useUserEmailIfPossible: true,
  },
  retentionMetadata: edunvalvontaPysyvaRetention,
  restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23ErityisiaHenkilotietojaRestriction,
}
