import { StaticChip, suomifiDesignTokens } from 'suomifi-ui-components'
import { styled } from 'styled-components'
import { observer } from 'mobx-react'
import { IsoDatetimeToUiDatetime } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { mkTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { AsiointiSeurantaAsiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/edunvalvontasuhteet/edunvalvontasuhteet-api.type'
import { AsiointiDraft } from 'edunvalvonta-asiointi/src/vtj/asiointi/draft/draft-api.type'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { SeurantaAsiaVaatimusTila } from 'tilintarkastus-common/src/vtj/types/seuranta-asiavaatimus/seuranta-asiavaatimus-enums'

const TiliStatusChip: React.FC<{
  vaatimus: Pick<
    AsiointiSeurantaAsiavaatimus,
    | 'seurantaAsiavaatimusId'
    | 'sentDate'
    | 'lomakeBlockedReason'
    | 'asiaType'
    | 'statusType'
  >
  draft?: AsiointiDraft
}> = observer(({ vaatimus, draft }) => {
  const [t] = useTranslation()
  return (
    <section data-test-id={mkTestId('tili-status-chip')}>
      {vaatimus.sentDate ? (
        <ChipForStatusSent>
          {vaatimus.asiaType === AsiointiType.OMAISUUSLUETTELO
            ? t('tiliStatus_VALMIS')
            : vaatimus.statusType === SeurantaAsiaVaatimusTila.PAATETTY
            ? t('tiliStatus_TARKASTETTU')
            : t('tiliStatus_LAHETETTY')}
          {(vaatimus.asiaType === AsiointiType.OMAISUUSLUETTELO ||
            vaatimus.statusType === SeurantaAsiaVaatimusTila.PAATETTY) && (
            <LahetettyIconDiv>✔</LahetettyIconDiv>
          )}
        </ChipForStatusSent>
      ) : vaatimus.lomakeBlockedReason ? (
        <ChipForStatusOdottaa>{t('odottaa')}</ChipForStatusOdottaa>
      ) : draft ? (
        <ChipForStatusSaved>
          {t('tiliStatus_TALLENNETTU')}&nbsp;
          {IsoDatetimeToUiDatetime(draft.updatedAt)}
          <TallennettuIconDiv>✔</TallennettuIconDiv>
        </ChipForStatusSaved>
      ) : (
        <ChipForStatusNotStarted>
          {t('tiliStatus_ALOITTAMATTA')}
        </ChipForStatusNotStarted>
      )}
    </section>
  )
})

export default TiliStatusChip

const LahetettyIconDiv = styled.span`
  padding-left: 10px;
`

const TallennettuIconDiv = styled.span`
  color: ${suomifiDesignTokens.colors.successBase};
  padding-left: 10px;
`

const ChipForStatusNotStarted = styled(StaticChip)`
  &&& {
    background-color: ${suomifiDesignTokens.colors.warningLight1};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`

const ChipForStatusSent = styled(StaticChip)`
  &&& {
    background: ${suomifiDesignTokens.colors.successSecondary};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`

const ChipForStatusSaved = styled(StaticChip)`
  &&& {
    background: ${suomifiDesignTokens.colors.highlightLight3};
    color: ${suomifiDesignTokens.colors.depthDark1};
    font-size: 14px;
  }
`

const ChipForStatusOdottaa = styled(StaticChip)`
  &&& {
    background: ${suomifiDesignTokens.colors.depthLight2};
    color: ${suomifiDesignTokens.colors.blackBase};
    font-size: 14px;
  }
`
