import keyMirror from 'keymirror'
import { addYears, format, startOfDay } from 'date-fns'
import { ViranomainenId } from 'asia-common/src/vtj/koodisto/viranomainen-enums'
import { switchCaseExhaustiveCheck } from 'asia-common/src/vtj/misc/typescript-utils'

export const PublicityClass = keyMirror({
  JULKINEN: null,
  SALAINEN: null,
  OSITTAIN_SALASSAPIDETTAVA: null,
})
export type PublicityClassId = (typeof PublicityClass)[keyof typeof PublicityClass]
export const allPublicityClassIds = Object.keys(PublicityClass) as PublicityClassId[]

export const SecurityClass = keyMirror({
  EI_TURVALLISUUSLUOKITELTU: null,
})
export type SecurityClassId = (typeof SecurityClass)[keyof typeof SecurityClass]
export const allSecurityClassIds = Object.keys(SecurityClass) as SecurityClassId[]

export const PersonalDataClass = keyMirror({
  EI_SISALLA_HENKILOTIETOJA: null,
  SISALTAA_HENKILOTIETOJA: null,
  SISALTAA_ERITYISIA_HENKILOTIETOJA: null,
})
export type PersonalDataClassId = (typeof PersonalDataClass)[keyof typeof PersonalDataClass]
export const allPersonalDataClassIds = Object.keys(PersonalDataClass) as PersonalDataClassId[]

export const SecurityPeriod = keyMirror({
  SALASSAPITOAIKA_25_VUOTTA: null,
  SALASSAPITOAIKA_50_VUOTTA: null,
  SALASSAPITOAIKA_100_VUOTTA: null,
})
export type SecurityPeriodId = (typeof SecurityPeriod)[keyof typeof SecurityPeriod]
export const allSecurityPeriodIds = Object.keys(SecurityPeriod) as SecurityPeriodId[]

export const SecurityPeriodInYears: { [key in SecurityPeriodId]: number } = {
  [SecurityPeriod.SALASSAPITOAIKA_25_VUOTTA]: 25,
  [SecurityPeriod.SALASSAPITOAIKA_50_VUOTTA]: 50,
  [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA]: 100,
}

export const SecurityReason = keyMirror({
  JULKL_621_1999_24_1_KOHTA_20: null,
  JULKL_621_1999_24_1_KOHTA_22: null,
  JULKL_621_1999_24_1_KOHTA_23: null,
  JULKL_621_1999_24_1_KOHTA_24: null,
  JULKL_621_1999_24_1_KOHTA_25: null,
  JULKL_621_1999_24_1_KOHTA_26: null,
  JULKL_621_1999_24_1_KOHTA_27: null,
  JULKL_621_1999_24_1_KOHTA_28: null,
  JULKL_621_1999_24_1_KOHTA_31: null,
  JULKL_621_1999_24_1_KOHTA_31_A: null,
  JULKL_621_1999_24_1_KOHTA_31_B: null,
  JULKL_621_1999_24_1_KOHTA_31_C: null,
  JULKL_621_1999_24_1_KOHTA_32: null,
})
export type SecurityReasonId = (typeof SecurityReason)[keyof typeof SecurityReason]
export const allSecurityReasonIds = Object.keys(SecurityReason) as SecurityReasonId[]

export const SecurityReasonLawDefs: {
  [key in SecurityReasonId]: { luku: string; pykala: string; kohta: string }
} = {
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_20]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '20' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_22]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '22' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_23]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '23' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_24]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '24' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_25]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '25' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_26]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '26' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_27]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '27' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_28]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '28' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_31]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '31' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_31_A]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '31 a' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_31_B]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '31 b ' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_31_C]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '31 c' },
  [SecurityReason.JULKL_621_1999_24_1_KOHTA_32]: { luku: '(621 / 1999)', pykala: '24.1', kohta: '32' },
}

export interface JulkinenMetadata {
  defaultPersonalDataClassId: PersonalDataClassId
  personalDataClassIds: PersonalDataClassId[]
}

export interface SalassaPidettavaMetadata {
  defaultPersonalDataClassId: PersonalDataClassId
  personalDataClassIds: PersonalDataClassId[]
  defaultSecurityPeriodId: SecurityPeriodId
  securityPeriodIds: SecurityPeriodId[]
  defaultSecurityReasonIds: SecurityReasonId[]
  securityReasonIds: SecurityReasonId[]
}

export interface PublicityClassMetadata {
  [PublicityClass.JULKINEN]?: JulkinenMetadata
  [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]?: SalassaPidettavaMetadata
  [PublicityClass.SALAINEN]?: SalassaPidettavaMetadata
}

export interface RestrictionMetadata {
  defaultPublicityClassId: PublicityClassId
  publicityClassIds: PublicityClassId[]
  publicityMetadata: PublicityClassMetadata
  defaultViranomainenId: ViranomainenId
  viranomainenIds: ViranomainenId[]
  defaultSecurityClassId: SecurityClassId
  securityClassIds: SecurityClassId[]
}

export interface SecurityDates {
  securityStartDate: string | null
  securityEndDate: string | null
}

export const getSecurityDates = (
  securityStartTimestamp: Date,
  securityPeriodId: SecurityPeriodId | null
): SecurityDates => {
  if (securityPeriodId !== null) {
    const start = startOfDay(securityStartTimestamp)
    const end = addYears(start, SecurityPeriodInYears[securityPeriodId])
    return {
      securityStartDate: format(start, 'yyyy-MM-dd'),
      securityEndDate: format(end, `yyyy-MM-dd`),
    }
  } else {
    return {
      securityStartDate: null,
      securityEndDate: null,
    }
  }
}

export interface PublicityMetadata {
  defaultPersonalDataClassId: PersonalDataClassId
  personalDataClassIds: PersonalDataClassId[]
  defaultSecurityPeriodId: SecurityPeriodId | null
  securityPeriodIds: SecurityPeriodId[]
  defaultSecurityReasonIds: SecurityReasonId[]
  securityReasonIds: SecurityReasonId[]
}

export const getPublicityMetadata = (
  meta: RestrictionMetadata,
  publicityClassIdOpt?: PublicityClassId
): PublicityMetadata => {
  const publicityClassId = publicityClassIdOpt ?? meta.defaultPublicityClassId
  switch (publicityClassId) {
    case PublicityClass.JULKINEN: {
      const data = meta.publicityMetadata[publicityClassId] as JulkinenMetadata
      return {
        defaultPersonalDataClassId: data.defaultPersonalDataClassId,
        personalDataClassIds: data.personalDataClassIds,
        defaultSecurityPeriodId: null,
        securityPeriodIds: [],
        defaultSecurityReasonIds: [],
        securityReasonIds: [],
      }
    }
    case PublicityClass.SALAINEN:
    case PublicityClass.OSITTAIN_SALASSAPIDETTAVA: {
      const data = meta.publicityMetadata[publicityClassId] as SalassaPidettavaMetadata
      return data
    }
    default:
      return switchCaseExhaustiveCheck(publicityClassId)
  }
}
