import { Paragraph, ParagraphProps } from 'suomifi-ui-components'

export const MultiParagraphText: React.FC<
  {
    text?: string
  } & Omit<ParagraphProps, 'children'>
> = ({ text, ...props }) => {
  if (!text) {
    return <Paragraph {...props}></Paragraph>
  } else {
    const paragraphs = text.split('\n')
    return (
      <>
        {paragraphs.map((p, i) => (
          <Paragraph
            key={p}
            {...{ ...(i + 1 < paragraphs.length && { mb: 's' }), ...props }}
          >
            {p}
          </Paragraph>
        ))}
      </>
    )
  }
}
