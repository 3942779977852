import React, { useEffect, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  Block,
  Button,
  IconPrint,
  Modal,
  ModalContent,
  ModalFooter,
  ModalTitle,
} from 'suomifi-ui-components'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import Perustiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/Perustiedot'
import Yhteenveto from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/Yhteenveto'
import { getWindowNonceId } from 'common/src/vtj/browser/browser-nonce'
import { useReactToPrint } from 'react-to-print'
import {
  initTilintarkastusStore,
  isTilintarkastusStoreInitialized,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { styled } from 'styled-components'
import { ASIOINTI_ROOT_ELEMENT_ID } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/constants'
import { TilintarkastusApiClient } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/api/tilintarkastus-api-client'
import { AsiointiSeurantaAsiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/edunvalvontasuhteet/edunvalvontasuhteet-api.type'
import PageLoading from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/PageLoading'

export const TulostaYhteenvetoModal: React.FC<{
  visible: boolean
  setVisible: (value: boolean) => void
  vaatimus: Pick<
    AsiointiSeurantaAsiavaatimus,
    'seurantaAsiavaatimusId' | 'asiaType'
  >
}> = observer(({ visible, setVisible, vaatimus }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = 'm'
  const printComponentRef = useRef<HTMLElement>(null)

  const [isLoading, setLoading] = useState(
    !isTilintarkastusStoreInitialized(vaatimus.seurantaAsiavaatimusId)
  )

  useEffect(() => {
    if (isLoading) {
      void (async () => {
        const response = await TilintarkastusApiClient.avaaTiliLomake({
          seurantaAsiavaatimusId: vaatimus.seurantaAsiavaatimusId,
        })
        if (response.isOk) {
          const props = response.data
          initTilintarkastusStore(props)
          setLoading(false)
        }
      })()
    }
  }, [isLoading, setLoading, vaatimus.seurantaAsiavaatimusId])

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    pageStyle:
      '@page { size: auto; margin: 20mm; } @media print { body { -webkit-print-color-adjust: exact; print-color-adjust: exact; } }',
    nonce: getWindowNonceId(),
  })

  const headingTranslationString = {
    OMAISUUSLUETTELO: 'omaisuusluetteloYhteenveto',
    PAATOSTILI: 'paatostiliYhteenveto',
    VUOSITILI: 'vuositiliYhteenveto',
  }

  return (
    <Modal
      visible={visible}
      appElementId={ASIOINTI_ROOT_ELEMENT_ID}
      variant={isTablet ? 'default' : 'smallScreen'}
      onEscKeyDown={() => setVisible(false)}
    >
      <ModalContent>
        <Block forwardedRef={printComponentRef}>
          <ModalTitle>
            {t(headingTranslationString[vaatimus.asiaType])}
          </ModalTitle>
          {isLoading && <PageLoading />}
          {!isLoading && (
            <>
              <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
              <Perustiedot showValtakirja />
              <DividerLine mb={verticalMarginToken} mt={verticalMarginToken} />
              <Yhteenveto />
            </>
          )}
        </Block>
      </ModalContent>
      <ModalFooter>
        <PrintButton onClick={handlePrint} icon={<IconPrint />}>
          {t('tulostaYhteenveto')}
        </PrintButton>
        <Button onClick={() => setVisible(false)} variant="secondary">
          {t('sulje')}
        </Button>
      </ModalFooter>
    </Modal>
  )
})

const PrintButton = styled(Button)`
  align-self: baseline;
`
