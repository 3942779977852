import {
  Block,
  Expander,
  ExpanderContent,
  ExpanderGroup,
  ExpanderTitleButton,
  ExternalLink,
  Heading,
  InlineAlert,
  Paragraph,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { FormProvider } from 'react-hook-form'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { mkAloitussivuTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useTranslation } from 'react-i18next'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { styled } from 'styled-components'
import {
  omaisuusluetteloOhjeAlaikaiselleUrl,
  omaisuusluetteloOhjeUrl,
  paatostiliOhjeAlaikaiselleUrl,
  paatostiliOhjeTaysikaiselleUrl,
  vuositiliOhjeAlaikaiselleUrl,
  vuositiliOhjeTaysikaiselleUrl,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import Perustiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/Perustiedot'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import { TilivelvollisuusType } from 'tilintarkastus-common/src/vtj/types/edunvalvontasuhde/edunvalvontasuhde-enums'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TilintarkastusForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'
import { OmaisuusluetteloOhje } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/common/OmaisuusluetteloOhje'
import { VuositiliAsiakirjatOhje } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/common/VuositiliAsiakirjatOhje'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { PaatostiliAsiakirjatOhje } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/common/PaatostiliAsiakirjatOhje'

const AloitusPage: React.FC<{ form: TilintarkastusForm }> = observer(
  ({ form }) => {
    const [t] = useTranslation()
    const userStore = useAsiointiUserStore()
    const {
      vaatimus: { isPrincipalUnderaged, tilivelvollisuusType, asiaType },
      lomake: { tarkistetutEsitaytetytTiedot },
    } = getTilintarkastusStore()

    const verticalMarginToken = 'xl'
    const onErrorRef = useRef<HTMLDivElement>(null)

    const lueLisaaExternalLink = (): string => {
      switch (asiaType) {
        case 'OMAISUUSLUETTELO':
          return isPrincipalUnderaged
            ? omaisuusluetteloOhjeAlaikaiselleUrl(userStore.lang)
            : omaisuusluetteloOhjeUrl(userStore.lang)
        case 'VUOSITILI':
          return isPrincipalUnderaged
            ? vuositiliOhjeAlaikaiselleUrl(userStore.lang)
            : vuositiliOhjeTaysikaiselleUrl(userStore.lang)
        default:
          return isPrincipalUnderaged
            ? paatostiliOhjeAlaikaiselleUrl(userStore.lang)
            : paatostiliOhjeTaysikaiselleUrl(userStore.lang)
      }
    }

    return (
      <>
        <FormProvider {...form}>
          <ResponsiveBorderedContentBlock
            data-test-id={mkAloitussivuTestId('general-info-container')}
          >
            <PageStepHeading
              heading={t('aloitus')}
              steps={TILINTARKASTUS_STEPS}
            />
            <FormErrorSummary ref={onErrorRef} />

            <MultiParagraphText text={t(translations[asiaType].info1)} />
            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
            <Perustiedot />

            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

            {asiaType === AsiointiType.OMAISUUSLUETTELO && (
              <OmaisuusluetteloAloitusOhje
                jaettu={
                  tilivelvollisuusType ===
                  TilivelvollisuusType.OMAISUUSLUETTELO_YHDESSA
                }
              />
            )}

            {asiaType === AsiointiType.VUOSITILI && (
              <VuositiliAloitusOhje
                jaettu={
                  tilivelvollisuusType ===
                  TilivelvollisuusType.TILIVELVOLLISUUS_YHDESSA
                }
              />
            )}

            {asiaType === AsiointiType.PAATOSTILI && (
              <PaatostiliAloitusOhje
                jaettu={
                  tilivelvollisuusType ===
                  TilivelvollisuusType.TILIVELVOLLISUUS_YHDESSA
                }
              />
            )}

            <Block mt={verticalMarginToken} />
            <ExternalLink
              data-test-id={mkAloitussivuTestId('ohje-link')}
              variant="accent"
              toNewWindow
              labelNewWindow={t('avautuuUuteenValilehteen')}
              href={lueLisaaExternalLink()}
            >
              {t(translations[asiaType].lueLisaa)}
            </ExternalLink>

            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

            {Object.keys(tarkistetutEsitaytetytTiedot).length > 0 && (
              <InlineAlert labelText={t('esitaytettyTili')} mb="s">
                {t('esitaytettyTiliAloitusInfo')}
              </InlineAlert>
            )}
            <FormNavigationBar onErrorRef={onErrorRef} />
          </ResponsiveBorderedContentBlock>
        </FormProvider>
      </>
    )
  }
)

export default AloitusPage

const OmaisuusluetteloAloitusOhje: React.FC<{ jaettu: boolean }> = ({
  jaettu,
}) => {
  const [t] = useTranslation()
  return (
    <>
      <Heading variant="h3" mb="xs">
        {t('omaisuusluetteloTayttoohjeOtsikko')}
      </Heading>
      <MultiParagraphText text={t('omaisuusluetteloTayttoohje')} mb="s" />
      <ExpanderGroup>
        <Expander>
          <ExpanderTitleButton>
            {t('mitaTarvitsetOmaisuusluetteloon')}
          </ExpanderTitleButton>
          <ExpanderContent>
            <OmaisuusluetteloOhje />
          </ExpanderContent>
        </Expander>

        {jaettu && (
          <Expander>
            <ExpanderTitleButton>
              {t('nainTeetKunAsioitaHoitaaUseampiHenkiloOtsikko')}
            </ExpanderTitleButton>
            <ExpanderContent>
              <Block
                data-test-id={mkAloitussivuTestId(
                  'tilivelvollisuus-yhdessa-ohje'
                )}
              >
                <Paragraph mb="s">
                  <Text>
                    {t('nainTeetKunAsioitaHoitaaUseampiHenkiloOhje1')}
                  </Text>
                </Paragraph>
                <OhjeList>
                  <li>
                    {t('nainTeetKunAsioitaHoitaaUseampiHenkiloOhjeLista1')}
                  </li>
                  <li>
                    {t('nainTeetKunAsioitaHoitaaUseampiHenkiloOhjeLista2')}
                  </li>
                  <li>
                    {t('nainTeetKunAsioitaHoitaaUseampiHenkiloOhjeLista3')}
                  </li>
                  <li>
                    {`${t(
                      'nainTeetKunAsioitaHoitaaUseampiHenkiloOhjeLista4'
                    )} `}
                    <Text variant={'bold'}>{`${t('valtakirja')}.`}</Text>
                  </li>
                  <li>{t('valtakirjanSisaltoOhje')}</li>
                </OhjeList>
              </Block>
            </ExpanderContent>
          </Expander>
        )}
      </ExpanderGroup>
    </>
  )
}

const VuositiliAloitusOhje: React.FC<{ jaettu: boolean }> = ({ jaettu }) => {
  const [t] = useTranslation()
  return (
    <>
      <Heading variant="h3" mb="xs">
        {t('vuositiliTayttoohjeOtsikko')}
      </Heading>
      <MultiParagraphText text={t('vuositiliTayttoohje')} mb="s" />
      <ExpanderGroup>
        <Expander>
          <ExpanderTitleButton>
            {t('mitaTarvitsetVuositiliinOtsikko')}
          </ExpanderTitleButton>
          <ExpanderContent>
            <Paragraph>{t('mitaTarvitsenVuositilinTekoon-kuvaus')}</Paragraph>
            <VuositiliAsiakirjatOhje />
          </ExpanderContent>
        </Expander>
        {jaettu && (
          <Expander>
            <ExpanderTitleButton>
              {t('jaettuEdunvalvontaVuositiliExpanderHeader')}
            </ExpanderTitleButton>
            <ExpanderContent>
              <Block
                data-test-id={mkAloitussivuTestId(
                  'tilivelvollisuus-yhdessa-ohje'
                )}
              >
                <Paragraph mb="s">{t('josJaettuEdunvalvontaOhje')}</Paragraph>
                <OhjeList>
                  <li>{t('soviKuvaLaatiiVuositilin')}</li>
                  <li>{t('taytaVuositilinTiedot')}</li>
                  <li>{t('varmistaEttaMuutOvatTietoisiaVuositilista')}</li>
                  <li>
                    {`${t('pyydaVuositiliValtakirja')} `}
                    <Text variant={'bold'}>{`${t('valtakirja')}.`}</Text>
                  </li>
                  <li>{t('valtakirjanSisaltoOhje')}</li>
                </OhjeList>
              </Block>
            </ExpanderContent>
          </Expander>
        )}
      </ExpanderGroup>
    </>
  )
}

const PaatostiliAloitusOhje: React.FC<{ jaettu: boolean }> = ({ jaettu }) => {
  const [t] = useTranslation()
  return (
    <>
      <Heading variant="h3" mb="xs">
        {t('paatostilinTayttoohjeOtsikko')}
      </Heading>
      <MultiParagraphText text={t('paatostilinTayttoohje')} mb="s" />
      <ExpanderGroup mt="s">
        <Expander>
          <ExpanderTitleButton>
            {t('mitaTarvitsetPaatostiliinOtsikko')}
          </ExpanderTitleButton>
          <ExpanderContent>
            <Paragraph>{t('mitaTarvitsenPaatostilinTekoon-kuvaus')}</Paragraph>
            <PaatostiliAsiakirjatOhje />
          </ExpanderContent>
        </Expander>
        {jaettu && (
          <Expander>
            <ExpanderTitleButton>
              {t('jaettuEdunvalvontaPaatostiliExpanderHeader')}
            </ExpanderTitleButton>
            <ExpanderContent>
              <Block
                data-test-id={mkAloitussivuTestId(
                  'tilivelvollisuus-yhdessa-ohje'
                )}
              >
                <Paragraph mb="s">{t('jaettuPaatostiliOhje')}</Paragraph>
                <OhjeList>
                  <li>{t('soviKuvaLaatiiPaatostilin')}</li>
                  <li>{t('taytaPaatostilinTiedot')}</li>
                  <li>{t('varmistaEttaMuutOvatTietoisiaPaatostilista')}</li>
                  <li>
                    {`${t('pyydaPaatostiliValtakirja')} `}
                    <Text variant={'bold'}>{`${t('valtakirja')}.`}</Text>
                  </li>
                  <li>{t('valtakirjanSisaltoOhje')}</li>
                </OhjeList>
              </Block>
            </ExpanderContent>
          </Expander>
        )}
      </ExpanderGroup>
    </>
  )
}

const translations = {
  OMAISUUSLUETTELO: {
    info1: 'omaisuusluetteloAloitusInfo',
    lueLisaa: 'lueLisaaOmaisuusluettelosta',
  },
  PAATOSTILI: {
    info1: 'paatostiliJuurisivuOhje',
    lueLisaa: 'lueLisaaPaatostilista',
  },
  VUOSITILI: {
    info1: 'vuositiliAloitusInfo',
    lueLisaa: 'lueLisaaVuositilista',
  },
}

const OhjeList = styled.ul`
  list-style: disc outside;
  margin: 0 0 0 ${suomifiDesignTokens.spacing.l};
`
