import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'

const MuutMerkittavatToimet: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    lomake: { tili, lomakkeelleValitutTiedot },
    vaatimus,
  } = getTilintarkastusStore()
  return (
    <section
      data-test-id={mkYhteenvetoTestId('muut-merkittavat-toimet-container')}
    >
      <Heading variant="h3">{t('muutMerkittavatToimet')}</Heading>
      <Heading variant="h5" as="h4" mt="xs">
        {vaatimus.asiaType === AsiointiType.OMAISUUSLUETTELO
          ? t('onkoTulossaMuitaMerkittaviaToimia')
          : t('onkoMuitaMerkittaviaToimia')}
      </Heading>
      <Paragraph>
        {lomakkeelleValitutTiedot.muutMerkittavatToimet ? t('kylla') : t('ei')}
      </Paragraph>

      {lomakkeelleValitutTiedot.muutMerkittavatToimet && (
        <Block mt="xs">
          <Heading variant="h5" as="h4">
            {vaatimus.asiaType === AsiointiType.OMAISUUSLUETTELO
              ? t('mitaToimiaOnTulossa')
              : t('mitaMuitaMerkittaviaToimia')}
          </Heading>
          <MultiParagraphText text={tili.muutMerkittavatToimet} />
        </Block>
      )}
    </section>
  )
})

export default MuutMerkittavatToimet
