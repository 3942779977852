import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { FrontAsiakirjaLiite } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'

export const EiIlmoitettujaTietoja: React.FC = () => {
  const [t] = useTranslation()
  return (
    <Paragraph>
      {isOmaisuusluettelo()
        ? t('eiIlmoitettujaTietojaTehtavasiAlkamispaivana')
        : t('eiIlmoitettujaTietojaTallaTilikaudella')}
    </Paragraph>
  )
}

export const YhteenvetoTilirivi: React.FC<{
  heading: string
  content: (string | undefined)[]
  attachments?: FrontAsiakirjaLiite[]
}> = observer(({ heading, content, attachments }) => {
  return content.length ? (
    <Block mb="xs">
      <Heading variant="h5" mt="xs">
        {heading}
      </Heading>
      {content.map(
        (item, index) => item && <Paragraph key={index}>{item}</Paragraph>
      )}
      {attachments && <AttachmentList attachments={attachments} />}
    </Block>
  ) : null
})
