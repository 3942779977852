import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const MuutVarat: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    lomake: {
      lomakkeelleValitutTiedot,
      tili: {
        paamiehenOmaisuus: { muutVarat },
      },
    },
  } = getTilintarkastusStore()
  return (
    <>
      <Heading variant="h3">{t('muutOmassaKaytossaOlevatVarat')}</Heading>
      <Heading variant="h5" as="h4" mt="xs">
        {t('saakoHenkiloOmaanKayttoonsaMuitaVaroja')}
      </Heading>
      <Paragraph>
        {lomakkeelleValitutTiedot.muutOmassaKaytossaOlevatVarat
          ? t('kylla')
          : t('ei')}
      </Paragraph>

      {lomakkeelleValitutTiedot.muutOmassaKaytossaOlevatVarat && (
        <Block mt="xs">
          <Heading variant="h5" as="h4">
            {t('mitaMuitaVarojaHenkiloSaaOmaanKayttoon')}
          </Heading>
          <MultiParagraphText text={muutVarat} />
        </Block>
      )}
    </>
  )
})

export default MuutVarat
