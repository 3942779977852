import { Retention, RetentionMetadata } from 'asia-common/src/vtj/koodisto/retention-enums'
import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { AsiakirjaMetadata } from 'asia-common/src/vtj/koodisto/asiakirja-metadata'

// asias and asiakirjas
export const vanhemmuusPysyvaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.PYSYVA,
  retentionIds: [Retention.PYSYVA],
}

// asias are osittain salassa pidettava and asiakirjas are salassa pidettava
export const vanhemmuusAsiaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
  publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
  publicityMetadata: {
    [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const vanhemmuusAsiakirjaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.SALAINEN,
  publicityClassIds: [PublicityClass.SALAINEN],
  publicityMetadata: {
    [PublicityClass.SALAINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_ERITYISIA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

// Asiakirjatypes for all vanhemmuus asia types are defined currently in same TOS-document
export const vanhemmuusAsiakirjaMetadata: AsiakirjaMetadata[] = [
  {
    asiakirjaTypeId: AsiakirjaType.ASIAN_SIIRTO_LASTENVALVOJALLE,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    originTypeIds: [AsiakirjaOriginType.LAADITTU],
    toivoAsiakirjaTypeIds: ['b18c6dd1-6496-4e3c-bd6b-4a34d2c07ef7'],
    defaultToivoAsiakirjaTypeId: 'b18c6dd1-6496-4e3c-bd6b-4a34d2c07ef7',
  },
  {
    asiakirjaTypeId: AsiakirjaType.HOITOTODISTUS,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    toivoAsiakirjaTypeIds: ['5fbd61d5-2bd9-40b9-b83b-2f5b24073054'],
    defaultToivoAsiakirjaTypeId: '5fbd61d5-2bd9-40b9-b83b-2f5b24073054',
  },
  {
    asiakirjaTypeId: AsiakirjaType.TAYDENNYS,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    toivoAsiakirjaTypeIds: ['816c3b57-d8d5-41f3-9661-d1baf5b9b9ab'],
    defaultToivoAsiakirjaTypeId: '816c3b57-d8d5-41f3-9661-d1baf5b9b9ab',
  },
  {
    asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    originTypeIds: [AsiakirjaOriginType.LAADITTU],
    toivoAsiakirjaTypeIds: ['9f989997-1a1d-4cd2-92a9-28e0ee608b50'],
    defaultToivoAsiakirjaTypeId: '9f989997-1a1d-4cd2-92a9-28e0ee608b50',
  },
  {
    asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    toivoAsiakirjaTypeIds: ['1cc59614-eeb1-40d4-b78b-8764dffb7084'],
    defaultToivoAsiakirjaTypeId: '1cc59614-eeb1-40d4-b78b-8764dffb7084',
  },
  {
    asiakirjaTypeId: AsiakirjaType.PAATOS,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
    originTypeIds: [AsiakirjaOriginType.LAADITTU],
    toivoAsiakirjaTypeIds: ['1ea4519e-50fc-4015-b60d-5e9ee168c4fe'],
    defaultToivoAsiakirjaTypeId: '1ea4519e-50fc-4015-b60d-5e9ee168c4fe',
  },
  {
    asiakirjaTypeId: AsiakirjaType.SAANTITODISTUS,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    toivoAsiakirjaTypeIds: ['5936361f-117f-464f-9bf7-50c5e886891c'],
    defaultToivoAsiakirjaTypeId: '5936361f-117f-464f-9bf7-50c5e886891c',
  },
  {
    asiakirjaTypeId: AsiakirjaType.SIVIILISAATYTODISTUS,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    toivoAsiakirjaTypeIds: ['b3b5d200-9b5a-4a1c-87f1-8467ab91059c'],
    defaultToivoAsiakirjaTypeId: 'b3b5d200-9b5a-4a1c-87f1-8467ab91059c',
  },
  {
    asiakirjaTypeId: AsiakirjaType.SYNTYMATODISTUS,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    toivoAsiakirjaTypeIds: ['4e10adaa-a907-4aca-a247-1a6a5fbe1589'],
    defaultToivoAsiakirjaTypeId: '4e10adaa-a907-4aca-a247-1a6a5fbe1589',
  },
  {
    asiakirjaTypeId: AsiakirjaType.VANHEMMUUDEN_TUNNUSTAMISASIAKIRJA_SAHKOINEN,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    toivoAsiakirjaTypeIds: ['dacba2eb-570d-4726-a8b8-33203e8d3f3c'],
    defaultToivoAsiakirjaTypeId: 'dacba2eb-570d-4726-a8b8-33203e8d3f3c',
  },
  {
    asiakirjaTypeId: AsiakirjaType.VANHEMMUUDEN_TUNNUSTAMISASIAKIRJA_FYYSINEN,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    toivoAsiakirjaTypeIds: ['a62f565a-cc0c-4ed5-a661-a7e36be235bb'],
    defaultToivoAsiakirjaTypeId: 'a62f565a-cc0c-4ed5-a661-a7e36be235bb',
  },
  {
    asiakirjaTypeId: AsiakirjaType.VANHEMMUUDEN_TUNNUSTAMISEN_KUULEMISASIAKIRJA,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    toivoAsiakirjaTypeIds: ['a25c0e79-bf29-49cf-a190-c44a5023d8ec'],
    defaultToivoAsiakirjaTypeId: 'a25c0e79-bf29-49cf-a190-c44a5023d8ec',
  },
  {
    asiakirjaTypeId: AsiakirjaType.VANHEMMUUDEN_TUNNUSTAMISEN_KIISTAMINEN_TAI_PERUUTUS,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    toivoAsiakirjaTypeIds: ['3e6e86af-22ae-4c50-b076-89b4857adbf0'],
    defaultToivoAsiakirjaTypeId: '3e6e86af-22ae-4c50-b076-89b4857adbf0',
  },
  {
    asiakirjaTypeId: AsiakirjaType.VANHEMMUUDEN_SELVITTAMISPOYTAKIRJA_LIITTEINEEN,
    restrictionMetadata: vanhemmuusAsiakirjaRestriction,
    retentionMetadata: vanhemmuusPysyvaRetention,
    defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
    originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
    toivoAsiakirjaTypeIds: ['4c88cd5e-9340-4ca0-b62b-aea1c22ffcc5'],
    defaultToivoAsiakirjaTypeId: '4c88cd5e-9340-4ca0-b62b-aea1c22ffcc5',
  },
]
