import keyMirror from 'keymirror'
import _ from 'lodash'

export const SpecifierType = keyMirror({
  ASUMISOIKEUDEN_HANKKIMINEN: null,
  ASUMISOIKEUDEN_VUOKRAAMINEN: null,
  ASUNNON_HANKKIMINEN: null,
  ASUNNON_VUOKRAAMINEN: null,
  AVIOERO_OSITUS_TAI_EROTTELU: null,
  ELINKEINON_HARJOITTAMINEN: null,
  KIINTEISTON_HANKKIMINEN: null,
  KIINTEISTON_VUOKRAAMINEN: null,
  LAINAN_ANTAMINEN: null,
  LAINAN_OTTAMINEN: null,
  METSAN_TAI_MAA_AINEKSEN_MYYNTI: null,
  PANTTAUS: null,
  PERINNOSTA_LUOPUMINEN: null,
  PERINTOOSUUDEN_LUOVUTUS: null,
  SIJOITUSKOHTEIDEN_OSTAMINEN: null,
  SOPIMUS_KUOLINPESAN_YHTEISHALLINNOSTA: null,
  TAKAUS: null,
  YHTIOMIEHEKSI_RYHTYMINEN: null,
  TOISSIJAINEN_VALTUUTETTU: null,
  AVIOERO: null,
  AVIOLIITTO: null,
  REKISTEROIDUN_PARISUHTEEN_PURKAMINEN: null,
  REKISTEROIDUN_PARISUHTEEN_ILMOITTAMINEN: null,
  A_TAI_P_OLESKELULUPA: null,
  EU_REKISTEROINTI_ALLE_12_KK_SITTEN: null,
  EU_REKISTEROINTI_YLI_12_KK_SITTEN: null,
  TILAPAISEN_SUOJELUN_OLESKELULUPA: null,
  VIRANOMAISALOITTEINEN: null,
  VALTUUTETTU_KUOLLUT: null,
  VALTUUTETUN_LUOPUMINEN: null,
  B_OLESKELULUPA_TAI_PERHESUHDEPERUSTE: null,
  POHJOISMAAT_TAI_VIRO: null,
  KUOLEMA: null,
  KUOLLEEKSI_JULISTAMINEN: null,
  EIDAS: null,
  HETU_NORMAALI_KASITTELY: null,
  HETU_NOPEUTETTU_KASITTELY: null,
  KV_ASIA: null,
  APOSTILLE: null,
  KUOLINTIEDON_KORJAUS: null,
  KUOLLEEKSIJULISTAMISTIEDON_KORJAUS: null,
  VAKINAINEN_MUUTTO: null,
  TILAPAINEN_MUUTTO: null,
  PUOLESTA_ILMOITETTU_MUUTTO: null,
  POSTIOSOITTEEN_MUUTOS: null,
  HENKILOTUNNUKSEN_ANTAMINEN: null,
  LAPSEN_NIMI_AIDINKIELI_JA_KANSALAISUUS: null,
  KANSALAISUUDET: null,
  ULKOMAINEN_SYNTYMAPAIKKA: null,
  EI_LUVANVARAINEN_LUPA_ASIA: null,
  EU_KAANNOSLOMAKE: null,
  TILI_SAAPUMATTA: null,
  TILIKAUSIEN_YHDISTAMINEN: null,
  VIHREA: null,
  KELTAINEN: null,
  ORANSSI: null,
  RUOTSINKIELINEN: null,
  MAKSETTU_TILAUKSEN_YHTEYDESSA: null,
  EDUNVALVONNAN_LAKKAUTTAMINEN: null,
  EDUNVALVOJAN_VAIHTAMINEN: null,
  EDUNVALVOJAN_SIJAISEN_MAARAAMINEN: null,
  EDUNVALVOJAN_JA_SIJAISEN_MAARAAMINEN: null,
  EDUNVALVONNAN_JATKAMINEN: null,
  EDUNVALVONNAN_MUUTTAMINEN: null,
  EDUNVALVONTAVALTUUTUKSEN_PERUUTTAMISEN_VAHVISTAMINEN: null,
  EDUNVALVONTAVALTUUTUKSEN_VAHVISTAMINEN_VARAVALTUUTETUN_OSALTA: null,
  EDUNVALVOJAN_MAARAAMINEN_VALTUUTETUN_TEHTAVIIN: null,
  ALAIKAISEN_EDUNVALVONNAN_REKISTEROINTI: null,
  ALAIKAISEN_EDUNVALVONNAN_REKISTERISTA_POISTAMINEN: null,
  EDUNVALVONNAN_SIIRROT_AHVENANMAA: null,
  EDUNVALVONNAN_SIIRROT_POHJOISMAAT: null,
  VALVONNAN_LOPETTAMINEN: null,
  TILIN_TAI_OMAISUUSLUETTELON_MAARAAJAN_PIDENTAMINEN: null,
  MUUTOS_TILIVELVOLLISUUTEEN: null,
  OMAISUUDENHOITOSUUNNITELMAN_HYVAKSYMINEN: null,
  VAROJEN_TALLETTAMINEN_ESTOTILILLE: null,
  VAROJEN_NOSTO_ESTOTILILTA: null,
  EDUNVALVOJIEN_VALISEN_ERIMIELISYYDEN_RATKAISEMINEN: null,
  REKISTERIVIRHEEN_KORJAAMINEN: null,
  ASIA_TAI_KIRJOITUSVIRHEEN_KORJAUS_OIKAISUVAATIMUS_MUUTOKSENHAKU: null,
  TIEDON_TAI_ASIAKIRJAN_LUOVUTTAMINEN: null,
  LAUSUMAPYYNTO_TUOMIOISTUIMELTA: null,
  KANTELU_EDUNVALVOJAN_TOIMINNASTA: null,
  KANTELU_TAI_MUU_YHTEYDENOTTO_EDUNVALVOJAN_TOIMINNASTA: null,
  PAKKOKEINOASIA: null,
  MAKSUA_KOSKEVA_OIKAISUVAATIMUS: null,
  KANSAINVALINEN_REKISTEROINTIASIA: null,
  KANSAINVALINEN_ASIA: null,
  KARHUN_JALKEINEN_ASIAKASYHTEYDENOTTO: null,
  EDUNVALVOJA_KUOLLUT: null,
  NELIVUOTISSELVITYS: null,
  UO_VIRANOMAISEN_ILMOITUS_ALAIKAISESTA_VELALLISESTA: null,
  ALAIKAISEN_VAROJEN_ILMOITTAMINEN: null,
  REKISTEROIDYN_ALAIKAISEN_HUOLTAJIEN_MUUTOS: null,
  LAAKARINLAUSUNTO_OIKEUSTOIMIKELPOISUUDESTA: null,
  VAATIVUUS_RISKIALTIS: null,
  VAATIVUUS_TYOLAS: null,
  VAATIVUUS_NORMAALI: null,
})
export type SpecifierTypeId = (typeof SpecifierType)[keyof typeof SpecifierType]
export const allSpecifierTypeIds = _.keys(SpecifierType) as SpecifierTypeId[]

export type SpecifierMetadata = {
  multipleSpecifiers: boolean
  mandatorySpecifier: boolean
  specifierTypeIds: SpecifierTypeId[]
}

export const prioritizedSpecifiers: SpecifierTypeId[] = [
  SpecifierType.APOSTILLE,
  SpecifierType.EIDAS,
  SpecifierType.KV_ASIA,
]
