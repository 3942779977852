import { styled, css } from 'styled-components'
import { Button, suomifiDesignTokens } from 'suomifi-ui-components'

export const HeaderButton = styled(Button).attrs(() => ({
  variant: 'secondary',
}))`
  height: 46px;

  && {
    ${suomifiDesignTokens.typography.bodySemiBoldSmall}
    color: ${suomifiDesignTokens.colors.whiteBase};
    border-color: ${suomifiDesignTokens.colors.whiteBase};
    background-color: ${suomifiDesignTokens.colors.brandBase};

    &:hover {
      text-decoration: underline;
      background: ${suomifiDesignTokens.colors.brandBase};
    }

    &:active {
      background: ${suomifiDesignTokens.colors.highlightDark1};
    }
  }
`

export const headerLinkStyle = css`
  &.fi-link {
    color: ${suomifiDesignTokens.colors.whiteBase};

    &:hover,
    &:visited {
      color: ${suomifiDesignTokens.colors.whiteBase};
    }
  }
`
