import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Heading } from 'suomifi-ui-components'
import {
  EiIlmoitettujaTietoja,
  YhteenvetoTilirivi,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import {
  countTulot,
  getIncomeForAccountingPeriod,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'

const Tulot: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const {
    lomake: {
      lomakkeelleValitutTiedot,
      tili: {
        tilirivit: { tulot },
      },
    },
  } = getTilintarkastusStore()
  return (
    <section data-test-id={mkYhteenvetoTestId('tulot-container')}>
      <Heading variant="h3">{t('tulotHeading')}</Heading>
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('elaketulotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.elakkeet ? (
        tulot.elakkeet.map((el, i) => (
          <YhteenvetoTilirivi
            heading={t(`incomeType_${el.tyyppi}`)}
            content={[el.maksaja, getIncomeForAccountingPeriod(t, el.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('palkkatulotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.palkkatulot ? (
        tulot.palkat.map((pal, i) => (
          <YhteenvetoTilirivi
            heading={t(`incomeType_${pal.tyyppi}`)}
            content={[
              pal.maksaja,
              getIncomeForAccountingPeriod(t, pal.yhteensa),
            ]}
            attachments={pal.suostumus}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('etuustulotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.etuudet ? (
        tulot.etuudet.map((et, i) => (
          <YhteenvetoTilirivi
            heading={t(`incomeType_${et.tyyppi}`)}
            content={[et.maksaja, getIncomeForAccountingPeriod(t, et.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('tyottomyysturvatulotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.tyottomyysturva ? (
        tulot.tyottomyysturva.map((tt, i) => (
          <YhteenvetoTilirivi
            heading={t(`incomeType_${tt.tyyppi}`)}
            content={[tt.maksaja, getIncomeForAccountingPeriod(t, tt.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('vuokratulotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.vuokratulot ? (
        tulot.vuokratulot.map((vt, i) => (
          <YhteenvetoTilirivi
            heading={`${vt.nimi}, ${vt.tunnus}`}
            content={[getIncomeForAccountingPeriod(t, vt.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('osinkotulotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.osinkotulot ? (
        tulot.osinkotulot.map((ot, i) => (
          <YhteenvetoTilirivi
            heading={ot.maksaja}
            content={[getIncomeForAccountingPeriod(t, ot.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('korkotulotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.korkotulot ? (
        tulot.korkotulot.map((kt, i) => (
          <YhteenvetoTilirivi
            heading={kt.maksaja}
            content={[getIncomeForAccountingPeriod(t, kt.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('myyntitulotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.myyntitulot ? (
        tulot.myyntitulot.map((mt, i) => (
          <YhteenvetoTilirivi
            heading={t(`incomeType_${mt.tyyppi}`)}
            content={[getIncomeForAccountingPeriod(t, mt.yhteensa)]}
            attachments={mt.tositteet}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('muutTulotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.muutTulot ? (
        tulot.muutTulot.map((mt, i) => (
          <YhteenvetoTilirivi
            heading={t(`incomeType_${mt.tyyppi}`)}
            content={[mt.maksaja, getIncomeForAccountingPeriod(t, mt.yhteensa)]}
            attachments={mt.tositteet}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {`${t('tulotyhteensa')}: ${formatNumberWithComma(countTulot())} €`}
      </Heading>
    </section>
  )
})

export default Tulot
