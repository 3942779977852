import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import {
  ulkomaalaisenRekisterointi4VuottaRetention,
  ulkomaalaisenRekisterointi50VuottaRetention,
  ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction,
  ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction,
  ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta32HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/ulkomaalaisen-rekisterointi/common-ulkomaalaisen-rekisterointi-metadata'
import { defaultAsiakirjaMarkingMetadata } from 'asia-common/src/vtj/koodisto/marking-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'

export const kansalaisuusJaUlkomaatiedotMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.KANSALAISUUDEN_TAI_ULKOMAATIEDON_REKISTEROINTI,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointi50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['5de6d2ca-7c72-4bc8-b739-7cb70df10bc7'],
      defaultToivoAsiakirjaTypeId: '5de6d2ca-7c72-4bc8-b739-7cb70df10bc7',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.KOPIO_HENKILOLLISYYSASIAKIRJASTA,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointi50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['a861d785-4882-4747-a973-7249998a092e'],
      defaultToivoAsiakirjaTypeId: 'a861d785-4882-4747-a973-7249998a092e',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUSTEET_REKISTEROINNILLE,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointi50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['114c66be-deb6-4791-b45a-42ff175304cf'],
      defaultToivoAsiakirjaTypeId: '114c66be-deb6-4791-b45a-42ff175304cf',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointi50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c98331ea-5aee-40da-bd56-ad8a3b14c704'],
      defaultToivoAsiakirjaTypeId: 'c98331ea-5aee-40da-bd56-ad8a3b14c704',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.KANSALAISUUDEN_MAARITTAMISPYYNTO_MIGRILLE,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointi50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['e59a298f-b4f2-4703-8fbe-8ce911d46d40'],
      defaultToivoAsiakirjaTypeId: 'e59a298f-b4f2-4703-8fbe-8ce911d46d40',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta32HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointi50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['8f7488e7-15fb-4547-92ef-f46d4added82'],
      defaultToivoAsiakirjaTypeId: '8f7488e7-15fb-4547-92ef-f46d4added82',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_REKISTEROINNISTA,
      restrictionMetadata:
        ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointi4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['87981ee5-79a4-4bed-bfc4-8cd4fd348809'],
      defaultToivoAsiakirjaTypeId: '87981ee5-79a4-4bed-bfc4-8cd4fd348809',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS_LIITTEINEEN,
      restrictionMetadata: ulkomaalaisenRekisterointSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointi50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['9e1ded30-62ef-47a1-b9de-304c06bc2995'],
      defaultToivoAsiakirjaTypeId: '9e1ded30-62ef-47a1-b9de-304c06bc2995',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OIKAISUVAATIMUSOHJE,
      restrictionMetadata:
        ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction,
      retentionMetadata: ulkomaalaisenRekisterointi50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['b1575857-1644-4304-9875-6f389e69e4ff'],
      defaultToivoAsiakirjaTypeId: 'b1575857-1644-4304-9875-6f389e69e4ff',
    },
  ],
  huomioMetadata: [
    // FIXME remove: https://jira.dvv.fi/browse/OIVA-5519
    {
      huomioTypeId: HuomioType.ODOTTAA_TUNNISTAUTUMISTA,
      postDueDateHuomioTypeId: HuomioType.TUNNISTAUTUMISEN_MAARAPAIVA_YLITTYNYT,
    },
    // FIXME remove: https://jira.dvv.fi/browse/OIVA-5519
    {
      huomioTypeId: HuomioType.TUNNISTAUTUMISEN_MAARAPAIVA_YLITTYNYT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_KUULEMISTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_LAUSUNTOA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.LAUSUNNON_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.LAUSUNNON_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_SELVITYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.VAALIT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.MUU_TALLENNUS,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.SAHKOISEN_ASIAKIRJAN_VARMENNUS,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.PAKOLLISIA_ASIAKIRJOJA_PUUTTUU,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.ILMOITTAJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.REKISTERIIN_MERKINTA,
    manualRatkaisuTypeIds: [
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.OSITTAIN_KIELTEINEN,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.TUTKIMATTAJATTAMISPAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.AIHEETON_VIENTI,
      RatkaisuType.ASIAN_SIIRTO,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: false,
    mandatorySpecifier: false,
    specifierTypeIds: [SpecifierType.KANSALAISUUDET, SpecifierType.ULKOMAINEN_SYNTYMAPAIKKA],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.LYKKAYS,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
    {
      toimenpideTypeId: ToimenpideType.TUNNISTAUTUMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.PAATOS_LIITTEINEEN],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
    },
    {
      toimenpideTypeId: ToimenpideType.KUULEMINEN,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
    },
    {
      toimenpideTypeId: ToimenpideType.SELVITYSPYYNTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
    },
    {
      toimenpideTypeId: ToimenpideType.LAUSUNTOPYYNTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYSASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELOMAKE_KANSALAINEN, Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata:
    ulkomaalaisenRekisterointOsittainSalassaPidettava100VuottaKohta24Kohta32HenkilotietojaRestriction,
  retentionMetadata: ulkomaalaisenRekisterointi50VuottaRetention,
  emailMetadata: {
    fromAddress: 'international@dvv.fi',
    useUserEmailIfPossible: true,
  },
}
