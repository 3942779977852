import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  AsiaHandlerRole,
  AsiaPersonRole,
  AsiaPersonType,
  AsiaStatus,
  RatkaisuType,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { Retention, RetentionMetadata } from 'asia-common/src/vtj/koodisto/retention-enums'
import {
  PersonalDataClass,
  PublicityClass,
  RestrictionMetadata,
  SecurityClass,
  SecurityPeriod,
  SecurityReason,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import { Viranomainen } from 'asia-common/src/vtj/koodisto/viranomainen-enums'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'

const vihkimistodistustilaus5VuottaRetention: RetentionMetadata = {
  defaultRetentionId: Retention.SAILYTYSAIKA_5_VUOTTA,
  retentionIds: [Retention.SAILYTYSAIKA_5_VUOTTA],
}

const vihkimistodistustilausOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.OSITTAIN_SALASSAPIDETTAVA,
  publicityClassIds: [PublicityClass.OSITTAIN_SALASSAPIDETTAVA],
  publicityMetadata: {
    [PublicityClass.OSITTAIN_SALASSAPIDETTAVA]: {
      defaultPersonalDataClassId: PersonalDataClass.SISALTAA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.SISALTAA_HENKILOTIETOJA],
      defaultSecurityPeriodId: SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA,
      securityPeriodIds: [SecurityPeriod.SALASSAPITOAIKA_100_VUOTTA],
      defaultSecurityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
      securityReasonIds: [SecurityReason.JULKL_621_1999_24_1_KOHTA_32],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

const vihkimistodistustilausJulkinenEiHenkilotietojaRestriction: RestrictionMetadata = {
  defaultPublicityClassId: PublicityClass.JULKINEN,
  publicityClassIds: [PublicityClass.JULKINEN],
  publicityMetadata: {
    [PublicityClass.JULKINEN]: {
      defaultPersonalDataClassId: PersonalDataClass.EI_SISALLA_HENKILOTIETOJA,
      personalDataClassIds: [PersonalDataClass.EI_SISALLA_HENKILOTIETOJA],
    },
  },
  defaultSecurityClassId: SecurityClass.EI_TURVALLISUUSLUOKITELTU,
  securityClassIds: [SecurityClass.EI_TURVALLISUUSLUOKITELTU],
  defaultViranomainenId: Viranomainen.DVV,
  viranomainenIds: [Viranomainen.DVV],
}

export const vihkimistodistustilausMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMISTODISTUSTILAUS_VERKKOLOMAKKEELLA_ASIAKAS,
      restrictionMetadata: vihkimistodistustilausOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: vihkimistodistustilaus5VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['0a772b05-9817-4090-96f2-e82571c9f2fd'],
      defaultToivoAsiakirjaTypeId: '0a772b05-9817-4090-96f2-e82571c9f2fd',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMISTODISTUSTILAUS_VERKKOLOMAKKEELLA_VIRKAILIJA,
      restrictionMetadata: vihkimistodistustilausOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: vihkimistodistustilaus5VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['aaf251f7-e160-4464-bc2f-e7d07cdb2d63'],
      defaultToivoAsiakirjaTypeId: 'aaf251f7-e160-4464-bc2f-e7d07cdb2d63',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIHKIMISTODISTUSTILAUS,
      restrictionMetadata: vihkimistodistustilausOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: vihkimistodistustilaus5VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['6763b0c3-54d8-4278-a334-7e62b30b9fba'],
      defaultToivoAsiakirjaTypeId: '6763b0c3-54d8-4278-a334-7e62b30b9fba',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: vihkimistodistustilausOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: vihkimistodistustilaus5VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['4a4a99d2-556a-4059-b720-f49266a3cddb'],
      defaultToivoAsiakirjaTypeId: '4a4a99d2-556a-4059-b720-f49266a3cddb',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSPYYNTO,
      restrictionMetadata: vihkimistodistustilausOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: vihkimistodistustilaus5VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['29cb623b-7d51-41ca-af4c-3d3321181274'],
      defaultToivoAsiakirjaTypeId: '29cb623b-7d51-41ca-af4c-3d3321181274',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYS,
      restrictionMetadata: vihkimistodistustilausOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: vihkimistodistustilaus5VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['59c88f4b-a9c7-4f10-b715-2029d534a6c6'],
      defaultToivoAsiakirjaTypeId: '59c88f4b-a9c7-4f10-b715-2029d534a6c6',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LUOVUTETTU_VIHKIMISTODISTUS,
      restrictionMetadata: vihkimistodistustilausOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: vihkimistodistustilaus5VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['5774c342-e4d2-4f1f-951a-a87837fa9089'],
      defaultToivoAsiakirjaTypeId: '5774c342-e4d2-4f1f-951a-a87837fa9089',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KIELTEINEN_PAATOS,
      restrictionMetadata: vihkimistodistustilausOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: vihkimistodistustilaus5VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['edaa8043-83e5-448b-8163-80f69621cc81'],
      defaultToivoAsiakirjaTypeId: 'edaa8043-83e5-448b-8163-80f69621cc81',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUUTOKSENHAKUOHJE,
      restrictionMetadata: vihkimistodistustilausJulkinenEiHenkilotietojaRestriction,
      retentionMetadata: vihkimistodistustilaus5VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['d847ea7b-af05-4c12-aad3-a138831e69a9'],
      defaultToivoAsiakirjaTypeId: 'd847ea7b-af05-4c12-aad3-a138831e69a9',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TODISTUS_PALAUTUNUT_VIRASTOON,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREHDITTY,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.TILAAJA, AsiaPersonRole.PUOLISO],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.RATKAISTU,
    manualRatkaisuTypeIds: [
      RatkaisuType.RATKAISTU,
      RatkaisuType.RAUENNUT,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.KIELTEINEN_PAATOS,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [SpecifierType.APOSTILLE, SpecifierType.MAKSETTU_TILAUKSEN_YHTEYDESSA],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.LUOVUTETTU_VIHKIMISTODISTUS, AsiakirjaType.KIELTEINEN_PAATOS],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
    {
      toimenpideTypeId: ToimenpideType.SELVITYSPYYNTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSPYYNTO],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYS],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYS,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  retentionMetadata: vihkimistodistustilaus5VuottaRetention,
  restrictionMetadata: vihkimistodistustilausOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
  emailMetadata: {
    fromAddress: 'vihkiminen@dvv.fi',
    useUserEmailIfPossible: false,
  },
}
