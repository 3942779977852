import { Block, Paragraph, Text } from 'suomifi-ui-components'
import { DiscList } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/common'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const OmaisuusluetteloOhje: React.FC = () => {
  const [t] = useTranslation()
  return (
    <>
      <Paragraph>{t('mitaTarvitsenOmaisuusluettelonTekoon-kuvaus')}</Paragraph>
      <Paragraph mt="m" mb="xxs">
        <Text variant="bold">{t('omLtarvittavatTiedotListHeading')}</Text>
      </Paragraph>
      <DiscList>
        <li>{t('olTieto1')}</li>
        <li>{t('olTieto2')}</li>
        <li>{t('olTieto3')}</li>
        <li>{t('olTieto4')}</li>
        <li>{t('olTieto5')}</li>
        <li>{t('olTieto6')}</li>
      </DiscList>
      <Block mt="m" />

      <Paragraph>
        <Text variant="bold">{t('omaisuusluettelonAsiakirjat')}</Text>
      </Paragraph>
      <Paragraph>{t('omLtarvittavatAsiakirjatOhje')}</Paragraph>

      <Paragraph mt="m">{t('omLtarvittavatAsiakirjatListHeading')}</Paragraph>
      <Block mt="m" />
      <DiscList>
        <li>{t('olAsiakirjainfo1')}</li>
        <li>{t('olAsiakirjainfo2')}</li>
        <li>{t('olAsiakirjainfo3')}</li>
        <li>{t('olAsiakirjainfo4')}</li>
        <li>{t('olAsiakirjainfo5')}</li>
        <li>{t('olAsiakirjainfo6')}</li>
        <li>{t('olAsiakirjainfo7')}</li>
      </DiscList>
    </>
  )
}
