import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import PankkilainatForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/velat/PankkilainatForm'
import TakauksetForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/velat/TakauksetForm'
import MuutVelatForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/velat/MuutVelatForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { TilintarkastusForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'
import VahvistaEsitaytetytTiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/VahvistaEsitaytetytTiedot'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const VelatPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const verticalMarginToken = 'xl'
  const onErrorRef = useRef<HTMLDivElement>(null)

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock>
        <PageStepHeading heading={t('velat')} steps={TILINTARKASTUS_STEPS} />
        <FormErrorSummary ref={onErrorRef} />
        <MultiParagraphText
          text={t(
            isOmaisuusluettelo() ? 'velatInfoText1' : 'velatTilikausiInfo1'
          )}
        />
        <VahvistaEsitaytetytTiedot
          tiedot={['pankkilainat', 'takaukset', 'muutVelat']}
          dataTestId={mkOmaisuusTestId('velatPage-isTarkistettu')}
        >
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <PankkilainatForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <TakauksetForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

          <MuutVelatForm />
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        </VahvistaEsitaytetytTiedot>
        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default VelatPage
