import { i18n } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { createI18nInstance } from 'common-translations/src/vtj/i18next-utils'
import commonTranslations from 'asia-common/src/vtj/localization/common.translations'
import holhousCommonTranslations from 'holhous-common/src/vtj/static/holhous-common.translations'
import fi from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/localization/localization_fi.json'
import sv from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/localization/localization_sv.json'
import { LanguageCode } from 'common/src/vtj/LanguageCode.enum'
import { edunvalvontaAsiointiTranslations } from 'edunvalvonta-asiointi/src/vtj/asiointi/localization/i18next'

export const tilinpaatosAsiointiTranslations = {
  namespace: 'tili-asiointi',
  translations: { fi, sv },
}

export const initTilinpaatosAsiointiI18n = (
  language?: LanguageCode,
  initForReact = true
): i18n =>
  createI18nInstance({
    language,
    defaultBundle: tilinpaatosAsiointiTranslations,
    additionalBundles: [
      holhousCommonTranslations,
      commonTranslations,
      edunvalvontaAsiointiTranslations,
    ],
    modules: initForReact ? [initReactI18next] : [],
  })
