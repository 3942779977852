import { ChildTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import {
  suomifiDesignTokens,
  Heading,
  Expander,
  ExpanderTitleButton,
  Text,
  ExpanderContent,
  Button,
  IconEdit,
  IconRemove,
  Block,
  IconWarning,
  Paragraph,
} from 'suomifi-ui-components'
import { PuuttuvatEsitaytetytTiedot } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/VahvistaEsitaytetytTiedot'
import { TiliLomakeKenttaId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake-tunniste'

export const FormListInputExpander: React.FC<
  PropsWithChildren & {
    heading: string
    headingRight: string | undefined
    subHeading?: string
    createDataTestId: (...arg: ChildTestId[]) => string
    onEdit: () => void
    onRemove: (deleteModalText: string) => void
    tiliLomakeKentta: TiliLomakeKenttaId
    pakollinenTietoPuuttuu?: boolean
  }
> = ({
  children,
  heading,
  headingRight,
  subHeading,
  createDataTestId,
  onEdit,
  onRemove,
  pakollinenTietoPuuttuu,
  tiliLomakeKentta,
}) => {
  const [t] = useTranslation()
  const [isOpen, setIsOpen] = useState(pakollinenTietoPuuttuu)

  const puuttuvatTiedot = useContext(PuuttuvatEsitaytetytTiedot)
  const id = createDataTestId(tiliLomakeKentta)
  useEffect(() => {
    if (pakollinenTietoPuuttuu) {
      puuttuvatTiedot[id] = tiliLomakeKentta
    } else {
      puuttuvatTiedot[id] = undefined
    }
    return () => {
      // clear up when unmounting
      puuttuvatTiedot[id] = undefined
    }
  }, [pakollinenTietoPuuttuu, id, puuttuvatTiedot, tiliLomakeKentta])

  return (
    <Expander
      data-test-id={createDataTestId('expander-container')}
      defaultOpen={pakollinenTietoPuuttuu}
    >
      <ExpanderTitleButton
        data-test-id={createDataTestId('expander-title-button')}
        onClick={() => setIsOpen(!isOpen)}
      >
        <ExpanderTitleMainText>
          <ExpanderHeading
            variant="h4"
            data-test-id={createDataTestId('expander-heading')}
          >
            {heading}
          </ExpanderHeading>
          {pakollinenTietoPuuttuu && !isOpen ? (
            <div>
              <TaydennaAlert color={suomifiDesignTokens.colors.accentBase} />
              <Text color="blackBase"> {t('lisaaPuuttuvatTiedot')}</Text>
            </div>
          ) : (
            <Text data-test-id={createDataTestId('expander-heading-count')}>
              {headingRight}
            </Text>
          )}
        </ExpanderTitleMainText>
        {subHeading && !isOpen && (
          <ExpanderTitleSubHeading
            smallScreen
            data-test-id={createDataTestId('expander-subheading')}
          >
            {subHeading}
          </ExpanderTitleSubHeading>
        )}
      </ExpanderTitleButton>

      <ExpanderContent
        data-test-id={createDataTestId('expander-content-container')}
      >
        {children}
        <ExpanderModifyComponent>
          <Block mt="s" />
          <Button
            icon={<IconEdit />}
            onClick={onEdit}
            data-test-id={createDataTestId('expander-content-edit-button')}
          >
            {t(pakollinenTietoPuuttuu ? 'lisaaPuuttuvatTiedot' : 'edit')}
          </Button>
          <Button
            icon={<IconRemove />}
            variant="secondary"
            onClick={() => onRemove(`${heading} ${headingRight ?? ''}`)}
            data-test-id={createDataTestId('expander-content-remove-button')}
          >
            {t('remove')}
          </Button>
        </ExpanderModifyComponent>
      </ExpanderContent>
    </Expander>
  )
}

export const ContentItemOrPakollinenTietoPuuttuuWarning: React.FC<{
  heading: string
  content: string | number | undefined
}> = ({ heading, content }) => {
  const [t] = useTranslation()

  return content === undefined ? (
    <Block mb="s">
      <Heading variant="h5" mb="xxs">
        {t(heading)}{' '}
      </Heading>

      <ColorBlock color="depthDark2">
        <TaydennaAlert color={suomifiDesignTokens.colors.blackBase} />
        {t('tietoPuuttuu')}
      </ColorBlock>
    </Block>
  ) : (
    <Block mb="s">
      <Heading variant="h5">{t(heading)}</Heading>
      <Paragraph>{content}</Paragraph>
    </Block>
  )
}

const ExpanderTitleMainText = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
`

const ExpanderTitleSubHeading = styled(Text)`
  color: ${suomifiDesignTokens.colors.depthDark1};
`

const ColorBlock = styled.div`
  background-color: ${suomifiDesignTokens.colors.warningLight1};
  width: fit-content;
  padding: 5px 10px 5px 10px;
`

const ExpanderHeading = styled(Heading)`
  color: ${suomifiDesignTokens.colors.highlightBase};
`

const ExpanderModifyComponent = styled.div`
  width: 100%;
  display: flex;
  gap: ${suomifiDesignTokens.spacing.s};
  justify-content: flex-end;
`

const TaydennaAlert = styled(IconWarning)`
  display: inline-block;
  vertical-align: -3px;
  margin-right: ${suomifiDesignTokens.spacing.xxs};
`
