import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { Heading } from 'suomifi-ui-components'
import {
  EiIlmoitettujaTietoja,
  YhteenvetoTilirivi,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  formatDateAsLocal,
  formatNumberWithComma,
  parseStringAsNumberWithDigits,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import {
  countVarat,
  getArvoAlussaString,
  getArvoLopussaString,
  getKappalemaaraLopussaString,
  getKappalemaaraTilikaudenAlussaString,
  getKayttotarkoitus,
  getOmistusosuus,
  getOsakkeidenMaara,
  getVelallinenString,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'

const Varat: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    lomake,
    vaatimus: { asiaType },
  } = getTilintarkastusStore()
  const { varat } = lomake.tili.tilirivit
  const { lomakkeelleValitutTiedot } = lomake
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkYhteenvetoTestId('varat-container')}>
      <Heading variant="h3">{t('varat')}</Heading>
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('talletuksetHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.talletukset ? (
        varat.talletukset.map((tal, i) => (
          <YhteenvetoTilirivi
            heading={`${t('tilityyppi_' + (tal.tyyppi || ''))}, ${
              tal.myontajanNimi
            }`}
            content={
              tal.yhteiskayttotili
                ? [
                    `${t('yhteiskayttoTili')}: ${t('kylla')}`,
                    `${t('tilinumero')}: ${tal.vakuusTaiTilinumero}`,
                    `${t('yhteiskayttoinenTiliSaldo')}: ${formatNumberWithComma(
                      tal.kokonaissaldoLopussa || 0
                    )} €`,
                    tal.arvoTilikaudenAlussaEur !== undefined
                      ? `${t(
                          'henkilonOsuusEuroinaAlussa'
                        )}: ${formatNumberWithComma(
                          tal.arvoTilikaudenAlussaEur
                        )} €`
                      : '',
                    tal.arvoTilikaudenLopussaEur !== undefined
                      ? `${t(
                          'henkilonOsuusEuroinaLopussa'
                        )}: ${formatNumberWithComma(
                          tal.arvoTilikaudenLopussaEur
                        )} €`
                      : '',
                  ]
                : [
                    `${t('tilinumero')}: ${tal.vakuusTaiTilinumero}`,
                    getArvoAlussaString(
                      t,
                      asiaType,
                      tal.arvoTilikaudenAlussaEur
                    ) || '',
                    getArvoLopussaString(
                      t,
                      asiaType,
                      tal.arvoTilikaudenLopussaEur
                    ) || '',
                  ]
            }
            attachments={tal.asiakirjat}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {t('arvopaperitHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.arvopaperit ? (
        varat.arvopaperit.map((ap, i) => (
          <YhteenvetoTilirivi
            heading={`${t('arvopaperityyppi_' + (ap.tyyppi || ''))} ${
              ap.yhtionNimi
            }`}
            content={[
              getKappalemaaraTilikaudenAlussaString(
                t,
                ap.kappalemaaraTilikaudenAlussa
              ),
              getKappalemaaraLopussaString(
                t,
                asiaType,
                ap.kappalemaaraTilikaudenLopussa
              ),
              getArvoAlussaString(t, asiaType, ap.arvoTilikaudenAlussaEur),
              getArvoLopussaString(t, asiaType, ap.arvoTilikaudenLopussaEur),
            ]}
            attachments={ap.asiakirjat}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('osuudetYhtioissaYrityksissaHeading')}
      </Heading>

      {lomakkeelleValitutTiedot.osuudetHenkiloYhtiossaJaYrityksissa ? (
        varat.osuudetHenkiloYhtiossaJaYrityksissa.map((os, i) => (
          <YhteenvetoTilirivi
            heading={os.yhtionNimi}
            content={[
              getOsakkeidenMaara(os.osakkeidenMaara, t),
              getOmistusosuus(os.omistusosuusProsentteina, t),
              getArvoAlussaString(t, asiaType, os.arvoTilikaudenAlussaEur),
              getArvoLopussaString(t, asiaType, os.arvoTilikaudenLopussaEur),
            ].filter(Boolean)}
            attachments={os.asiakirjat}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('huoneistotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.huoneistot ? (
        varat.huoneistot.map((h, i) => (
          <YhteenvetoTilirivi
            heading={`${h.yhtionNimi},  ${t('osakkeidenNumerot')} ${
              h.osakkeidenNumerot
            }`}
            content={[
              getKayttotarkoitus(t, h),
              getOmistusosuus(h.omistusosuusProsentteina, t),
              getArvoAlussaString(t, asiaType, h.arvoTilikaudenAlussaEur),
              getArvoLopussaString(t, asiaType, h.arvoTilikaudenLopussaEur),
            ].filter(Boolean)}
            attachments={[...h.asiakirjat, ...h.vuokrasopimukset]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('kiinteistotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.kiinteistot ? (
        varat.kiinteistot.map((k, i) => (
          <YhteenvetoTilirivi
            heading={`${k.kiinteistonNimi},  ${k.kiinteistonTunnus}`}
            content={[
              getKayttotarkoitus(t, k),
              getOmistusosuus(k.omistusosuusProsentteina, t),
              getArvoAlussaString(t, asiaType, k.arvoTilikaudenAlussaEur),
              getArvoLopussaString(t, asiaType, k.arvoTilikaudenLopussaEur),
            ].filter(Boolean)}
            attachments={[...k.asiakirjat, ...k.vuokrasopimukset]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('irtaimistotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.irtaimistot ? (
        varat.irtaimistot.map((irtaimisto, i) => (
          <YhteenvetoTilirivi
            heading={irtaimisto.nimi}
            content={[
              getArvoAlussaString(
                t,
                asiaType,
                irtaimisto.arvoTilikaudenAlussaEur
              ),
              getArvoLopussaString(
                t,
                asiaType,
                irtaimisto.arvoTilikaudenLopussaEur
              ),
            ].filter(Boolean)}
            attachments={irtaimisto.asiakirjat}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('saatavatHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.saatavat ? (
        varat.saatavat.map((s, i) => (
          <YhteenvetoTilirivi
            heading={getVelallinenString(
              t,
              s.velallisenEtunimi,
              s.velallisenSukunimi
            )}
            content={[
              getArvoAlussaString(t, asiaType, s.arvoTilikaudenAlussaEur),
              getArvoLopussaString(t, asiaType, s.arvoTilikaudenLopussaEur),
            ].filter(Boolean)}
            attachments={s.asiakirjat}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('muutVaratHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.muutVarat ? (
        varat.muutVarat.map((mv, i) => (
          <YhteenvetoTilirivi
            heading={mv.nimi}
            content={[
              getArvoAlussaString(t, asiaType, mv.arvoTilikaudenAlussaEur),
              getArvoLopussaString(t, asiaType, mv.arvoTilikaudenLopussaEur),
            ].filter(Boolean)}
            attachments={mv.asiakirjat}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('osakkuudetKuolinpesissa')}
      </Heading>
      {lomakkeelleValitutTiedot.osuudetKuolinpesissa ? (
        varat.osuudetKuolinpesissa.map((os, i) => (
          <YhteenvetoTilirivi
            heading={`${t('kuolinpesa')} ${os.vainajanEtunimi} ${
              os.vainajanSukunimi
            }`}
            content={[
              parseStringAsNumberWithDigits(os.osakkuudenOsuus, 'two')
                ? `${t('osakkuudenOsuus')} ${os.osakkuudenOsuus} %`
                : os.osakkuudenOsuus,
              os.vainajanKuolinpaiva &&
                `${t('vainajanKuolinpaiva')} ${formatDateAsLocal(
                  os.vainajanKuolinpaiva
                )}`,
              getArvoAlussaString(t, asiaType, os.arvoTilikaudenAlussaEur),
              getArvoLopussaString(t, asiaType, os.arvoTilikaudenLopussaEur),
            ].filter(Boolean)}
            attachments={os.asiakirjat}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {`${t('varatyhteensa')}: ${formatNumberWithComma(countVarat())} €`}
      </Heading>
    </section>
  )
})

export default Varat
