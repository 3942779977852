import React, { useId } from 'react'
import { observer } from 'mobx-react'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  Block,
  Heading,
  Paragraph,
  RadioButton,
  RadioButtonGroup,
  suomifiDesignTokens,
  Textarea,
} from 'suomifi-ui-components'
import { getStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import {
  AsiointiApplication,
  LupaAsiointiAttachment,
  AsiointiBatch,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/asiointi-batch.type'
import {
  applicationCompareFn,
  AsiointiLupaEstateSpecification,
  asiointiLupaInfos,
  getExpectedAsiakirjaTypes,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-info'
import FileBox, {
  FileBoxFile,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/file/FileBox'
import { LupaAsiointiAsiakirjaTypeId } from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import { FileRejection } from 'react-dropzone'
import {
  addFailedApplicationAttachment,
  removeAttachment,
  uploadApplicationAttachment,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/file-actions'
import { AsiointiLupaTypeId } from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import {
  ChildTestId,
  mkHakemusTestId,
  mkLupaAttachmentTestId,
  mkLupaTestId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { LupaApplicationStep } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import * as FileUtil from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/file/file.util'
import CurrentStepDisplay from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/common/CurrentStepDisplay'
import { setBatchValues } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/batch-actions'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import {
  BlueBorderedContentBlock,
  ResponsiveBorderedContentBlock,
  FocusableDiv,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import ValidationErrorsSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/validation/ValidationErrorsSummary'
import {
  resolveAsiakirjaValidationErrorText,
  resolveFieldStatusProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/validation/validation.util'
import { useValidationErrorFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/validation/validation-error-focus.util'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { trimEventTargetValue } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/input.utils'
import { ContainsEstate } from 'lupa-backend/src/vtj/elsa/lupa/lupa-enums'
import { setApplicationValues } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/actions/application-actions'
import { AsiointiStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store.type'
import {
  attachmentToFileBoxFile,
  lupaAllowedMimeTypes,
  lupaMaxSizeInBytes,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-file.util'

const InfoInputPage: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const { batch, showValidationErrorsForSteps, validationFailedForSteps } =
    getStore()
  const sortedApplications = batch.applications
    .slice()
    .sort(applicationCompareFn)

  return (
    <ResponsiveBorderedContentBlock
      data-test-id={mkHakemusTestId(LupaApplicationStep.INPUT_INFO)}
    >
      <CurrentStepDisplay heading={t('hakemuksenPerustiedot')} />
      <Block mt="m" />
      <BatchBasicInfo
        batch={batch}
        showValidationErrorsForSteps={showValidationErrorsForSteps}
        validationFailedForSteps={validationFailedForSteps}
      />
      {sortedApplications.map((application, index) => (
        <React.Fragment key={application.typeId}>
          <DividerLine
            mt={isTablet ? 'xxl' : 'm'}
            mb={isTablet ? 'xxl' : 'm'}
          />
          <Application application={application} index={index} />
        </React.Fragment>
      ))}
    </ResponsiveBorderedContentBlock>
  )
})

const BatchBasicInfo: React.FC<
  {
    batch: Pick<
      AsiointiBatch,
      'arguments' | 'description' | 'validationErrors' | 'applications'
    >
  } & Pick<
    AsiointiStore,
    'showValidationErrorsForSteps' | 'validationFailedForSteps'
  >
> = observer(
  ({ batch, showValidationErrorsForSteps, validationFailedForSteps }) => {
    const [t] = useTranslation()
    const { validationErrors, applications } = batch

    const showErrors =
      showValidationErrorsForSteps.has(LupaApplicationStep.INPUT_INFO) &&
      validationFailedForSteps.has(LupaApplicationStep.INPUT_INFO)

    return (
      <>
        <Paragraph>{t('taytaHakemuksenPerustiedot')}</Paragraph>
        <Block mt="m" />
        {showErrors && (
          <>
            <ValidationErrorsSummary
              batch={{ validationErrors, applications }}
            />
            <Block mt="m" />
          </>
        )}
        <BlueBorderedContentBlock padding="s">
          <Textarea
            ref={useValidationErrorFocus<HTMLTextAreaElement>(
              mkHakemusTestId('description')
            )}
            data-test-id={mkHakemusTestId('description')}
            fullWidth
            labelText={t('kerroJaYksiloiToimenpide')}
            onBlur={(event) =>
              void setBatchValues({ description: trimEventTargetValue(event) })
            }
            {...resolveFieldStatusProps(batch, 'description', t)}
          >
            {batch.description || ''}
          </Textarea>
          <Block mt="s" />
          <Textarea
            ref={useValidationErrorFocus<HTMLTextAreaElement>(
              mkHakemusTestId('arguments')
            )}
            data-test-id={mkHakemusTestId('arguments')}
            fullWidth
            labelText={t('kerroMiksiToimenpideOnEdunMukainen')}
            onBlur={(event) =>
              void setBatchValues({ arguments: trimEventTargetValue(event) })
            }
            {...resolveFieldStatusProps(batch, 'arguments', t)}
          >
            {batch.arguments || ''}
          </Textarea>
        </BlueBorderedContentBlock>
      </>
    )
  }
)

const Application: React.FC<{
  application: AsiointiApplication
  index: number
}> = observer(({ application, index }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const mkTestId = (...ids: ChildTestId[]) =>
    mkLupaTestId(application.typeId, ...ids)
  const lupaTypeInfo = asiointiLupaInfos[application.typeId]

  return (
    <Block variant="section" data-test-id={mkTestId(index)}>
      <LupaAsiaText>{t('lupaAsia')}</LupaAsiaText>
      <Block mb={'xs'} />
      <Heading
        variant="h2"
        smallScreen={!isTablet}
        data-test-id={mkTestId('lupatyyppi')}
      >
        {t(`asiointiLupaType-${application.typeId}`)}
      </Heading>
      <Block mb={'xs'} />
      <Paragraph>{t('taytaToimenpiteeseenLiittyvatTiedot')}</Paragraph>
      <Block mb={'m'} />
      <BlueBorderedContentBlock padding="s">
        {lupaTypeInfo.containsEstate ===
          AsiointiLupaEstateSpecification.OPTIONAL && (
          <>
            <RadioButtonGroup
              labelText={t('onkoLupaAsianOsapuolenaKuolipesa')}
              name={mkTestId('kuolinpesavalinta')}
              value={application.containsEstate}
              onChange={(containsEstate) =>
                setApplicationValues(application.typeId, {
                  containsEstate:
                    containsEstate as AsiointiApplication['containsEstate'],
                })
              }
              data-test-id={mkTestId('kuolinpesavalinta')}
            >
              <RadioButton
                value={ContainsEstate.LIITTYY_KUOLINPESA}
                data-test-id={mkTestId(
                  'kuolinpesa',
                  ContainsEstate.LIITTYY_KUOLINPESA
                )}
              >
                {t('kylla')}
              </RadioButton>
              <RadioButton
                value={ContainsEstate.EI_KUOLINPESAA}
                data-test-id={mkTestId(
                  'kuolinpesa',
                  ContainsEstate.EI_KUOLINPESAA
                )}
              >
                {t('ei')}
              </RadioButton>
            </RadioButtonGroup>
            <Block mb={'xl'} />
          </>
        )}

        <Block data-test-id={mkTestId('liitteet')}>
          {getExpectedAsiakirjaTypes(application).map(
            (asiakirjaTypeId, index) => (
              <React.Fragment key={asiakirjaTypeId}>
                {index > 0 && <Block mt="s" />}
                <AsiakirjaBox
                  errorMessage={resolveAsiakirjaValidationErrorText(
                    application,
                    asiakirjaTypeId,
                    t
                  )}
                  lupaTypeId={application.typeId}
                  asiakirjaTypeId={asiakirjaTypeId}
                  asiakirjas={application.attachments.filter(
                    (att) => att.asiakirjaTypeId === asiakirjaTypeId
                  )}
                />
              </React.Fragment>
            )
          )}
        </Block>
      </BlueBorderedContentBlock>
    </Block>
  )
})

const LupaAsiaText = styled.div`
  color: ${suomifiDesignTokens.colors.depthDark1};
  font-size: 14px;
  line-height: 18px;
`

const AsiakirjaBox: React.FC<{
  lupaTypeId: AsiointiLupaTypeId
  asiakirjaTypeId: LupaAsiointiAsiakirjaTypeId
  errorMessage: string
  asiakirjas: LupaAsiointiAttachment[]
}> = observer(({ lupaTypeId, asiakirjaTypeId, errorMessage, asiakirjas }) => {
  const [t] = useTranslation()
  const fileBoxId = useId()
  const fileBoxFiles: FileBoxFile[] = asiakirjas.map((ak) =>
    attachmentToFileBoxFile(t, ak)
  )

  const onAddFiles = (validFiles: File[], rejections: FileRejection[]) => {
    validFiles.forEach((file) => {
      void uploadApplicationAttachment(lupaTypeId, {
        file,
        asiakirjaTypeId,
      })
    })
    rejections.forEach(
      (rejection) =>
        void addFailedApplicationAttachment(lupaTypeId, {
          asiakirjaTypeId,
          status: 'failed',
          error: FileUtil.getError(rejection),
          file: rejection.file,
        })
    )
  }

  const onRemoveFile = async (file: FileBoxFile) => {
    void (await removeAttachment(file.id))
  }

  return (
    <FocusableDiv
      ref={useValidationErrorFocus<HTMLDivElement>(
        mkLupaAttachmentTestId(lupaTypeId, asiakirjaTypeId)
      )}
      tabIndex={-1}
      data-test-id={mkLupaAttachmentTestId(
        lupaTypeId,
        asiakirjaTypeId,
        'focusable',
        'container'
      )}
    >
      <Heading variant="h5" as="h3" htmlFor={fileBoxId}>
        {t(`asiointiAsiakirjaType-${asiakirjaTypeId}`)}
      </Heading>
      <Block mb="xs" />
      <FileBox
        htmlId={fileBoxId}
        files={fileBoxFiles}
        onAdd={onAddFiles}
        onRemove={onRemoveFile}
        errorMessage={errorMessage}
        minFileSizeBytes={1}
        maxFileSizeBytes={lupaMaxSizeInBytes}
        allowedMimeTypes={lupaAllowedMimeTypes}
        data-test-id={mkLupaAttachmentTestId(lupaTypeId, asiakirjaTypeId)}
      />
    </FocusableDiv>
  )
})

export default InfoInputPage
