import React from 'react'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { useTranslation } from 'react-i18next'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { Block, Heading } from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { EiIlmoitettujaTietoja } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'

const Verotiedot: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTilintarkastusStore().lomake.tili
  return (
    <section data-test-id={mkYhteenvetoTestId('verotiedot-container')}>
      <Heading variant="h3">{t('verotiedot')}</Heading>
      <Block mt="xs">
        <Heading variant="h5" as="h4" mb="xxs">
          {t('veroIlmoitusTaiVerotuspaatosTuloverosta')}
        </Heading>
        {tili.verotustiedot.length ? (
          <AttachmentList attachments={tili.verotustiedot} />
        ) : (
          <EiIlmoitettujaTietoja />
        )}
      </Block>
    </section>
  )
})

export default Verotiedot
