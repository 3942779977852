import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, InlineAlert, Paragraph } from 'suomifi-ui-components'
import { action } from 'mobx'
import FormSimpleListInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormSimpleListInput'
import React, { FC } from 'react'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import AriaFriendlyInlineAlert from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/AriaFriendlyInlineAlert'

const OmassaKaytossaOlevaOmaisuusForm: FC = observer(() => {
  const [t] = useTranslation()
  const {
    lomake: {
      tili: { paamiehenOmaisuus },
      lomakkeelleValitutTiedot,
      tarkistetutEsitaytetytTiedot,
    },
  } = getTilintarkastusStore()
  const isTablet = useDeviceContext().tablet
  const isTarkistettu = tarkistetutEsitaytetytTiedot.omaisuusOmassaKaytossa

  const verticalMarginToken = isTablet ? 'm' : 's'

  const containsValunutItem = () =>
    paamiehenOmaisuus.omaisuusOmassaKaytossa.find(
      (item) => item.isEsitaytetty === true
    )

  return (
    <section data-test-id={mkOmaisuusTestId('kaytossa-oleva-container')}>
      <Heading mb="xs" variant="h3">
        {t('henkilonKaytossaOlevaMuuOmaisuus')}
      </Heading>
      <Paragraph mb={verticalMarginToken}>
        {t('ohjeOnkoHenkilollaOmaisuutta')}
      </Paragraph>
      {isTarkistettu === false && containsValunutItem() && (
        <AriaFriendlyInlineAlert
          status="warning"
          mb="s"
          data-test-id={mkOmaisuusTestId('tarkista-esitaytetyt-tiedot')}
        >
          {t('tarkistaEsitaytetytTiedot')}
        </AriaFriendlyInlineAlert>
      )}

      {!containsValunutItem() && (
        <FormRadioButtonGroup
          required
          value={lomakkeelleValitutTiedot.omaisuusOmassaKaytossa?.toString()}
          updateValue={action((value) => {
            lomakkeelleValitutTiedot.omaisuusOmassaKaytossa = value === 'true'
            if (
              lomakkeelleValitutTiedot.omaisuusOmassaKaytossa &&
              !paamiehenOmaisuus.omaisuusOmassaKaytossa?.length
            ) {
              paamiehenOmaisuus.omaisuusOmassaKaytossa.push({
                id: crypto.randomUUID(),
                value: '',
              })
            }
          })}
          data-test-id={mkOmaisuusTestId('omassa-kaytossa-radio')}
          labelText={t('onkoHenkilollaOmaisuutta')}
          radioButtons={[
            { value: 'false', labelText: t('ei') },
            { value: 'true', labelText: t('kylla') },
          ]}
        />
      )}

      {lomakkeelleValitutTiedot.omaisuusOmassaKaytossa && (
        <Block mt={verticalMarginToken}>
          <FormSimpleListInput
            dataTestIdPrefix={mkOmaisuusTestId('kaytto-omaisuus')}
            description={t('kaytossaOlevaOmaisuusListInfo')}
            heading={t('kaytossaOlevaOmaisuusListHeading')}
            state={paamiehenOmaisuus.omaisuusOmassaKaytossa}
          />
        </Block>
      )}
      {lomakkeelleValitutTiedot.omaisuusOmassaKaytossa === false &&
        paamiehenOmaisuus.omaisuusOmassaKaytossa.find(
          (omaisuus) => !!omaisuus.value
        ) && (
          <InlineAlert mt={'s'} status={'warning'}>
            {t('valittuEiInlineAlert')}
          </InlineAlert>
        )}
    </section>
  )
})

export default OmassaKaytossaOlevaOmaisuusForm
