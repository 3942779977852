import fp from 'lodash/fp'
import HttpStatus from 'http-status'
import axios, { CancelToken, AxiosRequestConfig } from 'axios'
import {
  createWithAxiosInstance,
  isCancelError,
  enableCancel,
  Result,
  HttpClient,
} from 'common/src/vtj/browser/http'
import actions from 'asia-common/src/vtj/action-singleton'
import { isTimeoutErrorCode } from 'common/src/vtj/timeout-error.enum'

export interface ApiCancel {
  cancelApiCall(): void
  cancelToken: CancelToken
}
export function createApiCancel(): ApiCancel {
  const { cancelFunction, configWithCancel } = enableCancel()
  return {
    cancelApiCall: cancelFunction,
    cancelToken: configWithCancel.cancelToken,
  } as ApiCancel
}

export interface ApiResponse<T> {
  isTimedOut: boolean
  isOk: boolean
  isCancelled: boolean
  status: number
  data: T
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errorData?: any
}

/**
 * Executes a cancellable microfrontend backend API call. The call also handles session expiration
 * by displaying a dialog for logging in again if the call fails to an Unauthorized (401) error.
 *
 * The api call gets a fresh http client instance configured with base URL.
 */
export const executeBackendClient = async <T>(
  apiCall: (client: HttpClient) => Promise<Result<T>>,
  baseURL = '/',
  cancelToken?: CancelToken
): Promise<ApiResponse<T>> => {
  const config: AxiosRequestConfig = {
    baseURL,
    ...(cancelToken ? { cancelToken } : {}),
  }
  const httpClient = createHttpClientWithSessionExpirationHandling(config)
  const [err, response] = await apiCall(httpClient)

  const isTimedOut: boolean = isTimeoutErrorCode(err?.code)

  const isCancelled = isCancelError(err)
  const isOk = !!response
  const data = (isOk ? response.data : null) as T // intentional hard fail for caller. Caller should use isOk before accessing
  const status = fp.cond([
    [() => isCancelled, () => HttpStatus.IM_A_TEAPOT],
    [() => isOk, () => response?.status],
    [fp.stubTrue, () => fp.get('response.status', err) as number],
  ])([err, response])
  const errorData = fp.cond([
    [() => isOk, () => undefined],
    [() => isCancelled || isTimedOut, () => err],
    [fp.stubTrue, () => fp.get('response.data', err)],
  ])([err, response])
  return {
    isOk,
    isCancelled,
    status,
    data,
    errorData,
    isTimedOut: isTimedOut,
  } as ApiResponse<T>
}

const createHttpClientWithSessionExpirationHandling = (
  config: AxiosRequestConfig
): HttpClient => {
  const axiosInstance = axios.create(config)
  axiosInstance.interceptors.response.use(undefined, (error) => {
    if (error.response?.status === HttpStatus.UNAUTHORIZED) {
      actions.handleSessionExpiration()
    }
    return Promise.reject(error)
  })
  return createWithAxiosInstance(axiosInstance)
}
