import { Block } from 'suomifi-ui-components'
import React, { useRef } from 'react'
import { ResponsiveBorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FormProvider } from 'react-hook-form'
import { TILINTARKASTUS_STEPS } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import FormNavigationBar from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNavigationBar'
import SaannollisetTulotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/SaannollisetTulotForm'
import VerotiedotForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/VerotiedotForm'
import FormErrorSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormErrorSummary'
import AlaikainenElinkustannusForm from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yleiskatsaus/AlaikainenElinkustannusForm'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import {
  getTili,
  isOmaisuusluettelo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { AsiointiElaketyyppi } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { TilintarkastusForm } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import VahvistaEsitaytetytTiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/VahvistaEsitaytetytTiedot'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const ElakkeetJaEtuudetPage: React.FC<{
  form: TilintarkastusForm
}> = observer(({ form }) => {
  const [t] = useTranslation()
  const onErrorRef = useRef<HTMLDivElement>(null)
  const verticalMarginToken = 'xl'
  const {
    vaatimus: { isPrincipalUnderaged },
  } = getTilintarkastusStore()
  const { tulotiedot } = getTili()

  const showAlaikainenElinkustannuksetForm = !!(
    isPrincipalUnderaged &&
    tulotiedot.saannollisetTulot.find(
      ({ tyyppi }) => tyyppi && AsiointiElaketyyppi.includes(tyyppi)
    )
  )

  return (
    <FormProvider {...form}>
      <ResponsiveBorderedContentBlock
        data-test-id={mkYleiskatsausTestId('elakkeetJaEtuudetPage-container')}
      >
        <PageStepHeading
          heading={t(
            isOmaisuusluettelo() ? 'elakkeetJaEtuudet' : 'saannollisetTulot'
          )}
          steps={TILINTARKASTUS_STEPS}
        />
        <FormErrorSummary ref={onErrorRef} />
        <MultiParagraphText
          text={t(
            isOmaisuusluettelo()
              ? 'elakkeetEtuudetPageInfo'
              : 'saannollisetTulotPageInfo'
          )}
        />
        <VahvistaEsitaytetytTiedot
          tiedot={[
            'saannollisetTulot',
            'elinkustannuksiinKaytettavatElaketulot',
          ]}
          dataTestId={mkYleiskatsausTestId('elakkeetEtuudetPage-isTarkistettu')}
        >
          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <SaannollisetTulotForm />
          <Block mt={verticalMarginToken} />
          {showAlaikainenElinkustannuksetForm && (
            <AlaikainenElinkustannusForm />
          )}

          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
          <VerotiedotForm />

          <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
        </VahvistaEsitaytetytTiedot>
        <FormNavigationBar onErrorRef={onErrorRef} />
      </ResponsiveBorderedContentBlock>
    </FormProvider>
  )
})

export default ElakkeetJaEtuudetPage
