import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { FrontVakuutus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { getPaivamaaraString } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { EiIlmoitettujaTietoja } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Voimassaoloaika } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'

const Vakuutus: React.FC<{ v: FrontVakuutus }> = ({ v }) => {
  const [t] = useTranslation()
  const content = [
    v.toimittaja,
    v.voimassaoloaika === Voimassaoloaika.MAARA_AIKAINEN
      ? t('maaraaikainen')
      : v.voimassaoloaika === Voimassaoloaika.TOISTAISEKSI_VOIMASSA_OLEVA
      ? t('toistaiseksiVoimassaOleva')
      : undefined,
    getPaivamaaraString(t, v.beginDate, v.endDate),
  ]
    .filter((s) => s)
    .join(', ')

  return (
    <Block>
      <Heading variant="h5" as="h4" mt="xs">
        {v.vakuutustyyppi}
      </Heading>
      <Paragraph>{content}</Paragraph>
      <AttachmentList attachments={v.asiakirjat} />
    </Block>
  )
}

const Vakuutukset: React.FC = observer(() => {
  const [t] = useTranslation()
  const { tili, lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  return (
    <section data-test-id={mkYhteenvetoTestId('vakuutukset-container')}>
      <Heading variant="h3">{t('vakuutuksetHeading')}</Heading>

      {lomakkeelleValitutTiedot.vakuutukset ? (
        tili.vakuutukset.map((v, index) => <Vakuutus v={v} key={index} />)
      ) : (
        <EiIlmoitettujaTietoja />
      )}
    </section>
  )
})

export default Vakuutukset
