import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import {
  Block,
  Button,
  Heading,
  IconPrint,
  InlineAlert,
  Paragraph,
} from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  FocusableDiv,
  ResponsiveBorderedContentBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  TILINTARKASTUS_ROUTES,
  TILINTARKASTUS_STEPS,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-ui-route.util'
import { FormSubmitBar } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormSubmitBar'
import { FormProvider } from 'react-hook-form'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import PageStepHeading from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/PageStepHeading'
import Perustiedot from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/Perustiedot'
import FormCheckboxInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormCheckboxInput'
import FormUncompletedStepsSummary from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormUncompletedStepsSummary'
import Yhteenveto from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/Yhteenveto'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import {
  TilintarkastusForm,
  TilintarkastusRouteFormControls,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-form.util'
import { useReactToPrint } from 'react-to-print'
import { getWindowNonceId } from 'common/src/vtj/browser/browser-nonce'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { IsoDatetimeToUiDate } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import { styled } from 'styled-components'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const YhteenvetoPage: React.FC<{
  formControls: TilintarkastusRouteFormControls
  form: TilintarkastusForm
}> = observer(({ formControls, form }) => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const onErrorRef = useRef<HTMLDivElement>(null)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [TILINTARKASTUS_ROUTES.YHTEENVETO]: _, ...formsWithoutYhteenveto } =
    formControls

  const printComponentRef = useRef<HTMLElement>(null)

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
    pageStyle:
      '@page { size: auto; margin: 20mm; } @media print { body { -webkit-print-color-adjust: exact; print-color-adjust: exact; } }',
    nonce: getWindowNonceId(),
  })

  const { vaatimus } = getTilintarkastusStore()

  return (
    <>
      <FormProvider {...form}>
        <ResponsiveBorderedContentBlock
          data-test-id={mkYhteenvetoTestId('page-container')}
        >
          <Block forwardedRef={printComponentRef}>
            <HeaderBlock>
              <PageStepHeading
                heading={t('yhteenvetoJaLahetys')}
                steps={TILINTARKASTUS_STEPS}
              />
              <PrintButton
                onClick={handlePrint}
                variant="secondaryNoBorder"
                icon={<IconPrint />}
              >
                {t('tulostaYhteenveto')}
              </PrintButton>
            </HeaderBlock>

            {!vaatimus.lahetysBlockedUntil && (
              <FormUncompletedStepsSummary
                forms={formsWithoutYhteenveto}
                ref={onErrorRef}
              />
            )}

            <MultiParagraphText
              text={
                isOmaisuusluettelo()
                  ? t('yhteenvetoPageInfo1_OMAISUUSLUETTELO')
                  : t('yhteenvetoPageInfo1_MUUT')
              }
            />

            <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />

            <Perustiedot
              showValtakirja
              showDueDateAlerts={!vaatimus.lahetysBlockedUntil}
            />
            <DividerLine mb={verticalMarginToken} mt={verticalMarginToken} />
            <Yhteenveto />
            <DividerLine mb={verticalMarginToken} />
          </Block>
          {isOmaisuusluettelo() && (
            <Block>
              <Heading variant="h3" mb={verticalMarginToken}>
                {t('omaisuusluettelossaIlmoitettujenTietojenOikeellisuus')}
              </Heading>
              <FormCheckboxInput
                data-test-id={mkYhteenvetoTestId('vahvistan-tiedot-checkbox')}
                labelText={t('omaisuusluetteloIlmoitanTiedotOikeaksi')}
                requiredLabel={t('sinunOnHyvaksyttavaEhdotEnnenKuinVoitJatkaa')}
                updateValue={() => undefined}
                defaultValue={false}
                required
              />
              <DividerLine mb={verticalMarginToken} mt={verticalMarginToken} />
            </Block>
          )}
          <InlineAlert labelText={t('otaYhteenvetoTalteen')}>
            <Paragraph mb={verticalMarginToken}>
              {t('yhteenvetoPrintInfoText')}
            </Paragraph>
            <Button
              onClick={handlePrint}
              variant="secondary"
              icon={<IconPrint />}
            >
              {t('tulostaYhteenveto')}
            </Button>
          </InlineAlert>

          <DividerLine mb={verticalMarginToken} mt={verticalMarginToken} />

          {vaatimus.lahetysBlockedUntil && (
            <FocusableDiv
              ref={onErrorRef}
              tabIndex={-1}
              data-test-id={mkYhteenvetoTestId('lahetys-blocked-container')}
            >
              <InlineAlert status="error" mb={verticalMarginToken}>
                <Paragraph>
                  {vaatimus.asiaType === AsiointiType.PAATOSTILI
                    ? t('voitLahettaaKunTilikausiPaattynyt_PAATOSTILI', {
                        pvm: IsoDatetimeToUiDate(vaatimus.lahetysBlockedUntil),
                      })
                    : t('voitLahettaaKunTilikausiPaattynyt_VUOSITILI', {
                        pvm: IsoDatetimeToUiDate(vaatimus.lahetysBlockedUntil),
                      })}
                </Paragraph>
              </InlineAlert>
            </FocusableDiv>
          )}

          <FormSubmitBar
            forms={formsWithoutYhteenveto}
            onErrorRef={onErrorRef}
          />
        </ResponsiveBorderedContentBlock>
      </FormProvider>
    </>
  )
})

export default YhteenvetoPage

const HeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
`

const PrintButton = styled(Button)`
  align-self: baseline;
`
