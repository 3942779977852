import * as DateFns from 'date-fns/fp'
import * as DateFnsTz from 'date-fns-tz'

export type LongIsoDateString =
  `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`

export const IsoDatetimeToUiDate = (
  date: LongIsoDateString | null | undefined
): string => {
  if (date) {
    // Tilikauden alku, loppu, saapumispvm ja määräpvm aina suomen ajassa
    return DateFnsTz.formatInTimeZone(date, 'Europe/Helsinki', 'd.M.yyyy')
  }
  return ''
}

export const IsoDatetimeToUiDatetime = (
  date: LongIsoDateString | null | undefined
): string => {
  if (date) {
    const parsedDate = DateFns.parseISO(date)
    return DateFns.format('d.M.yyyy HH:mm', parsedDate)
  }
  return ''
}

export const displayDateToIsoString = (
  value: string | null
): LongIsoDateString => {
  const date = DateFns.parse(new Date(), 'd.M.yyyy', value)
  return date.toISOString() as LongIsoDateString
}
