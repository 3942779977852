import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { KyllaEiTuntematon } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'

const Testamentti: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTili()
  const laadittu = tili.testamentti?.laadittu
  const rajoittaviaEhtoja = tili.testamentti?.rajoittaviaEhtoja

  return (
    <>
      <Heading variant="h3">{t('henkilonLaatimaTestamentti')}</Heading>
      <Heading variant="h5" as="h4" mt="xs">
        {t('onkoHenkiloLaatinutTestamentin')}
      </Heading>
      <Paragraph>
        {laadittu === KyllaEiTuntematon.KYLLA
          ? t('kylla')
          : laadittu === KyllaEiTuntematon.TUNTEMATON
          ? t('enTieda')
          : t('ei')}
      </Paragraph>
      {laadittu === KyllaEiTuntematon.KYLLA && (
        <Block mt="xs">
          <Heading variant="h5" as="h4">
            {t('testamentinSisalto')}
          </Heading>
          <Paragraph>
            {rajoittaviaEhtoja === KyllaEiTuntematon.KYLLA
              ? t('sisaltaaRajoittaviaEhtoja')
              : rajoittaviaEhtoja === KyllaEiTuntematon.EI
              ? t('eiSisallaRajoittaviaEhtoja')
              : rajoittaviaEhtoja === KyllaEiTuntematon.TUNTEMATON
              ? t('tuntematon')
              : undefined}
          </Paragraph>
        </Block>
      )}
    </>
  )
})

export default Testamentti
