import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { styled } from 'styled-components'
import { Button, Heading, IconPreview } from 'suomifi-ui-components'
import TiliStatusChip from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/TiliStatusChip'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TulostaYhteenvetoModal } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/TulostaYhteenvetoModal'
import { useTranslation } from 'react-i18next'
import { mkTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'

export const TopHeadingContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
`

export const TopHeadingSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
`

export const TopHeading: React.FC<{
  showEsikatseleYhteenveto?: boolean
}> = observer(({ showEsikatseleYhteenveto = true }) => {
  const { vaatimus, draft } = getTilintarkastusStore()
  const [t] = useTranslation()
  const [yhteenvetoModalVisible, setYhteenvetoModalVisible] = useState(false)
  const headingTranslationString = {
    OMAISUUSLUETTELO: 'omaisuusluettelo',
    PAATOSTILI: 'paatostili',
    VUOSITILI: 'vuositili',
  }
  return (
    <TopHeadingContainer data-test-id={mkTestId('top-heading-container')}>
      <Heading variant="h1">
        {t(headingTranslationString[vaatimus.asiaType])}
      </Heading>
      <TopHeadingSubContainer>
        {draft && !vaatimus.sentDate && (
          <TiliStatusChip vaatimus={vaatimus} draft={draft} />
        )}
        {showEsikatseleYhteenveto && (
          <>
            <EsikatseleYhteenvetoaButton
              onClick={() => setYhteenvetoModalVisible(true)}
              variant="secondaryNoBorder"
              icon={<IconPreview />}
            >
              {t('esikatseleYhteenvetoa')}
            </EsikatseleYhteenvetoaButton>
            <TulostaYhteenvetoModal
              setVisible={setYhteenvetoModalVisible}
              visible={yhteenvetoModalVisible}
              vaatimus={vaatimus}
            />
          </>
        )}
      </TopHeadingSubContainer>
    </TopHeadingContainer>
  )
})

const EsikatseleYhteenvetoaButton = styled(Button)`
  margin-left: 10px;
`
