import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  AsiaPersonRole,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
  AsiaPersonType,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { OrganizationType } from 'asia-common/src/vtj/koodisto/organization-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import {
  edunvalvonta50VuottaRetention,
  edunvalvontaJulkinenEiHenkilotietojaRestriction,
  edunvalvontaJulkinenHenkilotietojaRestriction,
  edunvalvontaOsittainSalassaPidettava100VuottaKohta23ErityisiaHenkilotietojaRestriction,
  edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
  edunvalvontaPysyvaRetention,
  edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava100VuottaKohta28ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
  edunvalvontaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/common-edunvalvontavaltuutus-metadata'

export const edunvalvojanMaaraaminenIlmoituksellaMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_EDUNVALVONNAN_TARPEESSA_OLEVASTA_HENKILOSTA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['f5705723-c2cf-4784-9a35-65935e194ac7'],
      defaultToivoAsiakirjaTypeId: 'f5705723-c2cf-4784-9a35-65935e194ac7',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KARAJAOIKEUDEN_LAUSUMAPYYNTO_LIITTEINEEN_UUDESSA_ASIASSA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['9e96be05-4c79-4ed1-8505-48554ed96e97'],
      defaultToivoAsiakirjaTypeId: '9e96be05-4c79-4ed1-8505-48554ed96e97',
    },
    {
      asiakirjaTypeId: AsiakirjaType.EDUNVALVONTAAN_ESITETYN_LAUSUMA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['009e944a-3931-4e62-83c4-e93b40908bc6'],
      defaultToivoAsiakirjaTypeId: '009e944a-3931-4e62-83c4-e93b40908bc6',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['43702231-1281-42fb-a0cc-85f22432568d'],
      defaultToivoAsiakirjaTypeId: '43702231-1281-42fb-a0cc-85f22432568d',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUUN_HENKILON_LAUSUMA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['cd45a73b-9801-40b0-918a-a93cc166288c'],
      defaultToivoAsiakirjaTypeId: 'cd45a73b-9801-40b0-918a-a93cc166288c',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LAUSUNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['ebb308d2-e28a-443d-82df-0e1b44e138f9'],
      defaultToivoAsiakirjaTypeId: 'ebb308d2-e28a-443d-82df-0e1b44e138f9',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SUOSTUMUS_EDUNVALVOJAN_TEHTAVAAN,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['fba2885e-cd65-4847-ac89-8671818df4dd'],
      defaultToivoAsiakirjaTypeId: 'fba2885e-cd65-4847-ac89-8671818df4dd',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['a78c1b32-f0af-469c-ad32-dcca3f15a986'],
      defaultToivoAsiakirjaTypeId: 'a78c1b32-f0af-469c-ad32-dcca3f15a986',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LIITE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c989deed-950f-41fd-be46-df2853fbb5a6'],
      defaultToivoAsiakirjaTypeId: 'c989deed-950f-41fd-be46-df2853fbb5a6',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYSPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['52e68a37-f760-408e-afde-79fe8947dc89'],
      defaultToivoAsiakirjaTypeId: '52e68a37-f760-408e-afde-79fe8947dc89',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYS,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvontaPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['1385fe96-ef82-43cf-ba06-9cef06e3447e'],
      defaultToivoAsiakirjaTypeId: '1385fe96-ef82-43cf-ba06-9cef06e3447e',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KUULEMISKIRJE_EDUNVALVONTAAN_ESITETYLLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['13af82f8-31be-489b-ba8a-8e39ae374e7b'],
      defaultToivoAsiakirjaTypeId: '13af82f8-31be-489b-ba8a-8e39ae374e7b',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KUULEMISKIRJE_MUULLE_HENKILOLLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['0c2f7f0d-571e-480e-80a6-ee03b4e12d2a'],
      defaultToivoAsiakirjaTypeId: '0c2f7f0d-571e-480e-80a6-ee03b4e12d2a',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTOPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['879ac280-70e2-4c7d-956f-8cce08ecd1d0'],
      defaultToivoAsiakirjaTypeId: '879ac280-70e2-4c7d-956f-8cce08ecd1d0',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_LAUSUNTOPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['539c0c4d-b450-4915-9186-c02d4d67487f'],
      defaultToivoAsiakirjaTypeId: '539c0c4d-b450-4915-9186-c02d4d67487f',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['a40022da-2f65-4995-b100-e3341bbdfc77'],
      defaultToivoAsiakirjaTypeId: 'a40022da-2f65-4995-b100-e3341bbdfc77',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TODISTUS_ULOSOTTOREKISTERISTA,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c552389c-9e87-425a-80e7-0c175ec7364e'],
      defaultToivoAsiakirjaTypeId: 'c552389c-9e87-425a-80e7-0c175ec7364e',
    },
    {
      asiakirjaTypeId: AsiakirjaType.RIKOSREKISTERIOTE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta28ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['a5877d1f-ba4c-4c5a-8980-deab747d7cd9'],
      defaultToivoAsiakirjaTypeId: 'a5877d1f-ba4c-4c5a-8980-deab747d7cd9',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TILIEN_SALDOT_JA_TILITAPAHTUMAT,
      restrictionMetadata: edunvalvontaSalassaPidettava25VuottaKohta23ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['8a577444-3b8b-4aa1-92ac-17eae3cb282e'],
      defaultToivoAsiakirjaTypeId: '8a577444-3b8b-4aa1-92ac-17eae3cb282e',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['73287970-881a-48dd-8e3b-cbe9c3158c42'],
      defaultToivoAsiakirjaTypeId: '73287970-881a-48dd-8e3b-cbe9c3158c42',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['55140d33-e475-4d98-90de-19b34f1c2fd2'],
      defaultToivoAsiakirjaTypeId: '55140d33-e475-4d98-90de-19b34f1c2fd2',
    },
    {
      asiakirjaTypeId: AsiakirjaType.HAKEMUS_KARAJAOIKEUDELLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['f281fb89-06a3-4174-b42e-68083aad3b6d'],
      defaultToivoAsiakirjaTypeId: 'f281fb89-06a3-4174-b42e-68083aad3b6d',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TAYDENNYS_KARAJAOIKEUDELLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['3b414a19-8996-4518-a171-af975cdab381'],
      defaultToivoAsiakirjaTypeId: '3b414a19-8996-4518-a171-af975cdab381',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAUSUMA_KARAJAOIKEUDELLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['9246acd7-05f4-4abc-a528-f8fab3fd2252'],
      defaultToivoAsiakirjaTypeId: '9246acd7-05f4-4abc-a528-f8fab3fd2252',
    },
    {
      asiakirjaTypeId: AsiakirjaType.HAKEMUKSEN_PERUUTUS_KARAJAOIKEUDELLE,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['7fe2a955-8f6f-4a68-bae7-307468d01493'],
      defaultToivoAsiakirjaTypeId: '7fe2a955-8f6f-4a68-bae7-307468d01493',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_KUOLEMASTA_KARAJAOIKEUDELLE,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['410336ba-174d-49f5-9089-94d43bdf0f29'],
      defaultToivoAsiakirjaTypeId: '410336ba-174d-49f5-9089-94d43bdf0f29',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA_KARAJAOIKEUTEEN,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['5d6b6443-17e6-4f1d-af92-f6fa5f08c20f'],
      defaultToivoAsiakirjaTypeId: '5d6b6443-17e6-4f1d-af92-f6fa5f08c20f',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KARAJAOIKEUDEN_TAYDENNYSPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['a9f4c61e-d462-48d0-b19a-41028c49f676'],
      defaultToivoAsiakirjaTypeId: 'a9f4c61e-d462-48d0-b19a-41028c49f676',
    },
    {
      asiakirjaTypeId: AsiakirjaType.KARAJAOIKEUDEN_LAUSUMAPYYNTO,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['f0e42ca1-58f8-49bf-8e7f-18d602d24031'],
      defaultToivoAsiakirjaTypeId: 'f0e42ca1-58f8-49bf-8e7f-18d602d24031',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ISTUNTOKUTSU,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['3b43e660-4983-4ffb-84af-7358df893caf'],
      defaultToivoAsiakirjaTypeId: '3b43e660-4983-4ffb-84af-7358df893caf',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_RATKAISUN_ANTAMISESTA,
      restrictionMetadata: edunvalvontaJulkinenEiHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['c0fe9cbc-5c51-4411-9a07-79b622035042'],
      defaultToivoAsiakirjaTypeId: 'c0fe9cbc-5c51-4411-9a07-79b622035042',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_TUOMIOISTUIMEN_LAATIMA_ASIAKIRJA,
      restrictionMetadata: edunvalvontaSalassaPidettava100VuottaKohta23Kohta25ErityisiaHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['5d0461bd-d754-4c8b-854f-0b6d80103fbd'],
      defaultToivoAsiakirjaTypeId: '5d0461bd-d754-4c8b-854f-0b6d80103fbd',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TUOMIOISTUIMEN_PAATOS,
      restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['d55bfa26-fca0-4538-9f00-1b8fdc4fb7d6'],
      defaultToivoAsiakirjaTypeId: 'd55bfa26-fca0-4538-9f00-1b8fdc4fb7d6',
    },
    {
      asiakirjaTypeId: AsiakirjaType.TUOMIOISTUIMEN_VALIAIKAISMAARAYS,
      restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23Kohta25HenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['28cdd2aa-ca2a-4b8c-95a2-7ccdf9442308'],
      defaultToivoAsiakirjaTypeId: '28cdd2aa-ca2a-4b8c-95a2-7ccdf9442308',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OTE_HOLHOUSASIOIDEN_REKISTERISTA,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['3d9e50f4-ed54-474d-8409-306118cb7ff3'],
      defaultToivoAsiakirjaTypeId: '3d9e50f4-ed54-474d-8409-306118cb7ff3',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_ASIAN_JATTAMISESTA_SILLENSA,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['47bb9b1a-8124-44b0-af68-ebdb82fa6c52'],
      defaultToivoAsiakirjaTypeId: '47bb9b1a-8124-44b0-af68-ebdb82fa6c52',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VIREILLETULOILMOITUS,
      restrictionMetadata: edunvalvontaJulkinenHenkilotietojaRestriction,
      retentionMetadata: edunvalvonta50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['d1328ccd-edca-4ad6-b5b3-d2e7569bb7ea'],
      defaultToivoAsiakirjaTypeId: 'd1328ccd-edca-4ad6-b5b3-d2e7569bb7ea',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.PAKOLLISIA_ASIAKIRJOJA_PUUTTUU,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREHDITTY,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_TAYDENNYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.TAYDENNYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_KUULEMISTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.KUULEMISEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA,
      postDueDateHuomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
    },
    {
      huomioTypeId: HuomioType.MAARAAIKA_YLITTYNYT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.EDUNVALVOJAN_TIEDOT_SAAPUNEET,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.HOLLE_OTTEEN_LAHETYS,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [AsiaPersonRole.EDUNVALVOJAKSI_EHDOTETTU],
    organizationTypeIds: [
      OrganizationType.OSTOPALVELU_EDUNVALVONTATOIMISTO,
      OrganizationType.VALTION_EDUNVALVONTATOIMISTO,
    ],
  },
  personRoleMetadata: [
    AsiaPersonRole.EDUNVALVONTAAN_ESITETTY,
    AsiaPersonRole.ILMOITTAJA,
    AsiaPersonRole.EDUNVALVOJAKSI_EHDOTETTU,
    AsiaPersonRole.MUU_HENKILO,
  ],
  personTypeMetadata: [
    AsiaPersonType.IHMINEN,
    AsiaPersonType.ORGANISAATIO,
    AsiaPersonType.AMMATTIHENKILO,
    AsiaPersonType.YRITYS,
  ],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.MYONTEINEN_PAATOS,
    manualRatkaisuTypeIds: [
      RatkaisuType.MYONTEINEN_PAATOS,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.TUTKIMATTAJATTAMISPAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.YHDISTETTY_TOISEEN_ASIAAN,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: false,
    specifierTypeIds: [SpecifierType.KV_ASIA],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [ViestintaType.EDVARD],
      prioritizedLaadittuAsiakirjaTypeIds: [
        AsiakirjaType.ILMOITUS_RATKAISUN_ANTAMISESTA,
        AsiakirjaType.ILMOITUS_ASIAN_JATTAMISESTA_SILLENSA,
      ],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [
        AsiakirjaType.TAYDENNYSPYYNTO,
        AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA,
      ],
      prioritizedSaapunutAsiakirjaTypeIds: [
        AsiakirjaType.TAYDENNYS,
        AsiakirjaType.EDUNVALVONTAAN_ESITETYN_LAUSUMA,
        AsiakirjaType.LAAKARINLAUSUNTO,
        AsiakirjaType.MUUN_HENKILON_LAUSUMA,
        AsiakirjaType.PERUKIRJA,
        AsiakirjaType.MUU_LIITE,
      ],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.TAYDENNYS,
    },
    {
      toimenpideTypeId: ToimenpideType.KUULEMINEN,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [
        AsiakirjaType.KUULEMISKIRJE_EDUNVALVONTAAN_ESITETYLLE,
        AsiakirjaType.KUULEMISKIRJE_MUULLE_HENKILOLLE,
      ],
      prioritizedSaapunutAsiakirjaTypeIds: [
        AsiakirjaType.EDUNVALVONTAAN_ESITETYN_LAUSUMA,
        AsiakirjaType.MUUN_HENKILON_LAUSUMA,
        AsiakirjaType.LAAKARINLAUSUNTO,
        AsiakirjaType.MUU_LAUSUNTO,
        AsiakirjaType.PERUKIRJA,
        AsiakirjaType.TODISTUS_ULOSOTTOREKISTERISTA,
        AsiakirjaType.RIKOSREKISTERIOTE,
        AsiakirjaType.TILIEN_SALDOT_JA_TILITAPAHTUMAT,
        AsiakirjaType.SUOSTUMUS_EDUNVALVOJAN_TEHTAVAAN,
        AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
      ],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
    },
    {
      toimenpideTypeId: ToimenpideType.SELVITYSPYYNTO,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSPYYNTO, AsiakirjaType.MUU_VIRASTON_LAATIMA_ASIAKIRJA],
      prioritizedSaapunutAsiakirjaTypeIds: [
        AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
        AsiakirjaType.LAAKARINLAUSUNTO,
        AsiakirjaType.MUU_LAUSUNTO,
        AsiakirjaType.PERUKIRJA,
        AsiakirjaType.TODISTUS_ULOSOTTOREKISTERISTA,
        AsiakirjaType.RIKOSREKISTERIOTE,
        AsiakirjaType.TILIEN_SALDOT_JA_TILITAPAHTUMAT,
        AsiakirjaType.SUOSTUMUS_EDUNVALVOJAN_TEHTAVAAN,
      ],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
    },
    {
      toimenpideTypeId: ToimenpideType.LAUSUNTOPYYNTO,
      internalViestintaTypeIds: [ViestintaType.TOIVO_POSTITUS, ViestintaType.MANUAALINEN],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.LAAKARINLAUSUNTOPYYNTO, AsiakirjaType.MUU_LAUSUNTOPYYNTO],
      prioritizedSaapunutAsiakirjaTypeIds: [
        AsiakirjaType.LAAKARINLAUSUNTO,
        AsiakirjaType.MUU_LAUSUNTO,
        AsiakirjaType.MUU_VIRASTON_SAAMA_ASIAKIRJA,
      ],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.LAAKARINLAUSUNTO,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA, Vireilletulokanava.ELOMAKE_KANSALAINEN],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.VALMISTELTAVANA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMISTELTAVANA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: AsiaStatus.PAATETTAVANA_KARAJAOIKEUDESSA,
    },
    {
      asiaStatusId: AsiaStatus.PAATETTAVANA_KARAJAOIKEUDESSA,
      toimenpideTypeId: InternalToimenpideType.PAATOKSENTEKO,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: AsiaStatus.PAATOS_SAAPUNUT,
    },
    {
      asiaStatusId: AsiaStatus.PAATOS_SAAPUNUT,
      toimenpideTypeId: InternalToimenpideType.PAATOKSENTEKO,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: AsiaStatus.VALMISTELTAVANA,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  emailMetadata: {
    fromAddress: 'noreply@dvv.fi',
    useUserEmailIfPossible: true,
  },
  retentionMetadata: edunvalvontaPysyvaRetention,
  restrictionMetadata: edunvalvontaOsittainSalassaPidettava100VuottaKohta23ErityisiaHenkilotietojaRestriction,
}
