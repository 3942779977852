import _ from 'lodash'
import keyMirror from 'keymirror'

export const RondoAsiakasryhma = keyMirror({
  KUNNAT: null,
  KOTITALOUDET: null,
  ELINKEINOELAMA: null,
  VALTIO: null,
  MUUT: null,
})
export type RondoAsiakasryhmaId = (typeof RondoAsiakasryhma)[keyof typeof RondoAsiakasryhma]

export const LaskutusStatus = keyMirror({
  ODOTTAA_LAHETYSTA: null,
  LAHETETTY: null,
  POISTETTU: null,
})
export type LaskutusStatusId = (typeof LaskutusStatus)[keyof typeof LaskutusStatus]
export const allLaskutusStatusIds = _.keys(LaskutusStatus) as LaskutusStatusId[]

export const Laskulaji = keyMirror({
  NIMENMUUTOS: null,
  PERHE_PERINTO_JA_VARALLISUUS: null,
  VTJ_OTTEET_ULKOMAILLE: null,
  HOLHOUS_TILIT: null,
  HOLHOUS_EVM: null,
  HOLHOUS_EVV: null,
  HOLHOUS_LUVAT: null,
})
export type LaskulajiId = (typeof Laskulaji)[keyof typeof Laskulaji]

export const LaskulajiRondoKoodi: { [key in LaskulajiId]: number } = {
  [Laskulaji.NIMENMUUTOS]: 41,
  [Laskulaji.PERHE_PERINTO_JA_VARALLISUUS]: 51,
  [Laskulaji.VTJ_OTTEET_ULKOMAILLE]: 91,
  [Laskulaji.HOLHOUS_TILIT]: 319,
  [Laskulaji.HOLHOUS_EVM]: 328,
  [Laskulaji.HOLHOUS_EVV]: 329,
  [Laskulaji.HOLHOUS_LUVAT]: 339,
}

export type LaskutusMetadata = {
  laskulaji: LaskulajiId
}
