import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  AsiaPersonRole,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
  AsiaPersonType,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import {
  muutot4VuottaRetention,
  muutotOsittainSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
  muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/muutot/common-muutot-metadata'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { ViestintaType } from 'asia-common/src/vtj/koodisto/viestinta-enums'

export const kotimaanMuuttoMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.MUUTTOILMOITUS,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['dce2e2a1-c0e4-4794-8ccf-55a59bc813c5'],
      defaultToivoAsiakirjaTypeId: 'dce2e2a1-c0e4-4794-8ccf-55a59bc813c5',
    },
    {
      asiakirjaTypeId: AsiakirjaType.VALTAKIRJA,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['925f8976-79e7-48cb-b00f-2964778c4f1a'],
      defaultToivoAsiakirjaTypeId: '925f8976-79e7-48cb-b00f-2964778c4f1a',
    },

    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSPYYNTO,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['5f7d5d3c-988e-42e3-8f14-e2dd78ad60d1'],
      defaultToivoAsiakirjaTypeId: '5f7d5d3c-988e-42e3-8f14-e2dd78ad60d1',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYS,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['90f507d7-d7a6-4336-a6e1-83e240b06400'],
      defaultToivoAsiakirjaTypeId: '90f507d7-d7a6-4336-a6e1-83e240b06400',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAUSUMAPYYNTO,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['795029eb-0531-4dde-bacb-bca9c011dd63'],
      defaultToivoAsiakirjaTypeId: '795029eb-0531-4dde-bacb-bca9c011dd63',
    },
    {
      asiakirjaTypeId: AsiakirjaType.LAUSUMA,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['71d4a4d0-a341-4ebf-83c8-39008865d4af'],
      defaultToivoAsiakirjaTypeId: '71d4a4d0-a341-4ebf-83c8-39008865d4af',
    },
    {
      asiakirjaTypeId: AsiakirjaType.ASIAKKAALTA_SAAPUNUT_TAYDENNYS,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['5429d5c4-7d71-4731-958d-1af157c9e05e'],
      defaultToivoAsiakirjaTypeId: '5429d5c4-7d71-4731-958d-1af157c9e05e',
    },
    {
      asiakirjaTypeId: AsiakirjaType.SAATE_TAI_LAHETE,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['e680d143-5e6f-42a3-a0de-d0be4dd41beb'],
      defaultToivoAsiakirjaTypeId: 'e680d143-5e6f-42a3-a0de-d0be4dd41beb',
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['94282cdd-542d-4c9f-a8c3-735c1a683170'],
      defaultToivoAsiakirjaTypeId: '94282cdd-542d-4c9f-a8c3-735c1a683170',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS_MUUTTOASIASSA,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['7f43250d-9dce-40c5-86e1-3dae9c7ef3db'],
      defaultToivoAsiakirjaTypeId: '7f43250d-9dce-40c5-86e1-3dae9c7ef3db',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OIKAISUVAATIMUSOHJE,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['628de27b-9259-442e-84d1-df720fa33dad'],
      defaultToivoAsiakirjaTypeId: '628de27b-9259-442e-84d1-df720fa33dad',
    },
    {
      asiakirjaTypeId: AsiakirjaType.DVVN_ILMOITUS,
      restrictionMetadata: muutotSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
      retentionMetadata: muutot4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['bdf4905f-5c11-419e-bdcc-a6ccfb333897'],
      defaultToivoAsiakirjaTypeId: 'bdf4905f-5c11-419e-bdcc-a6ccfb333897',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.ASIAKKAALLA_ON_MUUTTOESTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KASITTELY_VAATII_LISASELVITYKSEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.PAKOLLISIA_ASIAKIRJOJA_PUUTTUU,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.SIIRTO_KV_ASIOIDEN_JONOON,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.VTJ_TALLENNUS_EPAONNISTUI,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.MUUTTAJA, AsiaPersonRole.KANSSAMUUTTAJA, AsiaPersonRole.ILMOITTAJA],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.REKISTERIIN_MERKINTA,
    manualRatkaisuTypeIds: [
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.SIIRRETTY_TOISEEN_ASIANHALLINTAAN,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: true,
    mandatorySpecifier: true,
    specifierTypeIds: [
      SpecifierType.VAKINAINEN_MUUTTO,
      SpecifierType.TILAPAINEN_MUUTTO,
      SpecifierType.PUOLESTA_ILMOITETTU_MUUTTO,
      SpecifierType.POSTIOSOITTEEN_MUUTOS,
    ],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TIEDOKSIANTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [
        AsiakirjaType.PAATOS_MUUTTOASIASSA,
        AsiakirjaType.OIKAISUVAATIMUSOHJE,
        AsiakirjaType.DVVN_ILMOITUS,
      ],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSPYYNTO, AsiakirjaType.SAATE_TAI_LAHETE],
      prioritizedSaapunutAsiakirjaTypeIds: [
        AsiakirjaType.SELVITYS,
        AsiakirjaType.VALTAKIRJA,
        AsiakirjaType.ASIAKKAALTA_SAAPUNUT_TAYDENNYS,
        AsiakirjaType.MUU_ASIAKIRJA,
      ],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.ASIAKKAALTA_SAAPUNUT_TAYDENNYS,
    },
    {
      toimenpideTypeId: ToimenpideType.SELVITYSPYYNTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSPYYNTO, AsiakirjaType.SAATE_TAI_LAHETE],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYS, AsiakirjaType.MUU_ASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYS,
    },
    {
      toimenpideTypeId: ToimenpideType.LAUSUNTOPYYNTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.LAUSUMAPYYNTO, AsiakirjaType.SAATE_TAI_LAHETE],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.LAUSUMA, AsiakirjaType.MUU_ASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.LAUSUMA,
    },
    {
      toimenpideTypeId: ToimenpideType.LISATIETOPYYNTO,
      internalViestintaTypeIds: [
        ViestintaType.MANUAALINEN,
        ViestintaType.TOIVO_POSTITUS,
        ViestintaType.TURVASAHKOPOSTI,
      ],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [AsiakirjaType.SELVITYSPYYNTO, AsiakirjaType.SAATE_TAI_LAHETE],
      prioritizedSaapunutAsiakirjaTypeIds: [AsiakirjaType.SELVITYS, AsiakirjaType.MUU_ASIAKIRJA],
      automationSaapunutAsiakirjaTypeId: AsiakirjaType.SELVITYS,
    },
  ],
  vireilletulokanavaMetadata: [
    Vireilletulokanava.ELSA,
    Vireilletulokanava.POSTIN_VERKKOLOMAKE,
    Vireilletulokanava.POSTIN_PAPERILOMAKE,
  ],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: AsiaStatus.ODOTTAA_MUUTTOPAIVAA,
    },
    {
      asiaStatusId: AsiaStatus.ODOTTAA_MUUTTOPAIVAA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: AsiaStatus.KASITTELYSSA,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  retentionMetadata: muutot4VuottaRetention,
  restrictionMetadata: muutotOsittainSalassaPidettava100VuottaK24K25K31K32HenkilotietojaRestriction,
  emailMetadata: {
    fromAddress: 'muuttoneuvonta@dvv.fi',
    useUserEmailIfPossible: true,
  },
}
