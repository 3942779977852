import {
  AsiaTypeId,
  AsiaType,
  AsiaPersonRoleId,
  RatkaisuMetadata,
  AsiaPersonTypeId,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { AsiakirjaMetadata, DefaultAsiakirjaTypeMetadata } from 'asia-common/src/vtj/koodisto/asiakirja-metadata'
import { muuLupaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/luvat/muu-lupa-metadata'
import { myyntiLupaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/luvat/myyntilupa-metadata'
import { perinnonjakoMetadata } from 'asia-common/src/vtj/koodisto/asia-type/luvat/perinnonjako-metadata'
import { etunimenmuutosMetadata } from 'asia-common/src/vtj/koodisto/asia-type/nimiasiat/etunimenmuutos-metadata'
import { sukunimenmuutosPaluuAiempaanMetadata } from 'asia-common/src/vtj/koodisto/asia-type/nimiasiat/sukunimenmuutos-paluu-aiempaan-metadata'
import { sukunimiyhdistelmanMuutosMetadata } from 'asia-common/src/vtj/koodisto/asia-type/nimiasiat/sukunimiyhdistelman-muutos-metadata'
import { sukunimenmuutosLuokittelematonMetadata } from 'asia-common/src/vtj/koodisto/asia-type/nimiasiat/sukunimenmuutos-luokittelematon-metadata'
import { perhehakemusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/nimiasiat/perhehakemus-metadata'
import { asuinpaikkatodistusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/asuinpaikkatodistus-metadata'
import { avioliittotodistusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/avioliittotodistus-metadata'
import { elossaolotodistusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/elossaolotodistus-metadata'
import { siviilisaatytodistusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/siviilisaatytodistus-metadata'
import { syntymatodistusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/syntymatodistus-metadata'
import { vuositiliMetadata } from 'asia-common/src/vtj/koodisto/asia-type/tilintarkastus/vuositili-metadata'
import { paatostiliMetadata } from 'asia-common/src/vtj/koodisto/asia-type/tilintarkastus/paatostili-metadata'
import { ensitiliMetadata } from 'asia-common/src/vtj/koodisto/asia-type/tilintarkastus/ensitili-metadata'
import { omaisuusluetteloMetadata } from 'asia-common/src/vtj/koodisto/asia-type/tilintarkastus/omaisuusluettelo-metadata'
import { tunnustettuEnnenSyntymaaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/vanhemmuuden-vahvistaminen/tunnustettu-ennen-syntymaa-metadata'
import { tunnustettuSyntymanJalkeenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/vanhemmuuden-vahvistaminen/tunnustettu-syntyman-jalkeen-metadata'
import { vanhemmuudenKumoaminenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/vanhemmuuden-vahvistaminen/vanhemmuuden-kumoaminen-metadata'
import { kotikunnanRekisterointiMetadata } from 'asia-common/src/vtj/koodisto/asia-type/ulkomaalaisen-rekisterointi/kotikunnan-rekisterointi-metadata'
import { ensirekisterointiMetadata } from 'asia-common/src/vtj/koodisto/asia-type/ulkomaalaisen-rekisterointi/ensirekisterointi-metadata'
import { edunvalvontavaltuutuksenVahvistaminenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/edunvalvontavaltuutuksen-vahvistaminen-metadata'
import { selvitystehtavaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/selvitystehtava-metadata'
import { siviilisaadynRekisterointiKotimainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/siviilisaadyn-rekisterointi-kotimainen-metadata'
import { siviilisaadynRekisterointiUlkomainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/siviilisaadyn-rekisterointi-ulkomainen-metadata'
import { kuolemanRekisterointiKotimainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/kuoleman-rekisterointi-kotimainen-metadata'
import { kuolemanRekisterointiUlkomainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/kuoleman-rekisterointi-ulkomainen-metadata'
import { AsiakirjaTypeId } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { SpecifierMetadata } from 'asia-common/src/vtj/koodisto/specifier-enums'
import { OrganizationMetadata } from 'asia-common/src/vtj/koodisto/organization-enums'
import { todistusAsumistiedoistaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/todistus-asumistiedoista'
import { todistusHenkiloJaPerhesuhdetiedoistaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/todistus-henkilo-ja-perhesuhdetiedoista-metadata'
import { todistusKuolemastaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/todistus-kuolemasta-metadata'
import { HuomioMetadata, HuomioTypeId } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { VireilletulokanavaId } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { AsiaTypeAsiaStatusMetadata } from 'asia-common/src/vtj/koodisto/asia-status-metadata'
import { edunvalvojanMaaraaminenIlmoituksellaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/edunvalvojan-maaraaminen-ilmoituksella'
import { kotimaanMuuttoMetadata } from 'asia-common/src/vtj/koodisto/asia-type/muutot/kotimaan-muutto-metadata'
import { lapsenRekistrointiKotimainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/lapsen-rekisterointi-kotimainen'
import { lapsenRekistrointiUlkomainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/lapsen-rekisterointi-ulkomainen'
import { kansalaisuusJaUlkomaatiedotMetadata } from 'asia-common/src/vtj/koodisto/asia-type/ulkomaalaisen-rekisterointi/kansalaisuus-ja-ulkomaatiedot-metadata'
import { avioliitonEsteidenTutkintaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/avioliiton-esteiden-tutkinta-metadata'
import {
  allDueDateToimenpideTypeIds,
  getToimenpideHuomioMetadata,
  toimenpideHuomioMetadata,
  ToimenpideHuomioPhaseId,
  ToimenpideMetadata,
} from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import {
  getPublicityMetadata,
  PersonalDataClassId,
  PublicityClassId,
  RestrictionMetadata,
  SecurityClassId,
  SecurityPeriodId,
  SecurityReasonId,
} from 'asia-common/src/vtj/koodisto/restriction-enums'
import {
  RetentionId,
  RetentionMetadata,
  RetentionReasonId,
  RetentionToReason,
} from 'asia-common/src/vtj/koodisto/retention-enums'
import { EmailMetadata } from 'asia-common/src/vtj/koodisto/viestinta-enums'
import { edunvalvojanMaaraaminenHakemuksellaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/edunvalvonjan-maaraaminen-hakemuksella'
import { todistusOikeudestaMennaAvioliittoonUlkomaillaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/todistukset/todistus-oikeudesta-menna-avioliittoon-ulkomailla-metadata'
import { perukirjanOsakastietojenVahvistusMetadata } from 'asia-common/src/vtj/koodisto/asia-type/muut-asiat/perukirjan-osakastietojen-vahvistus-metadata'
import { ViranomainenId } from 'asia-common/src/vtj/koodisto/viranomainen-enums'
import { vihkimistodistustilausMetadata } from 'asia-common/src/vtj/koodisto/asia-type/muut-asiat/vihkimistodistustilaus-metadata'
import { LaskutusMetadata } from 'asia-common/src/vtj/koodisto/laskutus-enums'
import { ulkohenkilonYhdistaminenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/ulkomaalaisen-rekisterointi/ulkohenkilon-yhdistaminen-metadata'
import { muuHolhousasiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type/edunvalvontavaltuutus/muu-holhousasia-metadata'
import { vanhemmuusJaHuoltoUlkomainenMetadata } from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/vanhemmuus-ja-huolto-ulkomainen-metadata'

export type AsiaMetadata = {
  asiakirjaMetadata: AsiakirjaMetadata[]
  huomioMetadata: HuomioMetadata[]
  organizationMetadata: OrganizationMetadata
  personRoleMetadata: AsiaPersonRoleId[]
  personTypeMetadata: AsiaPersonTypeId[]
  ratkaisuMetadata: RatkaisuMetadata
  specifierMetadata: SpecifierMetadata
  toimenpideMetadata: ToimenpideMetadata[]
  vireilletulokanavaMetadata: VireilletulokanavaId[]
  asiaStatusMetadata: AsiaTypeAsiaStatusMetadata[]
  restrictionMetadata: RestrictionMetadata
  retentionMetadata: RetentionMetadata
  emailMetadata: EmailMetadata
  laskutusMetadata?: LaskutusMetadata
}

export type DefaultAsiaTypeMetadata = {
  viranomainenId: ViranomainenId
  securityClassId: SecurityClassId
  publicityClassId: PublicityClassId
  personalDataClassId: PersonalDataClassId
  securityPeriodId: SecurityPeriodId | null
  securityReasonIds: SecurityReasonId[]
  retentionId: RetentionId
  retentionReasonId: RetentionReasonId
}

export type AsiaTypeMetadata = {
  [k in AsiaTypeId]: AsiaMetadata
}

export const asiaTypeMetadata: AsiaTypeMetadata = {
  [AsiaType.ASUINPAIKKATODISTUS]: asuinpaikkatodistusMetadata,
  [AsiaType.AVIOLIITTOTODISTUS]: avioliittotodistusMetadata,
  [AsiaType.EDUNVALVONTAVALTUUTUKSEN_VAHVISTAMINEN]: edunvalvontavaltuutuksenVahvistaminenMetadata,
  [AsiaType.ENSIREKISTEROINTI]: ensirekisterointiMetadata,
  [AsiaType.ENSITILI]: ensitiliMetadata,
  [AsiaType.ELOSSAOLOTODISTUS]: elossaolotodistusMetadata,
  [AsiaType.ETUNIMENMUUTOS]: etunimenmuutosMetadata,
  [AsiaType.KOTIKUNNAN_REKISTEROINTI]: kotikunnanRekisterointiMetadata,
  [AsiaType.KUOLEMAN_REKISTEROINTI_KOTIMAINEN]: kuolemanRekisterointiKotimainenMetadata,
  [AsiaType.KUOLEMAN_REKISTEROINTI_ULKOMAINEN]: kuolemanRekisterointiUlkomainenMetadata,
  [AsiaType.MUU_LUPA]: muuLupaMetadata,
  [AsiaType.MYYNTILUPA]: myyntiLupaMetadata,
  [AsiaType.OMAISUUSLUETTELO]: omaisuusluetteloMetadata,
  [AsiaType.PAATOSTILI]: paatostiliMetadata,
  [AsiaType.PERHEHAKEMUS]: perhehakemusMetadata,
  [AsiaType.PERINNONJAKO]: perinnonjakoMetadata,
  [AsiaType.SELVITYSTEHTAVA]: selvitystehtavaMetadata,
  [AsiaType.SIVIILISAADYN_REKISTEROINTI_KOTIMAINEN]: siviilisaadynRekisterointiKotimainenMetadata,
  [AsiaType.SIVIILISAADYN_REKISTEROINTI_ULKOMAINEN]: siviilisaadynRekisterointiUlkomainenMetadata,
  [AsiaType.SIVIILISAATYTODISTUS]: siviilisaatytodistusMetadata,
  [AsiaType.SUKUNIMENMUUTOS_LUOKITTELEMATON]: sukunimenmuutosLuokittelematonMetadata,
  [AsiaType.SUKUNIMENMUUTOS_PALUU_AIEMPAAN]: sukunimenmuutosPaluuAiempaanMetadata,
  [AsiaType.SUKUNIMIYHDISTELMAN_MUUTOS]: sukunimiyhdistelmanMuutosMetadata,
  [AsiaType.SYNTYMATODISTUS]: syntymatodistusMetadata,
  [AsiaType.TUNNUSTETTU_ENNEN_SYNTYMAA]: tunnustettuEnnenSyntymaaMetadata,
  [AsiaType.TUNNUSTETTU_SYNTYMAN_JALKEEN]: tunnustettuSyntymanJalkeenMetadata,
  [AsiaType.VANHEMMUUDEN_KUMOAMINEN]: vanhemmuudenKumoaminenMetadata,
  [AsiaType.VUOSITILI]: vuositiliMetadata,
  [AsiaType.TODISTUS_ASUMISTIEDOISTA]: todistusAsumistiedoistaMetadata,
  [AsiaType.TODISTUS_HENKILO_JA_PERHESUHDETIEDOISTA]: todistusHenkiloJaPerhesuhdetiedoistaMetadata,
  [AsiaType.TODISTUS_KUOLEMASTA]: todistusKuolemastaMetadata,
  [AsiaType.EDUNVALVOJAN_MAARAAMINEN_ILMOITUKSELLA]: edunvalvojanMaaraaminenIlmoituksellaMetadata,
  [AsiaType.EDUNVALVOJAN_MAARAAMINEN_HAKEMUKSELLA]: edunvalvojanMaaraaminenHakemuksellaMetadata,
  [AsiaType.KOTIMAAN_MUUTTO]: kotimaanMuuttoMetadata,
  [AsiaType.LAPSEN_REKISTEROINTI_KOTIMAINEN]: lapsenRekistrointiKotimainenMetadata,
  [AsiaType.LAPSEN_REKISTEROINTI_ULKOMAINEN]: lapsenRekistrointiUlkomainenMetadata,
  [AsiaType.KANSALAISUUS_JA_ULKOMAATIEDOT]: kansalaisuusJaUlkomaatiedotMetadata,
  [AsiaType.AVIOLIITON_ESTEIDEN_TUTKINTA]: avioliitonEsteidenTutkintaMetadata,
  [AsiaType.TODISTUS_OIKEUDESTA_MENNA_AVIOLIITTOON_ULKOMAILLA]: todistusOikeudestaMennaAvioliittoonUlkomaillaMetadata,
  [AsiaType.PERUKIRJAN_OSAKASTIETOJEN_VAHVISTUS]: perukirjanOsakastietojenVahvistusMetadata,
  [AsiaType.VIHKIMISTODISTUSTILAUS]: vihkimistodistustilausMetadata,
  [AsiaType.ULKOHENKILON_YHDISTAMINEN]: ulkohenkilonYhdistaminenMetadata,
  [AsiaType.MUU_HOLHOUSASIA]: muuHolhousasiaMetadata,
  [AsiaType.VANHEMMUUS_JA_HUOLTO_ULKOMAINEN]: vanhemmuusJaHuoltoUlkomainenMetadata,
}

export const getSupportedHuomioTypeIds = (asiaTypeIds: AsiaTypeId[], getToimenpideHuomios = true): HuomioTypeId[] => [
  ...new Set(
    asiaTypeIds.flatMap((at) => [
      ...asiaTypeMetadata[at].huomioMetadata.map((h) => h.huomioTypeId),
      ...(getToimenpideHuomios ? getAsiaTypeToimenpideHuomioTypeIds(at) : []),
    ])
  ),
]

export const getAsiaTypeToimenpideHuomioTypeIds = (at: AsiaTypeId): HuomioTypeId[] =>
  asiaTypeMetadata[at].toimenpideMetadata.flatMap((t) => {
    const meta = getToimenpideHuomioMetadata(t.toimenpideTypeId)
    return meta ? Object.values(meta) : []
  })

export const getAllToimenpideHuomioPhaseTypeIds = (toimenpideHuomioPhase: ToimenpideHuomioPhaseId): HuomioTypeId[] =>
  allDueDateToimenpideTypeIds.flatMap((toimenpideTypeId) => {
    const candidate = toimenpideHuomioMetadata[toimenpideTypeId][toimenpideHuomioPhase]
    return candidate ? [candidate] : []
  })

export const getDefaultAsiaTypeMetadata = (asiaTypeId: AsiaTypeId): DefaultAsiaTypeMetadata => {
  const meta = asiaTypeMetadata[asiaTypeId]
  const restriction = meta.restrictionMetadata
  const publicityMetadata = getPublicityMetadata(restriction)
  const retention = meta.retentionMetadata
  const viranomainenId = restriction.defaultViranomainenId
  const retentionId = retention.defaultRetentionId
  return {
    viranomainenId,
    securityClassId: restriction.defaultSecurityClassId,
    publicityClassId: restriction.defaultPublicityClassId,
    personalDataClassId: publicityMetadata.defaultPersonalDataClassId,
    securityPeriodId: publicityMetadata.defaultSecurityPeriodId,
    securityReasonIds: publicityMetadata.defaultSecurityReasonIds,
    retentionId,
    retentionReasonId: RetentionToReason[retentionId],
  }
}

export const getAsiakirjaMetadata = (asiaTypeId: AsiaTypeId, asiakirjaTypeId: AsiakirjaTypeId): AsiakirjaMetadata => {
  return asiaTypeMetadata[asiaTypeId].asiakirjaMetadata.find(
    (a) => a.asiakirjaTypeId === asiakirjaTypeId
  ) as AsiakirjaMetadata
}

export const getDefaultAsiakirjaTypeMetadata = (
  asiaTypeId: AsiaTypeId,
  asiakirjaTypeId: AsiakirjaTypeId
): DefaultAsiakirjaTypeMetadata => {
  const meta = getAsiakirjaMetadata(asiaTypeId, asiakirjaTypeId)
  const restriction = meta.restrictionMetadata
  const publicityMetadata = getPublicityMetadata(restriction)
  const retention = meta.retentionMetadata
  const viranomainenId = restriction.defaultViranomainenId
  const retentionId = retention.defaultRetentionId
  return {
    originTypeId: meta.defaultOriginTypeId,
    viranomainenId,
    securityClassId: restriction.defaultSecurityClassId,
    publicityClassId: restriction.defaultPublicityClassId,
    personalDataClassId: publicityMetadata.defaultPersonalDataClassId,
    securityPeriodId: publicityMetadata.defaultSecurityPeriodId,
    securityReasonIds: publicityMetadata.defaultSecurityReasonIds,
    retentionId,
    retentionReasonId: RetentionToReason[retentionId],
    toivoAsiakirjaTypeId: meta.defaultToivoAsiakirjaTypeId ?? null,
  }
}
