import React from 'react'
import { styled, css } from 'styled-components'
import {
  contentMaxWidth,
  device,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { Block, BlockProps, suomifiDesignTokens } from 'suomifi-ui-components'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

export const centeredWidthLimited = css`
  width: 100%;
  max-width: ${contentMaxWidth};
  margin: 0 auto 0 auto;
`

export const CenteredWidthLimitedDiv = styled.div`
  ${centeredWidthLimited};

  padding: ${() => `${getPaddingY('mobile')} ${getPaddingX('mobile')}`};

  @media ${device.tablet} {
    padding: ${() => `${getPaddingY('tablet')} ${getPaddingX('tablet')}`};
  }
`

export const CenteredWidthLimitedSmallDiv = styled.div`
  ${centeredWidthLimited};

  padding: ${() => `${suomifiDesignTokens.spacing.s} ${getPaddingX('mobile')}`};

  @media ${device.tablet} {
    padding: ${() =>
      `${suomifiDesignTokens.spacing.s} ${getPaddingX('tablet')}`};
  }
`

const getPaddingX = (device: 'mobile' | 'tablet'): string | number => {
  return device === 'mobile'
    ? suomifiDesignTokens.spacing.s
    : suomifiDesignTokens.spacing.xl
}
const getPaddingY = (device: 'mobile' | 'tablet'): string | number => {
  return device === 'mobile'
    ? suomifiDesignTokens.spacing.l
    : suomifiDesignTokens.spacing.xl
}

export const ResponsiveBorderedContentBlock: React.FC<
  Omit<BlockProps, 'padding' | 'pt' | 'pr' | 'pb' | 'pl' | 'px' | 'py'>
> = (props) => {
  const isTablet = useDeviceContext().tablet

  return (
    <BorderedContentBlock
      {...props}
      py={isTablet ? 'xl' : 'm'}
      px={isTablet ? 'xl' : 's'}
    />
  )
}

export const BorderedContentBlock = styled(Block)`
  background-color: ${suomifiDesignTokens.colors.whiteBase};
  border: 1px solid ${suomifiDesignTokens.colors.depthLight1};
`

export const BlueBorderedContentBlock = styled(Block)`
  border: 1px solid ${suomifiDesignTokens.colors.depthLight1};
  background: ${suomifiDesignTokens.colors.highlightLight4};
`

export const BulletList = styled.ul`
  list-style-type: disc;
  padding-left: 30px;
`

export const focusOutline = css`
  &:focus {
    ${suomifiDesignTokens.focuses.boxShadowFocus};
    ${suomifiDesignTokens.focuses.highContrastFocus};
  }
`
export const FocusableDiv = styled.div`
  ${focusOutline};
`
