import React, { useId } from 'react'
import { FormModalProps } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import { useTranslation } from 'react-i18next'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { IsoDatetimeToUiDate } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { commonEuroWithDecimalsOrUndefinedValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { HintText, Text } from 'suomifi-ui-components'
import { styled } from 'styled-components'

type Props<
  T extends {
    arvoTilikaudenAlussaEur?: number
    arvoTilikaudenLopussaEur?: number
  }
> = FormModalProps<T> & {
  overrideHintText?: string
}

export const TilikaudenArvoFormInputs = <
  T extends {
    arvoTilikaudenAlussaEur?: number
    arvoTilikaudenLopussaEur?: number
  }
>({
  createDataTestId,
  entry,
  setEntry,
  overrideHintText,
}: Props<T>): React.ReactNode => {
  const [t] = useTranslation()
  const { vaatimus } = getTilintarkastusStore()
  const alkupvm = IsoDatetimeToUiDate(vaatimus.accountingPeriodStartDate)
  const hintTextId = useId()
  if (vaatimus.asiaType === AsiointiType.OMAISUUSLUETTELO) {
    return (
      <FormNumberInput
        data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
        labelText={t('arvoEuroina')}
        hintText={
          overrideHintText ??
          t('ilmoitaArvoTehtavanAlussa', { alkupvm }) +
            ' ' +
            t('ilmoitaArvoJosUseitaOmistajia')
        }
        digitMode="two"
        required
        value={entry.arvoTilikaudenLopussaEur}
        validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
        updateValue={(value) => {
          setEntry({
            ...entry,
            arvoTilikaudenLopussaEur: value,
          })
        }}
      />
    )
  } else {
    return (
      <fieldset>
        <legend>
          <Text smallScreen variant="bold">
            {t('tilikaudenArvo')}
          </Text>
        </legend>
        <HintTextV2 mb="m" id={hintTextId}>
          {overrideHintText ?? t('ilmoitaArvoJosUseitaOmistajia')}
        </HintTextV2>
        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
          labelText={t('arvoTilikaudenAlussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenAlussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) =>
            setEntry({
              ...entry,
              arvoTilikaudenAlussaEur: value,
            })
          }
          aria-describedby={hintTextId}
        />
        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t('arvoTilikaudenLopussaEuroina')}
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
          aria-describedby={hintTextId}
        />
      </fieldset>
    )
  }
}

const HintTextV2 = styled(HintText)`
  max-width: 290px;
`
