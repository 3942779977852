import {
  ApiResponse,
  executeBackendClient,
} from 'holhous-common/src/vtj/ui/api/microfrontend-backend-api-call'
import { Result } from 'common/src/vtj/browser/http'
import {
  SessionInfoResponse,
  SessionRenewalResponse,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/session/session-api.type'

export const renewSession = (
  externalId: string
): Promise<ApiResponse<SessionRenewalResponse>> =>
  executeBackendClient(async (client) => {
    const result = await client.post('/api/v1/session/renew', {
      externalId,
    })
    return handleSessionInfoResponse(result)
  }, '/')

export const getSessionInfo = (): Promise<ApiResponse<SessionInfoResponse>> =>
  executeBackendClient(async (client) => {
    const result = await client.get('/api/v1/session/info')
    return handleSessionInfoResponse(result)
  }, '/')

const handleSessionInfoResponse = <
  T extends SessionInfoResponse | SessionRenewalResponse
>(
  result: Result<T>
): Result<T> => {
  const [error, response] = result
  if (error) {
    return [error, response]
  }
  response.data = {
    ...response.data,
    expiresAt: new Date(response.data.expiresAt),
    mustLogInAt: new Date(response.data.mustLogInAt),
  }
  return [null, response]
}
