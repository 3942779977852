import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import { mkTestamenttiTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { runInAction } from 'mobx'
import { KyllaEiTuntematon } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import AriaFriendlyInlineAlert from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/AriaFriendlyInlineAlert'

const TestamenttiForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const {
    lomake: { tili, tarkistetutEsitaytetytTiedot },
  } = getTilintarkastusStore()

  return (
    <section data-test-id={mkTestamenttiTestId('container')}>
      <Heading variant="h3" mb="xs">
        {t('henkilonLaatimaTestamentti')}
      </Heading>
      <Paragraph mb={verticalMarginToken}>
        {t('ohjeHenkilonLaatimaTestamentti')}
      </Paragraph>

      {tarkistetutEsitaytetytTiedot?.['testamentti'] === false && (
        <AriaFriendlyInlineAlert
          status="warning"
          mb="s"
          data-test-id={mkTestamenttiTestId('tarkista-esitaytetyt-tiedot')}
        >
          {t('tarkistaEsitaytetytTiedot')}
        </AriaFriendlyInlineAlert>
      )}

      <FormRadioButtonGroup
        data-test-id={mkTestamenttiTestId('laadittu-radio')}
        labelText={t('onkoHenkiloLaatinutTestamentin')}
        radioButtons={[
          {
            labelText: t('ei'),
            value: KyllaEiTuntematon.EI,
          },
          {
            labelText: t('kylla'),
            value: KyllaEiTuntematon.KYLLA,
          },
          {
            labelText: t('enTieda'),
            value: KyllaEiTuntematon.TUNTEMATON,
          },
        ]}
        value={tili.testamentti.laadittu}
        required
        updateValue={(value) => {
          runInAction(() => {
            if (
              Object.values(KyllaEiTuntematon).includes(
                value as KyllaEiTuntematon
              )
            ) {
              tili.testamentti.laadittu = value as KyllaEiTuntematon
            } else {
              tili.testamentti.laadittu = undefined
            }
          })
        }}
      />

      {tili.testamentti.laadittu === KyllaEiTuntematon.KYLLA && (
        <Block mt={verticalMarginToken}>
          <FormRadioButtonGroup
            data-test-id={mkTestamenttiTestId('rajoittavia-ehtoja-radio')}
            labelText={t('testamentinSisalto')}
            groupHintText={t('ohjeTestamentinSisalto')}
            value={tili.testamentti.rajoittaviaEhtoja}
            required
            radioButtons={[
              {
                labelText: t('eiSisallaRajoittaviaEhtoja'),
                value: KyllaEiTuntematon.EI,
              },
              {
                labelText: t('sisaltaaRajoittaviaEhtoja'),
                value: KyllaEiTuntematon.KYLLA,
              },
              {
                labelText: t('tuntematon'),
                value: KyllaEiTuntematon.TUNTEMATON,
              },
            ]}
            updateValue={(value) => {
              runInAction(() => {
                if (
                  Object.values(KyllaEiTuntematon).includes(
                    value as KyllaEiTuntematon
                  )
                ) {
                  tili.testamentti.rajoittaviaEhtoja =
                    value as KyllaEiTuntematon
                } else {
                  tili.testamentti.rajoittaviaEhtoja = undefined
                }
              })
            }}
          />
        </Block>
      )}
    </section>
  )
})

export default TestamenttiForm
