import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { defaultAsiakirjaMarkingMetadata } from 'asia-common/src/vtj/koodisto/marking-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import {
  elamantapahtumat4VuottaRetention,
  elamantapahtumat50VuottaRetention,
  elamantapahtumatJulkinenHenkilotietojaRestriction,
  elamantapahtumatOsittainSalassaPidettava50VuottaK25HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/common-elamantapahtumat-metadata'

export const kuolemanRekisterointiUlkomainenMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.PYYNTO,
      restrictionMetadata: elamantapahtumatJulkinenHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c481fa3e-b753-4223-b267-2d5d7b3c88f5'],
      defaultToivoAsiakirjaTypeId: 'c481fa3e-b753-4223-b267-2d5d7b3c88f5',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUSTEET_REKISTEROINNILLE,
      restrictionMetadata: elamantapahtumatOsittainSalassaPidettava50VuottaK25HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['989e67be-a63f-4c0a-9719-488a06da4be6'],
      defaultToivoAsiakirjaTypeId: '989e67be-a63f-4c0a-9719-488a06da4be6',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: elamantapahtumatJulkinenHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['8c713be1-4484-49a6-b137-d2d76853e0e9'],
      defaultToivoAsiakirjaTypeId: '8c713be1-4484-49a6-b137-d2d76853e0e9',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      restrictionMetadata: elamantapahtumatOsittainSalassaPidettava50VuottaK25HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['e1e9fe6f-e7dc-42ee-afec-155c26854f6b'],
      defaultToivoAsiakirjaTypeId: 'e1e9fe6f-e7dc-42ee-afec-155c26854f6b',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_REKISTEROINNISTA,
      restrictionMetadata: elamantapahtumatJulkinenHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['bef205f3-6e78-467b-87eb-91b633e627ed'],
      defaultToivoAsiakirjaTypeId: 'bef205f3-6e78-467b-87eb-91b633e627ed',
    },
    {
      asiakirjaTypeId: AsiakirjaType.PAATOS_LIITTEINEEN,
      restrictionMetadata: elamantapahtumatJulkinenHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['a2a9c724-c566-482b-8997-9a0b5cccdf66'],
      defaultToivoAsiakirjaTypeId: 'a2a9c724-c566-482b-8997-9a0b5cccdf66',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.REKISTERIIN_MERKINTA,
    manualRatkaisuTypeIds: [
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.OSITTAIN_KIELTEINEN,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.ASIAN_SIIRTO,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: false,
    mandatorySpecifier: true,
    specifierTypeIds: [
      SpecifierType.KUOLEMA,
      SpecifierType.KUOLLEEKSI_JULISTAMINEN,
      SpecifierType.KUOLINTIEDON_KORJAUS,
      SpecifierType.KUOLLEEKSIJULISTAMISTIEDON_KORJAUS,
    ],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
    {
      toimenpideTypeId: ToimenpideType.LISATIETOPYYNTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA, Vireilletulokanava.VIRO],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: elamantapahtumatOsittainSalassaPidettava50VuottaK25HenkilotietojaRestriction,
  retentionMetadata: elamantapahtumat50VuottaRetention,
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
