import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { Heading, Paragraph } from 'suomifi-ui-components'
import { EiIlmoitettujaTietoja } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

const OmassaKaytossaOlevaOmaisuus: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    lomake: {
      tili: { paamiehenOmaisuus },
      lomakkeelleValitutTiedot,
    },
  } = getTilintarkastusStore()
  return (
    <>
      <Heading variant="h3">{t('kaytossaOlevaOmaisuusHeading')}</Heading>

      <Heading variant="h5" as="h4" mt="xs">
        {t('onkoHenkilollaOmaisuutta')}
      </Heading>
      <Paragraph>
        {lomakkeelleValitutTiedot.omaisuusOmassaKaytossa ? t('kylla') : t('ei')}
      </Paragraph>
      <Heading variant="h5" as="h4" mt="s">
        {t('kaytossaOlevaOmaisuusListHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.omaisuusOmassaKaytossa ? (
        paamiehenOmaisuus.omaisuusOmassaKaytossa.map((omaisuus, index) => (
          <Paragraph key={index}> {omaisuus.value}</Paragraph>
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
    </>
  )
})

export default OmassaKaytossaOlevaOmaisuus
