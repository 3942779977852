import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { InternalToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import {
  elamantapahtumat4VuottaRetention,
  elamantapahtumatOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
  elamantapahtumatPysyvaRetention,
  elamantapahtumatSalassaPidettava100VuottaK24K32HenkilotietojaRestriction,
  elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/common-elamantapahtumat-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { defaultAsiakirjaMarkingMetadata } from 'asia-common/src/vtj/koodisto/marking-enums'

export const lapsenRekistrointiUlkomainenMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.LAPSEN_REKISTEROINTI,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['6fa72a4e-5faa-4d81-97d5-4ee66f0929b8'],
      defaultToivoAsiakirjaTypeId: '6fa72a4e-5faa-4d81-97d5-4ee66f0929b8',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.KOPIO_HENKILOLLISYYSASIAKIRJASTA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c6c22a63-08b2-47df-b0f7-a4edf092c7cb'],
      defaultToivoAsiakirjaTypeId: 'c6c22a63-08b2-47df-b0f7-a4edf092c7cb',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.PERUSTEET_REKISTEROINNILLE,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK24K32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['b61beebf-cf70-448e-a972-2bed8775aa17'],
      defaultToivoAsiakirjaTypeId: 'b61beebf-cf70-448e-a972-2bed8775aa17',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['5d22d1bc-e219-450c-9e01-2509d8394c05'],
      defaultToivoAsiakirjaTypeId: '5d22d1bc-e219-450c-9e01-2509d8394c05',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU, AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['d1a5908a-8c58-4745-b90b-b35d3e61f2bb'],
      defaultToivoAsiakirjaTypeId: 'd1a5908a-8c58-4745-b90b-b35d3e61f2bb',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.DVVN_ILMOITUS_REKISTEROINNISTA,
      restrictionMetadata: elamantapahtumatOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat4VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['967cbbd1-f100-4455-b702-363a56cf9cd7'],
      defaultToivoAsiakirjaTypeId: '967cbbd1-f100-4455-b702-363a56cf9cd7',
    },
    {
      asiakirjaTypeId: AsiakirjaType.DVVN_PAATOS_LIITTEINEEN,
      restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['7226774d-7bcf-4c98-b37c-a9fe6d0af6c3'],
      defaultToivoAsiakirjaTypeId: '7226774d-7bcf-4c98-b37c-a9fe6d0af6c3',
    },
    {
      asiakirjaTypeId: AsiakirjaType.OIKAISUVAATIMUSOHJE,
      restrictionMetadata: elamantapahtumatOsittainSalassaPidettava100VuottaK32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['b4801136-3b28-4702-aaf8-0ded97cbdb98'],
      defaultToivoAsiakirjaTypeId: 'b4801136-3b28-4702-aaf8-0ded97cbdb98',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.REKISTERIIN_MERKINTA,
    manualRatkaisuTypeIds: [
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.OSITTAIN_KIELTEINEN,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.ASIAN_SIIRTO,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: false,
    mandatorySpecifier: false,
    specifierTypeIds: [],
  },
  toimenpideMetadata: [],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA, Vireilletulokanava.VIRO],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
  retentionMetadata: elamantapahtumatPysyvaRetention,
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
