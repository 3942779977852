import { findPotentialXssCharacters } from 'common/src/vtj/dom-purify-xss.util'
import { ValidateResult } from 'react-hook-form/dist/types/validator'
import { validateHetu } from 'common/src/vtj/validation/validators-typed'
import {
  validateDate,
  validateEmail,
  validatePhoneNumber,
} from 'common/src/vtj/validation/validators'
import {
  DigitMode,
  parseStringAsNumberWithDigits,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import { TOptionsBase } from 'i18next'
import { $Dictionary } from 'i18next/typescript/helpers'

export const commonXssValidator =
  (t: (arg0: string, arg1?: TOptionsBase & $Dictionary) => string) =>
  (value: string | undefined | null): ValidateResult => {
    if (!value) {
      return true
    }
    const invalidCharacters = findPotentialXssCharacters(value)
    if (invalidCharacters.length) {
      return t('virheTekstissaOnKiellettyjaMerkkeja', {
        invalidCharacters,
      })
    } else {
      return true
    }
  }

export const commonPercentageValidator =
  (t: (arg0: string, arg1?: TOptionsBase & $Dictionary) => string) =>
  (value: number | undefined | null): ValidateResult => {
    if (!value) {
      return true
    }
    if (value > 100) {
      return t('virheProsenttiOsuusYliSata')
    }
    return true
  }

export const commonHetuValidator =
  (t: (arg0: string, arg1?: TOptionsBase & $Dictionary) => string) =>
  (value: string | undefined | null): ValidateResult => {
    if (!value || value?.length === 0) {
      return true
    } else if (validateHetu(value)) {
      return commonXssValidator(t)(value)
    } else {
      return t('virheVirheellinenHetu')
    }
  }

export const isValidIban = (value: string): boolean => {
  const valueWithoutWhitespace = value.replace(/\s+/g, '').toUpperCase()

  if (!/^FI\d{16}$/.test(valueWithoutWhitespace)) {
    return false
  }

  const rearrangedIban =
    valueWithoutWhitespace.slice(4) + valueWithoutWhitespace.slice(0, 4)
  const numericIban = rearrangedIban
    .split('')
    .map((char) => {
      const code = char.charCodeAt(0)
      return code >= 65 && code <= 90 // A-Z
        ? (code - 55).toString() // Convert letters to their numeric equivalent
        : char // Keep digits as is
    })
    .join('')

  // Perform the mod-97 operation
  const ibanInt = BigInt(numericIban)
  return ibanInt % BigInt(97) === BigInt(1)
}

export const commonIbanValidator =
  (t: (arg0: string, arg1?: TOptionsBase & $Dictionary) => string) =>
  (value: string | undefined | null): ValidateResult => {
    if (!value || value?.length === 0) {
      return true
    } else if (isValidIban(value) || !value.toUpperCase().startsWith('FI')) {
      return commonXssValidator(t)(value)
    } else {
      return t('virheVirheellinenIBAN')
    }
  }

export const commonEmailValidator =
  (t: (arg0: string, arg1?: TOptionsBase & $Dictionary) => string) =>
  (value: string | undefined | null): ValidateResult => {
    if (!value || value?.length === 0) {
      return true
    } else if (validateEmail(value)) {
      return commonXssValidator(t)(value)
    } else {
      return t('virheVirheellinenEmail')
    }
  }

export const commonPhoneValidator =
  (t: (arg0: string, arg1?: TOptionsBase & $Dictionary) => string) =>
  (value: string | undefined | null): ValidateResult => {
    if (!value || value?.length === 0) {
      return true
    } else if (validatePhoneNumber(value)) {
      return commonXssValidator(t)(value)
    } else {
      return t('virheVirheellinenPuhelin')
    }
  }

export const commonEuroWithDecimalsOrUndefinedValidator =
  (
    t: (arg0: string, arg1?: TOptionsBase & $Dictionary) => string,
    digitMode: DigitMode
  ) =>
  (value: string | undefined | null): ValidateResult => {
    if (!value || value?.length === 0) {
      return true
    } else if (parseStringAsNumberWithDigits(value, digitMode) !== undefined) {
      return commonXssValidator(t)(value)
    } else if (digitMode === 'none') {
      return t('virheAnnaEuromaaraKokonaislukuna')
    } else {
      return t('virheAnnaMaaraEuroinaKahdellaDesimaalilla')
    }
  }

export const commonPercentageWithDecimalsOrUndefinedValidator =
  (
    t: (arg0: string, arg1?: TOptionsBase & $Dictionary) => string,
    digitMode: DigitMode
  ) =>
  (value: string | undefined | null): ValidateResult => {
    if (!value || value?.length === 0) {
      return true
    }
    const parsedNumber = parseStringAsNumberWithDigits(value, digitMode)
    if (parsedNumber !== undefined) {
      return commonPercentageValidator(t)(parsedNumber)
    } else if (digitMode === 'five') {
      return t('virheAnnaProsenttiosuusViidellaDesimaalilla')
    } else {
      return t('virheAnnaProsenttiosuusKahdellaDesimaalilla')
    }
  }

export const commonAmountWithDecimalsOrUndefinedValidator =
  (t: (arg0: string, arg1?: TOptionsBase & $Dictionary) => string) =>
  (value: string | undefined | null): ValidateResult => {
    if (!value || value?.length === 0) {
      return true
    } else if (parseStringAsNumberWithDigits(value, 'five') !== undefined) {
      return commonXssValidator(t)(value)
    } else {
      return t('virheAnnaKappalemaaraViidellaDesimaalilla')
    }
  }

export const commonDateValidator =
  (t: (arg0: string, arg1?: TOptionsBase & $Dictionary) => string) =>
  (value: string | undefined | null): ValidateResult => {
    if (!value || value?.length === 0) {
      return true
    } else if (validateDate(value, { checkFormat: 'D.M.YYYY' })) {
      return commonXssValidator(t)(value)
    } else {
      return t('virheVirheellinenPvm')
    }
  }

export const createOnChangeEvent = <T>(
  value: T,
  name: string
): { target: { value: T; name: string }; type: 'change' } => ({
  target: { value, name },
  type: 'change',
})
