/* eslint-disable @typescript-eslint/no-explicit-any */
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from 'axios'
import to from 'common/src/vtj/to'

export const DEFAULT_TIMEOUT = 20000
export const DEFAULT_UPLOAD_TIMEOUT = 120000

type ErrorResult<T> = [AxiosError<T>, null]
type SuccessResult<T> = [null, AxiosResponse<T>]
export type Result<T> = ErrorResult<T> | SuccessResult<T>

const request = <T>(
  instance: AxiosInstance,
  options: AxiosRequestConfig
): Promise<Result<T>> =>
  to(
    instance.request({
      headers: {
        Accept: 'application/json',
        'Csrf-Token': (window as any).CSRF_TOKEN,
      },
      timeout: DEFAULT_TIMEOUT,
      ...options,
    })
  ) as Promise<Result<T>>

const getWithInstance =
  <T>(instance: AxiosInstance) =>
  (url: string, options?: AxiosRequestConfig) =>
    request<T>(instance, {
      method: 'get',
      url,
      ...options,
    })

const postWithInstance =
  <T>(instance: AxiosInstance) =>
  (url: string, data?: any, options?: AxiosRequestConfig) =>
    request<T>(instance, {
      method: 'post',
      url,
      data,
      ...options,
    })

const putWithInstance =
  <T>(instance: AxiosInstance) =>
  (url: string, data?: any, options?: AxiosRequestConfig) =>
    request<T>(instance, {
      method: 'put',
      url,
      data,
      ...options,
    })

const patchWithInstance =
  <T>(instance: AxiosInstance) =>
  (url: string, data?: any, options?: AxiosRequestConfig) =>
    request<T>(instance, {
      method: 'patch',
      url,
      data,
      ...options,
    })

const delWithInstance =
  <T>(instance: AxiosInstance) =>
  (url: string, options?: AxiosRequestConfig) =>
    request<T>(instance, {
      method: 'delete',
      url,
      ...options,
    })

interface CancelConfig {
  configWithCancel: AxiosRequestConfig
  cancelFunction: () => void
}

export const enableCancel = (config?: AxiosRequestConfig): CancelConfig => {
  const source: CancelTokenSource = axios.CancelToken.source()
  const configWithCancel: AxiosRequestConfig = {
    ...config,
    cancelToken: source.token,
  }
  const cancelFunction = (): void => {
    source.cancel('CANCELLED')
  }
  return {
    configWithCancel,
    cancelFunction,
  }
}

export const isCancelError = (error: AxiosError<any> | null): boolean => {
  return axios.isCancel(error)
}

export const get: <T>(
  url: string,
  config?: AxiosRequestConfig
) => Promise<Result<T>> = getWithInstance(axios)
export const post: <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => Promise<Result<T>> = postWithInstance(axios)
export const put: <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => Promise<Result<T>> = putWithInstance(axios)
export const patch: <T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
) => Promise<Result<T>> = patchWithInstance(axios)
export const del: <T>(
  url: string,
  config?: AxiosRequestConfig
) => Promise<Result<T>> = delWithInstance(axios)

export interface HttpClient {
  get<T = any>(url: string, config?: AxiosRequestConfig): Promise<Result<T>>
  post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<Result<T>>
  put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<Result<T>>
  patch<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<Result<T>>
  del<T = any>(url: string, config?: AxiosRequestConfig): Promise<Result<T>>
}

export const createInstance = (
  options: AxiosRequestConfig = {}
): HttpClient => {
  const instance = axios.create(options)
  return createWithAxiosInstance(instance)
}

export const createWithAxiosInstance = (
  instance: AxiosInstance
): HttpClient => {
  return {
    get: getWithInstance(instance) as HttpClient['get'],
    post: postWithInstance(instance) as HttpClient['post'],
    put: putWithInstance(instance) as HttpClient['put'],
    patch: patchWithInstance(instance) as HttpClient['patch'],
    del: delWithInstance(instance) as HttpClient['del'],
  }
}
