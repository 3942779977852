import React from 'react'
import {
  Button,
  suomifiDesignTokens,
  IconRemove,
  IconPlus,
  Heading,
  Block,
  Paragraph,
  StaticChip,
  ExternalLink,
  IconChevronRight,
} from 'suomifi-ui-components'
import {
  AsiointiLupaTypeId,
  ExternalLupaTypeId,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import { styled } from 'styled-components'
import { useTranslation } from 'react-i18next'
import {
  AsiointiLupaInfo,
  ExternalLupaInfo,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-info'
import { LanguageCode } from 'common/src/vtj/LanguageCode.enum'
import { mkLupaCardTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { BorderedContentBlock } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

const chipStyle = {
  marginRight: suomifiDesignTokens.spacing.xxs,
  backgroundColor: suomifiDesignTokens.colors.highlightLight3,
  color: suomifiDesignTokens.colors.depthDark1,
  fontSize: '14px',
} as const

const LupaCard: React.FC<{
  lupaTypeId: AsiointiLupaTypeId | ExternalLupaTypeId
  lupaTypeInfo: AsiointiLupaInfo | ExternalLupaInfo
  isApplicableInSystem: boolean
  isSelected: boolean
  onChange: (isSelected: boolean) => unknown
}> = ({
  lupaTypeId,
  lupaTypeInfo,
  isApplicableInSystem,
  isSelected,
  onChange,
}) => {
  const [t, i18n] = useTranslation()
  const externalLupaLink =
    i18n.language === LanguageCode.SV
      ? 'https://dvv.fi/sv/intressebevakaren-behover-tillstand'
      : 'https://dvv.fi/milloin-edunvalvoja-tarvitsee-luvan'
  const isTablet = useDeviceContext().tablet

  return (
    <LupaCardBlock
      $isApplicableInSystem={isApplicableInSystem}
      data-test-id={mkLupaCardTestId(lupaTypeId)}
    >
      <Heading variant="h3">{t(`asiointiLupaType-${lupaTypeId}`)}</Heading>
      <Block mt="xxs" />
      <CardTypeName>{t('lupaAsia')}</CardTypeName>
      <Block mt="xs" />
      <Paragraph>{t(`asiointiLupaTypeDescription-${lupaTypeId}`)}</Paragraph>
      {!isApplicableInSystem && (
        <>
          <Block mt="xs" />
          <Paragraph>{t('ohjeetLuvanHakemiseenDVVnSivuilta')}</Paragraph>
        </>
      )}
      <Block mt="xs" />
      <KeywordList>
        {lupaTypeInfo.keywords.map((keyword) => (
          <li key={keyword}>
            <StaticChip
              style={chipStyle}
              data-test-id={mkLupaCardTestId(lupaTypeId, 'asiasana', keyword)}
            >
              {t(`asiointiLupaKeyword-${keyword}`)}
            </StaticChip>
          </li>
        ))}
      </KeywordList>
      <Block mt="xs" />
      {isApplicableInSystem ? (
        <Button
          variant={isSelected ? 'default' : 'secondary'}
          icon={isSelected ? <IconRemove /> : <IconPlus />}
          fullWidth={!isTablet}
          onClick={() => onChange(!isSelected)}
          data-test-id={mkLupaCardTestId(lupaTypeId, 'valintapainike')}
        >
          {isSelected ? t('poistaHakemukselta') : t('lisaaHakemukselle')}
        </Button>
      ) : (
        <DvvLink
          href={externalLupaLink}
          toNewWindow
          labelNewWindow={t('opensInNewWindow')}
          hideIcon
          data-test-id={mkLupaCardTestId(lupaTypeId, 'ulkoinen-linkki')}
        >
          <IconChevronRight color={suomifiDesignTokens.colors.accentBase} />
          <Block variant="span" mr="xs" />
          {t('siirryDvvnSivuille')}
        </DvvLink>
      )}
    </LupaCardBlock>
  )
}

const LupaCardBlock = styled(BorderedContentBlock)<{
  $isApplicableInSystem: boolean
}>`
  padding: ${suomifiDesignTokens.spacing.m};
  position: relative;
  &:before {
    content: '';
    background-color: ${(props) =>
      props.$isApplicableInSystem
        ? suomifiDesignTokens.colors.highlightBase
        : suomifiDesignTokens.colors.depthLight1};
    bottom: 0;
    left: 0;
    top: 0;
    position: absolute;
    width: ${suomifiDesignTokens.spacing.insetM};
  }
`

const CardTypeName = styled.div`
  color: ${suomifiDesignTokens.colors.depthDark1};
  font-size: 14px;
`

const DvvLink = styled(ExternalLink)`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const KeywordList = styled.ul`
  > li {
    display: inline-block;
  }
`

export default LupaCard
