import React from 'react'
import { useTranslation } from 'react-i18next'
import type { TFunction } from 'i18next'
import { observer } from 'mobx-react'
import { styled } from 'styled-components'
import {
  Block,
  Heading,
  suomifiDesignTokens,
  InlineAlert,
  Paragraph,
} from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { IsoDatetimeToUiDate } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { AsiointiType } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { AsiointiSeurantaAsiavaatimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/edunvalvontasuhteet/edunvalvontasuhteet-api.type'

const PerustiedotOsiot = styled.div<{ isTablet: boolean }>`
  display: grid;
  grid-template-columns: ${({ isTablet }) => (isTablet ? '1fr 1fr' : 'auto')};
  gap: ${suomifiDesignTokens.spacing.m};
`

const getPerustiedotTranslations = (
  t: TFunction,
  {
    accountingPeriodStartDate,
    accountingPeriodEndDate,
    dueDate,
    asiaType,
    sentDate,
    lahetysBlockedUntil,
  }: AsiointiSeurantaAsiavaatimus
) => {
  const formattedAlkuPvm = IsoDatetimeToUiDate(accountingPeriodStartDate)
  const formattedLoppuPvm = IsoDatetimeToUiDate(accountingPeriodEndDate)
  const formattedMaaraPvm = IsoDatetimeToUiDate(dueDate)
  const formattedSentDate = sentDate ? IsoDatetimeToUiDate(sentDate) : ''
  const lahetysBlockedDate = lahetysBlockedUntil
    ? IsoDatetimeToUiDate(lahetysBlockedUntil)
    : ''
  switch (asiaType) {
    case AsiointiType.OMAISUUSLUETTELO:
      return {
        henkilo: t('henkilo'),
        tilikausi: t('tehtavanAlkamispaiva'),
        tilikausipvm: `${formattedAlkuPvm}`,
        lahetaViimeistaan: t('lahetaOmaisuusluetteloMennessa', {
          paivamaara: formattedMaaraPvm,
        }),
        lahetetty: t('omaisuusluetteloOnLahetetty', {
          pvm: formattedSentDate,
        }),
        valtakirjaHeading: t('omaisuusluetteloValtakirjaHeading'),
        voitLahettaaTilinAlkaen: undefined,
      }
    case AsiointiType.PAATOSTILI:
      return {
        henkilo: t('henkilo'),
        tilikausi: t('tilikausi'),
        tilikausipvm: `${formattedAlkuPvm} - ${formattedLoppuPvm}`,
        lahetaViimeistaan: t('lahetaPaatostiliMennessa', {
          paivamaara: formattedMaaraPvm,
        }),
        lahetetty: t('paatostiliOnLahetetty', {
          pvm: formattedSentDate,
        }),
        valtakirjaHeading: t('paatostiliValtakirjaHeading'),
        voitLahettaaTilinAlkaen: t('voitLahettaaTilinAlkaen_PAATOSTILI', {
          pvm: lahetysBlockedDate,
        }),
      }
    case AsiointiType.VUOSITILI:
      return {
        henkilo: t('henkilo'),
        tilikausi: t('tilikausi'),
        tilikausipvm: `${formattedAlkuPvm} - ${formattedLoppuPvm}`,
        lahetaViimeistaan: t('lahetaVuositiliMennessa', {
          paivamaara: formattedMaaraPvm,
        }),
        lahetetty: t('vuositiliOnLahetetty', {
          pvm: formattedSentDate,
        }),
        valtakirjaHeading: t('vuositiliValtakirjaHeading'),
        voitLahettaaTilinAlkaen: t('voitLahettaaTilinAlkaen_VUOSITILI', {
          pvm: lahetysBlockedDate,
        }),
      }
  }
}

const Perustiedot: React.FC<{
  showValtakirja?: boolean
  showDueDateAlerts?: boolean
}> = observer(({ showValtakirja, showDueDateAlerts = true }) => {
  const [t] = useTranslation()

  const {
    vaatimus,
    lomake: { tili },
  } = getTilintarkastusStore()

  const {
    guardianLastname,
    guardianFirstnames,
    principalLastname,
    principalFirstnames,
    sentDate,
    lahetysBlockedUntil,
  } = vaatimus

  const translations = getPerustiedotTranslations(t, vaatimus)
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <Block data-test-id={mkYhteenvetoTestId('perustiedot')}>
      <Heading variant="h3">{t('perustiedot')}</Heading>
      <Block mt={verticalMarginToken} />
      <PerustiedotOsiot isTablet={isTablet}>
        <Block
          data-test-id={mkYhteenvetoTestId('perustiedot-block-paamies-name')}
        >
          <Heading variant="h4">{translations.henkilo}</Heading>
          <Paragraph>
            {principalLastname}, {principalFirstnames}
          </Paragraph>
        </Block>
        <Block data-test-id={mkYhteenvetoTestId('perustiedot-block-tilikausi')}>
          <Heading variant="h4">{translations.tilikausi}</Heading>
          <Paragraph>{translations.tilikausipvm}</Paragraph>
        </Block>
        <Block
          data-test-id={mkYhteenvetoTestId('perustiedot-block-tilivelvollinen')}
        >
          <Heading variant="h4">{t('tilivelvollinen')}</Heading>
          <Paragraph>
            {guardianLastname}, {guardianFirstnames}
          </Paragraph>
        </Block>
        {!!tili.edunvalvojanValtakirja.length && !!showValtakirja && (
          <Block
            data-test-id={mkYhteenvetoTestId('perustiedot-block-valtakirja')}
          >
            <Heading variant="h4">{translations.valtakirjaHeading}</Heading>
            <AttachmentList attachments={tili.edunvalvojanValtakirja} />
          </Block>
        )}
      </PerustiedotOsiot>
      <Block mt={verticalMarginToken} />

      {showDueDateAlerts && lahetysBlockedUntil && (
        <InlineAlert
          status="warning"
          data-test-id={mkYhteenvetoTestId('voit-lahettaa-alkaen-text')}
        >
          <Paragraph>{translations.voitLahettaaTilinAlkaen}</Paragraph>
        </InlineAlert>
      )}

      {showDueDateAlerts && !lahetysBlockedUntil && (
        <InlineAlert
          data-test-id={mkYhteenvetoTestId('lahetys-maarapaiva-text')}
        >
          <Paragraph>
            {sentDate ? translations.lahetetty : translations.lahetaViimeistaan}
          </Paragraph>
        </InlineAlert>
      )}
    </Block>
  )
})

export default Perustiedot
