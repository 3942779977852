import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { FieldValues, Validate, useFormContext } from 'react-hook-form'
import { createOnChangeEvent } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { DateInput } from 'suomifi-ui-components'
import { styled } from 'styled-components'
import { lowerFirst } from 'lodash'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

export const StyledDateInput = styled(DateInput)`
  width: 100%;
  max-width: 290px;
  margin-bottom: 25px;
`

export const FormDateInput: React.FC<{
  'data-test-id': string
  value?: string // NOTE: the initial empty value should be undefined!
  labelText: string
  required?: boolean
  validate: Validate<string | null | undefined, FieldValues>
  updateValue: (value: string) => void
}> = observer(
  ({
    'data-test-id': dataTestId,
    value,
    labelText,
    required = false,
    validate,
    updateValue,
  }) => {
    if (!dataTestId) {
      throw new Error('Missing data test id')
    }
    const [t] = useTranslation()
    const { lang } = useAsiointiUserStore()
    const isTablet = useDeviceContext().tablet

    const {
      setValue,
      register,
      formState: { errors, isValidating },
    } = useFormContext()

    const { onChange, ref, name } = register(dataTestId, {
      required: {
        value: required,
        message: `${t('kirjoitaTaiValitse')} ${lowerFirst(labelText)}`,
      },
      validate,
      onChange: (event) => updateValue(event.target.value),
    })

    useEffect(() => {
      // hack to get around the DateInput value not resetting when we want clear the selected value.
      // i.e. useful when we want to reuse existing modal with new data
      setValue(dataTestId, value, { shouldValidate: isValidating })
    }, [dataTestId, setValue, isValidating, value])

    return (
      <StyledDateInput
        data-test-id={dataTestId}
        labelText={t(labelText)}
        datePickerEnabled
        required={required}
        optionalText={required ? undefined : t('valinnainen')}
        language={lang}
        statusTextAriaLiveMode="off"
        smallScreen={!isTablet}
        defaultValue={value}
        status={errors[dataTestId] ? 'error' : 'default'}
        data-test-error-message={errors[dataTestId]?.message || ''}
        statusText={(errors[dataTestId]?.message || '') as string}
        onChange={({ value }) =>
          onChange(createOnChangeEvent(value, dataTestId))
        }
        ref={ref}
        name={name}
      />
    )
  }
)

export default FormDateInput
