import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getAddressAsArray } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import { EiIlmoitettujaTietoja } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { FrontTiedoksiannettava } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { TiliPersonRole } from 'tilintarkastus-common/src/vtj/tili-account-enums'

const OmatTiedot: React.FC = () => {
  const [t] = useTranslation()
  const {
    lomake: {
      edunvalvoja: { lastname, firstnames, address, email, phone },
    },
  } = getTilintarkastusStore()
  const lang = useAsiointiUserStore().lang

  return (
    <Block>
      <Paragraph>{`${firstnames} ${lastname}`}</Paragraph>
      {getAddressAsArray(address, lang).map((row, i) => (
        <Paragraph key={i}>{row}</Paragraph>
      ))}

      <Heading variant="h5" as="h4" mt="xs">
        {t('sahkoposti')}
      </Heading>
      {email ? <Paragraph>{email}</Paragraph> : <EiIlmoitettujaTietoja />}

      <Heading variant="h5" as="h4" mt="xs">
        {t('puhelinnumero')}
      </Heading>
      {phone ? <Paragraph>{phone}</Paragraph> : <EiIlmoitettujaTietoja />}
    </Block>
  )
}

const Tiedoksiannettava: React.FC<{
  tiedoksiannettava: FrontTiedoksiannettava
}> = ({ tiedoksiannettava }) => {
  const [t] = useTranslation()
  const {
    lomake: { lomakkeelleValitutTiedot },
  } = getTilintarkastusStore()
  const lang = useAsiointiUserStore().lang

  const yhteystiedot = tiedoksiannettava.address
    ? [
        [
          tiedoksiannettava.henkiloTyyppi === TiliPersonRole.YKSITYISHENKILO &&
            tiedoksiannettava.firstnames,
          tiedoksiannettava.henkiloTyyppi === TiliPersonRole.YKSITYISHENKILO &&
            tiedoksiannettava.lastname,
          tiedoksiannettava.henkiloTyyppi === TiliPersonRole.YRITYS &&
            tiedoksiannettava.businessId,
          tiedoksiannettava.henkiloTyyppi === TiliPersonRole.YRITYS &&
            tiedoksiannettava.businessName,
          tiedoksiannettava.henkiloTyyppi === TiliPersonRole.YKSITYISHENKILO &&
            tiedoksiannettava.hetu,
        ]
          .filter(Boolean)
          .join(' ') || undefined,
        ...getAddressAsArray(tiedoksiannettava.address, lang),
      ].filter(Boolean)
    : []

  return !lomakkeelleValitutTiedot.tiedoksiannettava ? (
    <Block>
      <Heading variant="h5" as="h4" mt="xs">
        {t('oletkoKuolinpesanAinoaOsakas')}
      </Heading>
      <Paragraph>{t('olen')}</Paragraph>
    </Block>
  ) : (
    <Block>
      {yhteystiedot.map((row, i) => (
        <Paragraph key={i}>{row}</Paragraph>
      ))}

      {tiedoksiannettava.henkiloTyyppi === TiliPersonRole.YKSITYISHENKILO && (
        <>
          <Heading variant="h5" as="h4" mt="xs">
            {t('yhteysPaamieheen')}
          </Heading>
          {tiedoksiannettava.yhteysPaamieheen ? (
            <Paragraph>{tiedoksiannettava.yhteysPaamieheen}</Paragraph>
          ) : (
            <EiIlmoitettujaTietoja />
          )}
        </>
      )}

      <Heading variant="h5" as="h4" mt="xs">
        {t('sahkoposti')}
      </Heading>
      {tiedoksiannettava.email ? (
        <Paragraph>{tiedoksiannettava.email}</Paragraph>
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h5" as="h4" mt="xs">
        {t('puhelinnumero')}
      </Heading>
      {tiedoksiannettava.phone ? (
        <Paragraph>{tiedoksiannettava.phone}</Paragraph>
      ) : (
        <EiIlmoitettujaTietoja />
      )}
    </Block>
  )
}

const Yhteystiedot: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const {
    lomake: { tiedoksiannettava },
  } = getTilintarkastusStore()
  return (
    <section data-test-id={mkYhteenvetoTestId('yhteystiedot-container')}>
      <Heading variant="h3">{t('omatYhteystiedot')}</Heading>
      <OmatTiedot />
      {tiedoksiannettava && (
        <>
          <DividerLine mb={verticalMarginToken} mt={verticalMarginToken} />
          <Heading variant="h3">{t('tiedoksianto')}</Heading>
          <Tiedoksiannettava tiedoksiannettava={tiedoksiannettava} />
        </>
      )}
    </section>
  )
})

export default Yhteystiedot
