import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { Heading } from 'suomifi-ui-components'
import {
  EiIlmoitettujaTietoja,
  YhteenvetoTilirivi,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import {
  countVelat,
  getArvoAlussaString,
  getArvoLopussaString,
  getVelallinenString,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'

const Velat: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    vaatimus: { isPrincipalUnderaged, asiaType },
    lomake,
  } = getTilintarkastusStore()
  const {
    lomakkeelleValitutTiedot,
    tili: {
      tilirivit: { velat },
    },
  } = lomake
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  return (
    <section data-test-id={mkYhteenvetoTestId('velat-container')}>
      <Heading variant="h3">{t('velat')}</Heading>
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('pankkilainatHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.pankkilainat ? (
        velat.pankkilainat.map((pl, i) => (
          <YhteenvetoTilirivi
            heading={`${t(`lainatyyppi_${pl.tyyppi}`)} ${pl.pankinNimi}`}
            content={[
              `${t('lainanNumero')}: ${pl.lainanNumero}`,
              getArvoAlussaString(t, asiaType, pl.arvoTilikaudenAlussaEur),
              getArvoLopussaString(t, asiaType, pl.arvoTilikaudenLopussaEur),
            ].filter(Boolean)}
            attachments={pl.asiakirjat}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {isPrincipalUnderaged ? t('panttaukset') : t('takauksetHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.takaukset ? (
        velat.takaukset.map((tak, i) => (
          <YhteenvetoTilirivi
            heading={getVelallinenString(
              t,
              tak.velallisenEtunimi,
              tak.velallisenSukunimi
            )}
            content={[
              tak.vakuus !== undefined
                ? `${t('vakuus')}: ${tak.vakuus}`
                : undefined,
              getArvoAlussaString(t, asiaType, tak.arvoTilikaudenAlussaEur),
              getArvoLopussaString(t, asiaType, tak.arvoTilikaudenLopussaEur),
              tak.onkoTullutHenkilonMaksettavaksi !== undefined
                ? `${t('onkoTullutHenkilonMaksettavaksi')}: ${
                    tak.onkoTullutHenkilonMaksettavaksi ? t('kylla') : t('ei')
                  }`
                : undefined,
            ].filter(Boolean)}
            attachments={tak.asiakirjat}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
      <Heading variant="h4" mt={verticalMarginToken}>
        {t('muutVelatHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.muutVelat ? (
        velat.muutVelat.map((mv, i) => (
          <YhteenvetoTilirivi
            heading={`${t(`velkatyyppi_${mv.tyyppi}`)}`}
            content={[
              getArvoAlussaString(t, asiaType, mv.arvoTilikaudenAlussaEur),
              getArvoLopussaString(t, asiaType, mv.arvoTilikaudenLopussaEur),
            ].filter(Boolean)}
            attachments={mv.asiakirjat}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {`${t('velatyhteensa')}: ${formatNumberWithComma(countVelat(true))} €`}
      </Heading>
      {countVelat(false) > 0 && (
        <Heading variant="h4" mt="xs">
          {`${t(
            isPrincipalUnderaged
              ? 'panttaukseYhteensa'
              : 'takauksetEiMaksettavaksiYhteensa'
          )}: ${formatNumberWithComma(countVelat(false))} €`}
        </Heading>
      )}
    </section>
  )
})

export default Velat
