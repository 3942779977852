import { suomifiDesignTokens } from 'suomifi-ui-components'
import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import AriaFriendlyInlineAlert from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/AriaFriendlyInlineAlert'
import { styled } from 'styled-components'

export const EsitaytetytTiedotOhjeAlert: React.FC<{
  dataTestId: string
}> = observer(({ dataTestId }) => {
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'
  const [t] = useTranslation()

  return (
    <AriaFriendlyInlineAlert
      title={t('ohjeetEsitaytetynTilinTayttamiseen')}
      data-test-id={dataTestId}
      mt={verticalMarginToken}
      mb={verticalMarginToken}
      status="neutral"
    >
      <List>
        <li>{t('tarkistaEsitaytetytTiedot')}</li>
        <li>{t('teeTarvittavatMuutokset')}</li>
        <li>{t('ilmoitaMuutoksetTilikaudenAjalta')}</li>
        <li>{t('lisaaTilikaudenAsiakirjat')}</li>
      </List>
    </AriaFriendlyInlineAlert>
  )
})

export default EsitaytetytTiedotOhjeAlert

const List = styled.ul`
  list-style: disc;
  margin-left: ${suomifiDesignTokens.spacing.m};

  li {
    padding-left: ${suomifiDesignTokens.spacing.s};
  }
`
