import React from 'react'
import axios from 'axios'
import { runInAction } from 'mobx'
import { styled } from 'styled-components'
import { observer } from 'mobx-react'
import {
  IconLogin,
  LanguageMenu,
  LanguageMenuItem,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import { LanguageCode } from 'common/src/vtj/LanguageCode.enum'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { useTranslation } from 'react-i18next'
import { mkHeaderTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-test-id'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import {
  useGetLoginUri,
  useGetLogoutUri,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/login-redirect'
import { CenteredWidthLimitedSmallDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  HeaderButton,
  headerLinkStyle,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/header/header-components'
import logoFiUrl from 'common-ui-components/src/assets/images/logo-dvv-white-fi.svg'
import logoSvUrl from 'common-ui-components/src/assets/images/logo-dvv-white-sv.svg'
import plainLogoUrl from 'common-ui-components/src/assets/images/dvv-symboli.svg'

const Header: React.FC<{ isTestAuthenticatorEnabled: boolean }> = observer(
  ({ isTestAuthenticatorEnabled }) => {
    const userStore = useAsiointiUserStore()
    const [t, i18next] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const logoUrl = i18next.language === 'sv' ? logoSvUrl : logoFiUrl

    const languageName = {
      fi: { desktop: 'Suomeksi (FI)', mobile: 'FI' },
      sv: { desktop: 'På svenska (SV)', mobile: 'SV' },
    }

    return (
      <HeaderBackground>
        <HeaderContent data-test-id={mkHeaderTestId()}>
          <HeadingContainer>
            <DvvHeaderLogo
              src={isTablet ? logoUrl : plainLogoUrl}
              alt={t('dvv')}
            />
            <HeadingText>{t('edunvalvontaHeader')}</HeadingText>
          </HeadingContainer>
          {isTablet && (
            <SignedIn isTestAuthenticatorEnabled={isTestAuthenticatorEnabled} />
          )}
          <StyledLanguageMenu
            id="headerLanguageMenu"
            data-test-id={mkHeaderTestId('language-menu')}
            buttonText={
              isTablet
                ? languageName[userStore.lang].desktop
                : languageName[userStore.lang].mobile
            }
            aria-label={t('chooseLanguage')}
          >
            {languageMenuItem(isTablet, selectLanguage, LanguageCode.FI)}
            {languageMenuItem(isTablet, selectLanguage, LanguageCode.SV)}
          </StyledLanguageMenu>
          {!isTablet && (
            <SignedIn isTestAuthenticatorEnabled={isTestAuthenticatorEnabled} />
          )}
        </HeaderContent>
      </HeaderBackground>
    )

    function languageMenuItem(
      isTablet: boolean,
      handleChange: (lang: HolhousAsiointiLanguageCode) => void,
      lang: HolhousAsiointiLanguageCode
    ) {
      return (
        <LanguageMenuItem
          data-test-id={mkHeaderTestId('language-menu-item', lang)}
          selected={userStore.lang === lang}
          onSelect={() => {
            handleChange(lang)
          }}
          lang={lang}
        >
          {isTablet ? languageName[lang].desktop : languageName[lang].mobile}
        </LanguageMenuItem>
      )
    }

    async function selectLanguage(lang: HolhousAsiointiLanguageCode) {
      try {
        await axios.get(`/aseta-kieli?kieliasetus=${lang}`)
        await i18next.changeLanguage(lang)
        runInAction(() => {
          userStore.lang = lang
        })
      } catch (err) {
        console.error(err)
      }
    }
  }
)

export default Header

const SignedIn: React.FC<{ isTestAuthenticatorEnabled: boolean }> = observer(
  ({ isTestAuthenticatorEnabled }) => {
    const [t] = useTranslation()
    const loginUri = useGetLoginUri()
    const logoutUri = useGetLogoutUri()
    const userStore = useAsiointiUserStore()
    const isTablet = useDeviceContext().tablet

    return (
      <>
        {userStore.user && (
          <SignedInContainer>
            <Text variant="bold" smallScreen={!isTablet} color="whiteBase">
              {userStore.user.displayname}
            </Text>
            <SignOutButton
              data-test-id={mkHeaderTestId('logout')}
              href={logoutUri}
            >
              {t('logoutLabel')}
            </SignOutButton>
          </SignedInContainer>
        )}
        {!userStore.user && (
          <>
            <HeaderButton
              data-test-id={mkHeaderTestId('login')}
              onClick={() => (window.location.href = loginUri)}
              icon={<IconLogin />}
              fullWidth={!isTablet}
            >
              {t('loginButtonLabel')}
            </HeaderButton>
            {isTestAuthenticatorEnabled && (
              <TestAuthenticatorButton
                data-test-id={mkHeaderTestId('test-authenticator')}
                onClick={() =>
                  (window.location.href = `${loginUri}&isTestAuthenticator=true`)
                }
                icon={<IconLogin />}
                fullWidth={!isTablet}
              >
                Testitunnistaja
              </TestAuthenticatorButton>
            )}
          </>
        )}
      </>
    )
  }
)

const HeaderBackground = styled.header`
  grid-area: header;
  background-color: ${suomifiDesignTokens.colors.brandBase};
`

const HeaderContent = styled(CenteredWidthLimitedSmallDiv)`
  display: flex;
  flex-flow: row wrap;
  gap: ${suomifiDesignTokens.spacing.s};
  justify-content: stretch;
  align-items: center;
`

const HeadingContainer = styled.div`
  margin-block: auto;
  flex: 1;
  display: flex;
  align-items: center;
  gap: 10px;

  @media screen and ${device.tablet} {
    margin-right: auto;
  }

  a {
    display: inline-block;
    ${headerLinkStyle};
  }
`

const HeadingText = styled(Text)`
  ${suomifiDesignTokens.typography.heading3}
  min-width: 50px;
  color: #ffffff;
`

const StyledLanguageMenu = styled(LanguageMenu)`
  &&&&& .fi-language-menu_button {
    padding: 10px 10px;
    color: ${suomifiDesignTokens.colors.whiteBase};
    background-color: ${suomifiDesignTokens.colors.brandBase};
    border-color: transparent;

    &:hover {
      border-color: ${suomifiDesignTokens.colors.depthLight1};
    }

    .fi-language-menu_button_icon {
      color: ${suomifiDesignTokens.colors.whiteBase};

      path {
        fill: ${suomifiDesignTokens.colors.whiteBase};
      }
    }
  }
`

const TestAuthenticatorButton = styled(HeaderButton)`
  height: 46px;

  &&,
  &&:hover {
    background-color: rgb(0, 127, 100);
  }
`

const SignOutButton = styled.a`
  ${suomifiDesignTokens.typography.bodySemiBold}
  color: ${suomifiDesignTokens.colors.whiteBase};
  background-color: transparent;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  align-self: flex-end;
  min-height: ${suomifiDesignTokens.spacing.s};
  padding: 0;

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${suomifiDesignTokens.colors.whiteBase};
  }

  &:focus,
  &:focus-visible,
  &:focus-within {
    outline: none;
    border: none;
    border-radius: 2px;
    box-shadow: ${suomifiDesignTokens.colors.accentSecondary} 0 0 0 2px;
  }
`

const SignedInContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
  background-color: ${suomifiDesignTokens.colors.brandBase};
  color: ${suomifiDesignTokens.colors.whiteBase};

  @media ${device.tablet} {
    flex-flow: column nowrap;
    width: fit-content;
    justify-content: flex-end;
  }
`

const DvvHeaderLogo = styled.img`
  @media ${device.tablet} {
    width: 160px;
    height: 80px;
    margin-left: -20px;
  }
`
