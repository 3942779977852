import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const HallintaOikeudet: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    lomake: { tili, lomakkeelleValitutTiedot },
  } = getTilintarkastusStore()
  return (
    <section data-test-id={mkYhteenvetoTestId('hallintaoikeudet-container')}>
      <Heading variant="h3">{t('hallintaoikeudet')}</Heading>

      <Heading variant="h5" as="h4" mt="xs">
        {t(
          isOmaisuusluettelo()
            ? 'onkoHallintaoikeusiaToisenOmaisuuteen'
            : 'onkoOllutSovittujaHallintaoikeuksia'
        )}
      </Heading>
      <Paragraph>
        {lomakkeelleValitutTiedot.hallintaoikeudet ? t('kylla') : t('ei')}
      </Paragraph>
      {lomakkeelleValitutTiedot.hallintaoikeudet && (
        <Block mt="xs">
          <Heading variant="h5" as="h4">
            {t('mitenHallintaoikeuksistaOnSovittu')}
          </Heading>
          <MultiParagraphText
            text={tili.sopimustiedot.hallintaoikeudet.mitenSovittu}
          />
          <AttachmentList
            attachments={tili.sopimustiedot.hallintaoikeudet.sopimukset}
          />
        </Block>
      )}
    </section>
  )
})

export default HallintaOikeudet
