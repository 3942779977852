import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'

import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Heading } from 'suomifi-ui-components'
import {
  EiIlmoitettujaTietoja,
  YhteenvetoTilirivi,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import {
  countMenot,
  getExpenseForAccountingPeriod,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'

const Menot: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const {
    vaatimus: { isPrincipalUnderaged },
    lomake: {
      lomakkeelleValitutTiedot,
      tili: {
        tilirivit: { menot },
      },
    },
  } = getTilintarkastusStore()

  return (
    <section data-test-id={mkYhteenvetoTestId('menot-container')}>
      <Heading variant="h3">{t('menotHeading')}</Heading>

      {!isPrincipalUnderaged && (
        <>
          <Heading variant="h4" mt={verticalMarginToken}>
            {t('kayttovaratHeading')}
          </Heading>
          {lomakkeelleValitutTiedot.kayttovarat ? (
            menot.kayttovarat.map((kv, i) => (
              <YhteenvetoTilirivi
                heading={t(`expenseType_${kv.tyyppi}`)}
                content={[getExpenseForAccountingPeriod(t, kv.yhteensa)]}
                attachments={kv.tositteet}
                key={i}
              />
            ))
          ) : (
            <EiIlmoitettujaTietoja />
          )}
        </>
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {t('elinkustannuksetHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.elinkustannukset ? (
        menot.elinkustannukset.map((ek, i) => (
          <YhteenvetoTilirivi
            heading={t(`expenseType_${ek.tyyppi}`)}
            content={[getExpenseForAccountingPeriod(t, ek.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {t('palvelumaksutHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.palvelumaksut ? (
        menot.palvelumaksut.map((pm, i) => (
          <YhteenvetoTilirivi
            heading={t(`expenseType_${pm.tyyppi}`)}
            content={[getExpenseForAccountingPeriod(t, pm.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {t('vuokratVastikkeetHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.vuokratVastikkeet ? (
        menot.vuokratVastikkeet.map((vv, i) => (
          <YhteenvetoTilirivi
            heading={t(`expenseType_${vv.tyyppi}`)}
            content={[
              [vv.nimi, vv.tunnus].filter(Boolean).join(', '),
              getExpenseForAccountingPeriod(t, vv.yhteensa),
            ]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      {!isPrincipalUnderaged && (
        <>
          <Heading variant="h4" mt={verticalMarginToken}>
            {t('elatusavutHeading')}
          </Heading>
          {lomakkeelleValitutTiedot.elatusavut ? (
            menot.elatusavut.map((ea, i) => (
              <YhteenvetoTilirivi
                heading={t(`expenseType_${ea.tyyppi}`)}
                content={[getExpenseForAccountingPeriod(t, ea.yhteensa)]}
                key={i}
              />
            ))
          ) : (
            <EiIlmoitettujaTietoja />
          )}
        </>
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {t('pankkimaksutHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.pankinPerimatMaksut ? (
        menot.pankinPerimatMaksut.map((pm, i) => (
          <YhteenvetoTilirivi
            heading={t(`expenseType_${pm.tyyppi}`)}
            content={[getExpenseForAccountingPeriod(t, pm.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {t('verotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.verot ? (
        menot.verot.map((v, i) => (
          <YhteenvetoTilirivi
            heading={t(`expenseType_${v.tyyppi}`)}
            content={[getExpenseForAccountingPeriod(t, v.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {t('tyonantajakulutHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.tyonantajakulut ? (
        menot.tyonantajakulut.map((tk, i) => (
          <YhteenvetoTilirivi
            heading={t(`expenseType_${tk.tyyppi}`)}
            content={[getExpenseForAccountingPeriod(t, tk.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {t('velanhoitomenotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.velanhoitomenot ? (
        menot.velanhoitomenot.map((vhm, i) => (
          <YhteenvetoTilirivi
            heading={t(`expenseType_${vhm.tyyppi}`)}
            content={[getExpenseForAccountingPeriod(t, vhm.yhteensa)]}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {t('edunvalvonnankulutHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.edunvalvonnankulut ? (
        menot.edunvalvonnankulut.map((ek, i) => (
          <YhteenvetoTilirivi
            heading={t(`expenseType_${ek.tyyppi}`)}
            content={[getExpenseForAccountingPeriod(t, ek.yhteensa)]}
            attachments={ek.tositteet}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {t('muutMenotHeading')}
      </Heading>
      {lomakkeelleValitutTiedot.muutMenot ? (
        menot.muutMenot.map((mm, i) => (
          <YhteenvetoTilirivi
            heading={t(`expenseType_${mm.tyyppi}`)}
            content={[getExpenseForAccountingPeriod(t, mm.yhteensa)]}
            attachments={mm.tositteet}
            key={i}
          />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}

      <Heading variant="h4" mt={verticalMarginToken}>
        {`${t('menotyhteensa')}: ${formatNumberWithComma(countMenot())} €`}
      </Heading>
    </section>
  )
})

export default Menot
