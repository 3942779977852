import React, { FC } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { mkOmaisuusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Block, Heading, Paragraph, Text } from 'suomifi-ui-components'
import {
  FormElementProps,
  FormListInput,
  FormModalProps,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInput'
import {
  getTilirivit,
  isOmaisuusluettelo,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { FrontMuuVelka } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import {
  ContentItemOrPakollinenTietoPuuttuuWarning,
  FormListInputExpander,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormListInputExpander'
import { observable } from 'mobx'
import FormNumberInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormNumberInput'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { AsiointiMuuVelkaTyyppi } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'
import { AsiointiLedgerAccountDebtType } from 'tilintarkastus-common/src/vtj/data/asiointi-ledger-accounts/asiointi-ledger-account-debt-enum'
import FormDropdown from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormDropdown'
import { commonEuroWithDecimalsOrUndefinedValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import { IsoDatetimeToUiDate } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/date-string'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { TiliLomakeKentta } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake-tunniste'

const MuutVelatForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const { lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake
  const omaisuus = getTilirivit()

  const mkNewEntry = (): FrontMuuVelka =>
    observable({
      id: crypto.randomUUID(),
      tiliointinumero: '',
      tyyppi: undefined,
      arvoTilikaudenAlussaEur: undefined,
      arvoTilikaudenLopussaEur: undefined,
      asiakirjat: [],
    })

  return (
    <section data-test-id={mkOmaisuusTestId('muutVelat-form')}>
      <Heading variant="h3" mb="xs">
        {t('muutVelatHeading')}
      </Heading>
      <Paragraph>{t('ohjeIlmoitaMuutVelat')}</Paragraph>
      <Block mt={verticalMarginToken} />
      <FormListInput
        ExpanderComponent={MuuVelkaListItem}
        state={omaisuus.velat.muutVelat}
        dataTestIdPrefix={mkOmaisuusTestId('muutVelat-form')}
        ModalContentComponent={MuuVelkaModal}
        newEntry={mkNewEntry}
        translationPrefix="muutVelat"
        tiliLomakeKentta={TiliLomakeKentta.muutVelat}
        lomakkeelleValitutTiedot={lomakkeelleValitutTiedot}
      />
    </section>
  )
})

const MuuVelkaModal: FC<FormModalProps<FrontMuuVelka>> = observer(
  ({ createDataTestId, entry, setEntry }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'xl' : 'm'

    const { vaatimus } = getTilintarkastusStore()
    const formattedAlkuPvm = IsoDatetimeToUiDate(
      vaatimus.accountingPeriodStartDate
    )

    const translations =
      muuVelkaTranslations[isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI']

    const velkatyypit = Object.values(AsiointiMuuVelkaTyyppi)
      .map((value) => ({
        name: t(`velkatyyppi_${value}`),
        value,
      }))
      .filter(({ value }) =>
        vaatimus.isPrincipalUnderaged
          ? value !== AsiointiLedgerAccountDebtType.ELATUSAPUVELKA
          : true
      )

    return (
      <div>
        <FormDropdown
          labelText={t('muunVelanTyyppi')}
          data-test-id={createDataTestId('modal-tyyppi')}
          value={entry.tyyppi}
          items={velkatyypit}
          required
          updateValue={(value) => {
            setEntry({
              ...entry,
              tyyppi: value as AsiointiLedgerAccountDebtType,
            })
          }}
        />
        <Block mt={verticalMarginToken} />

        {!isOmaisuusluettelo() && (
          <FormNumberInput
            data-test-id={createDataTestId('modal-arvoTilikaudenAlussa')}
            labelText={t('arvoTilikaudenAlussaEuroina')}
            digitMode="two"
            required
            value={entry.arvoTilikaudenAlussaEur}
            validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
            updateValue={(value) =>
              setEntry({
                ...entry,
                arvoTilikaudenAlussaEur: value,
              })
            }
          />
        )}

        <FormNumberInput
          data-test-id={createDataTestId('modal-arvoTilikaudenLopussa')}
          labelText={t(translations.arvoLopussa)}
          hintText={
            isOmaisuusluettelo()
              ? t('ilmoitaMaaraTehtavasiAlkamispaivalta', {
                  alkupvm: formattedAlkuPvm,
                })
              : undefined
          }
          digitMode="two"
          required
          value={entry.arvoTilikaudenLopussaEur}
          validate={commonEuroWithDecimalsOrUndefinedValidator(t, 'two')}
          updateValue={(value) => {
            setEntry({
              ...entry,
              arvoTilikaudenLopussaEur: value,
            })
          }}
        />

        <FormAttachmentFileBox
          observableAttachments={entry.asiakirjat}
          asiakirjaTypeId={TiliAsiakirjaType.MUU_LIITE}
          data-test-id={createDataTestId('modal-asiakirja')}
          text={t(translations.asiakirjaInfo)}
          title={t('sopimukset')}
        />
      </div>
    )
  }
)

const MuuVelkaListItem: FC<FormElementProps<FrontMuuVelka>> = observer(
  ({ createDataTestId, tiliLomakeKentta, entry, onEdit, onRemove }) => {
    const [t] = useTranslation()
    const isTablet = useDeviceContext().tablet
    const verticalMarginToken = isTablet ? 'm' : 's'
    const translations =
      muuVelkaTranslations[isOmaisuusluettelo() ? 'OMAISUUSLUETTELO' : 'TILI']

    const arvoLopussaEur =
      entry.arvoTilikaudenLopussaEur !== undefined
        ? `${formatNumberWithComma(entry.arvoTilikaudenLopussaEur)} €`
        : undefined
    const arvoAlussaEur =
      entry.arvoTilikaudenAlussaEur !== undefined
        ? `${formatNumberWithComma(entry.arvoTilikaudenAlussaEur)} €`
        : ''

    return (
      <FormListInputExpander
        heading={t(`velkatyyppi_${entry.tyyppi}`)}
        headingRight={arvoLopussaEur}
        createDataTestId={createDataTestId}
        onEdit={onEdit}
        onRemove={onRemove}
        pakollinenTietoPuuttuu={entry.arvoTilikaudenLopussaEur === undefined}
        tiliLomakeKentta={tiliLomakeKentta}
      >
        {!isOmaisuusluettelo() && (
          <Block mt={verticalMarginToken} mb={verticalMarginToken}>
            <Heading variant="h5">{t('arvoTilikaudenAlussaEuroina')}</Heading>
            <Text>{arvoAlussaEur}</Text>
          </Block>
        )}

        <ContentItemOrPakollinenTietoPuuttuuWarning
          heading={t(translations.arvoLopussa)}
          content={arvoLopussaEur}
        />

        {entry.asiakirjat.length > 0 && (
          <Block mb={verticalMarginToken}>
            <Heading variant="h5">{t('asiakirjat')}</Heading>
            <AttachmentList attachments={entry.asiakirjat} />
          </Block>
        )}
      </FormListInputExpander>
    )
  }
)

const muuVelkaTranslations = {
  OMAISUUSLUETTELO: {
    arvoLopussa: 'maaraEuroina',
    asiakirjaInfo: 'muunVelanAsiakirjatAlkamispaivaltaInfo',
  },
  TILI: {
    arvoLopussa: 'arvoTilikaudenLopussaEuroina',
    asiakirjaInfo: 'muutVelatAsiakirjaInfo',
  },
}

export default MuutVelatForm
