import _ from 'lodash'
import React from 'react'
import { observer } from 'mobx-react'
import {
  mkAttachmentsBuyInstrTestId,
  mkHakemusTestId,
  mkNeededAttachmentsTestId,
  mkNeededAttachmentTestId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { LupaApplicationStep } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import { styled } from 'styled-components'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import {
  Block,
  Heading,
  Paragraph,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import CurrentStepDisplay from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/common/CurrentStepDisplay'
import { AsiointiLupaTypeId } from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-enums'
import {
  applicationCompareFn,
  asiointiLupaInfos,
} from 'lupa-backend/src/vtj/asiointi/lupa/asiointi-lupa-info'
import { getStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { useTranslation } from 'react-i18next'
import {
  estateAsiakirjaTypeIds,
  LupaAsiointiAsiakirjaType,
  LupaAsiointiAsiakirjaTypeId,
} from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import { TFunction } from 'i18next'

const NeededAttachmentsPage: React.FC = observer(() => {
  const [t] = useTranslation()
  const store = getStore()
  const selectedTypes = store.batch.applications
    .slice()
    .sort(applicationCompareFn)
    .map((a) => a.typeId)

  return (
    <section
      data-test-id={mkHakemusTestId(LupaApplicationStep.NEEDED_ATTACHMENTS)}
    >
      <NeededAttachmentsBlock padding="s">
        <CurrentStepDisplay heading={t('liitteetHeading')} />
        <Block mt="m" />
        <Paragraph>{t('liitteetDescriptionValmiina')}</Paragraph>
        <Block mt="m" />
        <Paragraph>{t('liitteetDescriptionKasittelyaika')}</Paragraph>
        <Block mt="m" />
        <Paragraph>{t('liitteetDescriptionHenkilot')}</Paragraph>
        <Block mt="m" />

        <Heading variant="h5" as="h3">
          {t('hakijat')}
        </Heading>
        <TextList>
          <TextItem>{t('liitteetJosMuitaHakijoita')}</TextItem>
          <TextItem>{t('liitteetJosAsiamies')}</TextItem>
        </TextList>
        <Block mt="m" />

        <Heading variant="h5" as="h3">
          {t('liitteet15vPaamiehet')}
        </Heading>
        <TextList>
          <TextItem>{t('liitteetPaamiesYmmartaa')}</TextItem>
          <TextItem>{t('liitteetPaamiesEiYmmarra')}</TextItem>
        </TextList>

        {selectedTypes.map((lupaType, index) => {
          return (
            <Block
              key={lupaType}
              data-test-id={mkHakemusTestId(
                LupaApplicationStep.NEEDED_ATTACHMENTS,
                lupaType,
                index
              )}
            >
              <LupaTypeAttachments lupaTypeId={lupaType} />
            </Block>
          )
        })}
      </NeededAttachmentsBlock>
    </section>
  )
})

const BuyInstrumentsInfo: React.FC<{ testId: string }> = ({ testId }) => {
  const [t] = useTranslation()
  return (
    <div data-test-id={testId}>
      <Block mt="m" />
      <Paragraph>{t('liitteetSijoitusSelvitaPankki')}</Paragraph>
      <Block mt="m" />
      <Paragraph>{t('liitteetSijoitusTavalliset')}</Paragraph>
      <Block mt="m" />
      <Paragraph>{t('liitteetSijoitusLupaTarvitaan')}</Paragraph>
      <Block mt="m" />
      <TextList>
        <TextItem>{t('liitteetSijoitusJosSijoittamassa')}</TextItem>
        <TextList>
          <TextItem>{t('liitteetSijoitusLiitaOsakkeisiin')}</TextItem>
          <TextItem>{t('liitteetSijoitusLiitaOsuuskuntaan')}</TextItem>
          <TextItem>{t('liitteetSijoitusLiitaJoukkovelka')}</TextItem>
          <TextItem>{t('liitteetSijoitusLiitaRahasto')}</TextItem>
          <TextItem>{t('liitteetSijoitusLiitaOptiot')}</TextItem>
        </TextList>
      </TextList>
    </div>
  )
}

const LupaTypeAttachments: React.FC<{
  lupaTypeId: AsiointiLupaTypeId
}> = ({ lupaTypeId }) => {
  const [t] = useTranslation()
  const asiakirjaListDef = mkAsiakirjaList(lupaTypeId, t)

  return (
    <div data-test-id={mkNeededAttachmentsTestId(lupaTypeId)}>
      <Block mt="m" />
      <Heading variant="h5" as="h3">
        {t(`asiointiLupaType-${lupaTypeId}`)}
      </Heading>
      {lupaTypeId === 'SIJOITUSKOHTEIDEN_OSTAMINEN' ? (
        <BuyInstrumentsInfo testId={mkAttachmentsBuyInstrTestId()} />
      ) : null}
      <AttachmentList>
        {asiakirjaListDef.asiakirjaNames.map((asiakirjaName, index) => {
          return (
            <AttachmentItem
              key={asiakirjaName}
              data-test-id={mkNeededAttachmentTestId(lupaTypeId, index)}
            >
              {asiakirjaName}
            </AttachmentItem>
          )
        })}
        <AttachmentItem>
          {asiakirjaListDef.footerHeader}
          <AttachmentList>
            {asiakirjaListDef.footerAsiakirjaNames.map(
              (asiakirjaName, index) => {
                return (
                  <AttachmentItem
                    key={asiakirjaName}
                    data-test-id={mkNeededAttachmentTestId(
                      lupaTypeId,
                      asiakirjaListDef.asiakirjaNames.length + index
                    )}
                  >
                    {asiakirjaName}
                  </AttachmentItem>
                )
              }
            )}
          </AttachmentList>
        </AttachmentItem>
      </AttachmentList>
    </div>
  )
}

interface AsiakirjaListDef {
  asiakirjaNames: string[]
  footerHeader: string
  footerAsiakirjaNames: string[]
}
const mkAsiakirjaList = (
  lupaType: AsiointiLupaTypeId,
  t: TFunction
): AsiakirjaListDef => {
  const lupaTypeInfo = asiointiLupaInfos[lupaType]
  const tAsiakirjaType = (type: LupaAsiointiAsiakirjaTypeId): string =>
    t(`asiointiAsiakirjaType-${type}`, { context: lupaType })
  const getDefault = (
    footerHeader = t('liitteetKuolinpesaKaupanOsapuoli')
  ): AsiakirjaListDef => ({
    asiakirjaNames: _.without(
      lupaTypeInfo.asiakirjaTypes,
      LupaAsiointiAsiakirjaType.MUU_LIITE,
      ...estateAsiakirjaTypeIds
    ).map(tAsiakirjaType),
    footerHeader,
    footerAsiakirjaNames: estateAsiakirjaTypeIds.map(tAsiakirjaType),
  })

  switch (lupaType) {
    case 'AVIOERO_OSITUS_TAI_EROTTELU':
      return {
        asiakirjaNames: _.without(
          lupaTypeInfo.asiakirjaTypes,
          LupaAsiointiAsiakirjaType.MUU_LIITE,
          LupaAsiointiAsiakirjaType.PERUKIRJA_ILMAN_SUKUSELVITYSTA,
          LupaAsiointiAsiakirjaType.TESTAMENTTI_JA_SEN_TIEDOKSIANTO_JA_LAINVOIMAISUUSTODISTUKSET
        ).map(tAsiakirjaType),
        footerHeader: t('liitteetKuolinpesaOsapuoli'),
        // This will contain duplicate AVIOEHTOSOPIMUS_JA_TODISTUS_SEN_REKISTEROINNISTA and that is wanted for clarity
        footerAsiakirjaNames: estateAsiakirjaTypeIds.map(tAsiakirjaType),
      }
    case 'PERINNOSTA_LUOPUMINEN':
    case 'SOPIMUS_KUOLINPESAN_YHTEISHALLINNOSTA':
    case 'PERINTOOSUUDEN_LUOVUTUS':
    case 'PERINNONJAKO':
      return {
        asiakirjaNames: _.without(
          lupaTypeInfo.asiakirjaTypes,
          LupaAsiointiAsiakirjaType.MUU_LIITE,
          LupaAsiointiAsiakirjaType.SELVITYS_KUOLINPESAN_OMAISUUDEN_MYYNNISTA,
          LupaAsiointiAsiakirjaType.KUOLINPESAN_TILIEN_SALDOT_JA_TILITAPAHTUMAT
        ).map(tAsiakirjaType),
        footerHeader: t('liitteetKuolinpesaSelvitysTilasta'),
        footerAsiakirjaNames: [
          LupaAsiointiAsiakirjaType.SELVITYS_KUOLINPESAN_OMAISUUDEN_MYYNNISTA,
          LupaAsiointiAsiakirjaType.KUOLINPESAN_TILIEN_SALDOT_JA_TILITAPAHTUMAT,
        ].map(tAsiakirjaType),
      }
    case 'SIJOITUSKOHTEIDEN_OSTAMINEN':
      return {
        asiakirjaNames: [],
        footerHeader: t('liitteetKuolinpesaOsapuoli'),
        footerAsiakirjaNames: estateAsiakirjaTypeIds.map(tAsiakirjaType),
      }
    case 'LAINAN_ANTAMINEN':
    case 'LAINAN_OTTAMINEN':
    case 'TAKAUS':
    case 'ELINKEINON_HARJOITTAMINEN':
      return getDefault(t('liitteetKuolinpesaOsapuoli'))
    case 'PANTTAUS':
      return getDefault(t('liitteetKuolinpesaPantinantajana'))
    case 'YHTIOMIEHEKSI_RYHTYMINEN':
      return getDefault(t('liitteetKuolinpesaJatkaaYhtionToimintaa'))
    case 'METSAN_TAI_MAA_AINEKSEN_MYYNTI':
    case 'ASUNNON_VUOKRAAMINEN':
    case 'KIINTEISTON_VUOKRAAMINEN':
    case 'ASUMISOIKEUDEN_VUOKRAAMINEN':
      return getDefault(t('liitteetKuolinpesaOmaisuus'))
    default:
      return getDefault()
  }
}

const AttachmentList = styled.ul`
  list-style: disc;
  list-style-position: outside;
  margin: 0 0 ${suomifiDesignTokens.spacing.s} ${suomifiDesignTokens.spacing.l};
`

const AttachmentItem = styled.li`
  margin: ${suomifiDesignTokens.spacing.xxs} 0 0 0;
  padding: 0;

  > ${AttachmentList} {
    padding: 0px ${suomifiDesignTokens.spacing.xs};
    margin-left: ${suomifiDesignTokens.spacing.s};
  }
`

const TextList = AttachmentList
const TextItem = AttachmentItem

const NeededAttachmentsBlock = styled(Block)`
  border: solid ${suomifiDesignTokens.colors.accentSecondary};
  border-width: 1px 1px 1px 4px;
  background: ${suomifiDesignTokens.colors.accentSecondaryLight1};
  margin: 0 ${suomifiDesignTokens.spacing.s};
  padding: ${suomifiDesignTokens.spacing.m};
  position: relative;

  @media ${device.tablet} {
    margin: 0;
  }
`

export default NeededAttachmentsPage
