import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { FrontSopimus } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/tili-lomake/lomake.type'
import { getPaivamaaraString } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/groups/yhteenveto.util'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { EiIlmoitettujaTietoja } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { Voimassaoloaika } from 'tilintarkastus-common/src/vtj/asiointi-account-enums'

const Sopimus: React.FC<{ s: FrontSopimus }> = ({ s }) => {
  const [t] = useTranslation()
  const content = [
    s.toimittaja,
    s.voimassaoloaika === Voimassaoloaika.MAARA_AIKAINEN
      ? t('maaraaikainen')
      : s.voimassaoloaika === Voimassaoloaika.TOISTAISEKSI_VOIMASSA_OLEVA
      ? t('toistaiseksiVoimassaOleva')
      : undefined,
    getPaivamaaraString(t, s.beginDate, s.endDate),
  ]
    .filter((s) => s)
    .join(', ')

  return (
    <Block>
      <Heading variant="h5" as="h4" mt="xs">
        {s.sopimustyyppi}
      </Heading>
      <Paragraph>{content}</Paragraph>
      <AttachmentList attachments={s.asiakirjat} />
    </Block>
  )
}

const Sopimukset: React.FC = observer(() => {
  const [t] = useTranslation()
  const { tili, lomakkeelleValitutTiedot } = getTilintarkastusStore().lomake

  return (
    <section data-test-id={mkYhteenvetoTestId('sopimukset-container')}>
      <Heading variant="h3">{t('sopimuksetHeading')}</Heading>
      {lomakkeelleValitutTiedot.sopimukset ? (
        tili.sopimustiedot.sopimukset.map((s, index) => (
          <Sopimus s={s} key={index} />
        ))
      ) : (
        <EiIlmoitettujaTietoja />
      )}
    </section>
  )
})

export default Sopimukset
