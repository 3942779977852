import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import { AsiakirjaOriginType, AsiakirjaType } from 'asia-common/src/vtj/koodisto/asiakirja-enums'
import { SpecifierType } from 'asia-common/src/vtj/koodisto/specifier-enums'
import {
  AsiaPersonRole,
  AsiaPersonType,
  RatkaisuType,
  AsiaStatus,
  AsiaHandlerRole,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'
import { defaultAsiakirjaMarkingMetadata } from 'asia-common/src/vtj/koodisto/marking-enums'
import { InternalToimenpideType, ToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import {
  elamantapahtumat10VuottaRetention,
  elamantapahtumat50VuottaRetention,
  elamantapahtumatJulkinenHenkilotietojaRestriction,
  elamantapahtumatOsittainSalassaPidettava100VuottaK24K32HenkilotietojaRestriction,
  elamantapahtumatOsittainSalassaPidettava50VuottaK25HenkilotietojaRestriction,
  elamantapahtumatPysyvaRetention,
} from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/common-elamantapahtumat-metadata'

export const kuolemanRekisterointiKotimainenMetadata: AsiaMetadata = {
  asiakirjaMetadata: [
    {
      asiakirjaTypeId: AsiakirjaType.KUOLINTIEDON_KORJAUSPYYNTO,
      restrictionMetadata: elamantapahtumatOsittainSalassaPidettava100VuottaK24K32HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumatPysyvaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['545ce357-388f-4b69-8d7e-efc94c6452b6'],
      defaultToivoAsiakirjaTypeId: '545ce357-388f-4b69-8d7e-efc94c6452b6',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_KUOLEMASTA_VAESTOTIETOJARJESTELMAAN,
      restrictionMetadata: elamantapahtumatOsittainSalassaPidettava50VuottaK25HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['c9c59e58-8336-4046-a0ee-68e11fa2d852'],
      defaultToivoAsiakirjaTypeId: 'c9c59e58-8336-4046-a0ee-68e11fa2d852',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.ILMOITUS_KUOLEMASTA_VAESTOTIETOJARJESTELMAAN_HETUTON,
      restrictionMetadata: elamantapahtumatOsittainSalassaPidettava50VuottaK25HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat50VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['bd4283c9-cf7a-4dc5-97ac-5bd1bcfd0ef9'],
      defaultToivoAsiakirjaTypeId: 'bd4283c9-cf7a-4dc5-97ac-5bd1bcfd0ef9',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },

    {
      asiakirjaTypeId: AsiakirjaType.KUOLLEEKSIJULISTAMISPAATOS,
      restrictionMetadata: elamantapahtumatJulkinenHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['04912f4a-2d4a-4aa1-a33d-5b8909a8447b'],
      defaultToivoAsiakirjaTypeId: '04912f4a-2d4a-4aa1-a33d-5b8909a8447b',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.MUU_ASIAKIRJA,
      restrictionMetadata: elamantapahtumatJulkinenHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['9bc7effd-d93b-40bc-a472-6fca996ced71'],
      defaultToivoAsiakirjaTypeId: '9bc7effd-d93b-40bc-a472-6fca996ced71',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.SELVITYSASIAKIRJA,
      restrictionMetadata: elamantapahtumatOsittainSalassaPidettava50VuottaK25HenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.SAAPUNUT, AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.SAAPUNUT,
      toivoAsiakirjaTypeIds: ['54363b83-5fe4-40f7-bbb4-e6a7aea51206'],
      defaultToivoAsiakirjaTypeId: '54363b83-5fe4-40f7-bbb4-e6a7aea51206',
      markingMetadata: defaultAsiakirjaMarkingMetadata,
    },
    {
      asiakirjaTypeId: AsiakirjaType.DVVN_PAATOS_KUOLLEEKSI_JULISTAMISESTA,
      restrictionMetadata: elamantapahtumatJulkinenHenkilotietojaRestriction,
      retentionMetadata: elamantapahtumat10VuottaRetention,
      originTypeIds: [AsiakirjaOriginType.LAADITTU],
      defaultOriginTypeId: AsiakirjaOriginType.LAADITTU,
      toivoAsiakirjaTypeIds: ['b5500da5-092b-4de9-afed-9b00d85d9839'],
      defaultToivoAsiakirjaTypeId: 'b5500da5-092b-4de9-afed-9b00d85d9839',
    },
  ],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.HAKIJA, AsiaPersonRole.HUOLLETTAVA],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.REKISTERIIN_MERKINTA,
    manualRatkaisuTypeIds: [
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.OSITTAIN_KIELTEINEN,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.ASIAN_SIIRTO,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: false,
    mandatorySpecifier: true,
    specifierTypeIds: [
      SpecifierType.KUOLEMA,
      SpecifierType.KUOLLEEKSI_JULISTAMINEN,
      SpecifierType.KUOLINTIEDON_KORJAUS,
      SpecifierType.KUOLLEEKSIJULISTAMISTIEDON_KORJAUS,
    ],
  },
  toimenpideMetadata: [
    {
      toimenpideTypeId: ToimenpideType.TAYDENTAMINEN,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
    {
      toimenpideTypeId: ToimenpideType.LISATIETOPYYNTO,
      internalViestintaTypeIds: [],
      externalViestintaTypeIds: [],
      prioritizedLaadittuAsiakirjaTypeIds: [],
      prioritizedSaapunutAsiakirjaTypeIds: [],
      automationSaapunutAsiakirjaTypeId: null,
    },
  ],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA, Vireilletulokanava.TOIVO],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: elamantapahtumatJulkinenHenkilotietojaRestriction,
  retentionMetadata: elamantapahtumatPysyvaRetention,
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
