import { Paragraph, Text } from 'suomifi-ui-components'
import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { mkEdunvalvontaValtakirjaTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { LupaAsiointiAsiakirjaType } from 'lupa-backend/src/vtj/asiointi/asiakirja/asiointi-asiakirja-enums'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'

export const ValtakirjaForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const {
    vaatimus: { asiaType },
    lomake: { tili },
  } = getTilintarkastusStore()

  return (
    <section data-test-id={mkEdunvalvontaValtakirjaTestId('container')}>
      <Paragraph>
        <Text variant="bold">{t(translations[asiaType].heading)}</Text>
      </Paragraph>
      <FormAttachmentFileBox
        observableAttachments={tili.edunvalvojanValtakirja}
        asiakirjaTypeId={LupaAsiointiAsiakirjaType.VALTAKIRJA}
        data-test-id={mkEdunvalvontaValtakirjaTestId('liite')}
        title={t(translations[asiaType].heading)}
        hideTitle
        required
      />
    </section>
  )
})

const translations = {
  OMAISUUSLUETTELO: {
    heading: 'omaisuusluetteloValtakirjaHeading',
  },
  PAATOSTILI: {
    heading: 'paatostiliValtakirjaHeading',
  },
  VUOSITILI: {
    heading: 'vuositiliValtakirjaHeading',
  },
}
