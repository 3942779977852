import { AsiaMetadata } from 'asia-common/src/vtj/koodisto/asia-type-metadata'
import {
  elamantapahtumatPysyvaRetention,
  elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
} from 'asia-common/src/vtj/koodisto/asia-type/elamantapahtumien-rekisterointi/common-elamantapahtumat-metadata'
import { HuomioType } from 'asia-common/src/vtj/koodisto/huomio-enums'
import {
  AsiaHandlerRole,
  AsiaPersonRole,
  AsiaPersonType,
  AsiaStatus,
  RatkaisuType,
} from 'asia-common/src/vtj/koodisto/asia-enums'
import { InternalToimenpideType } from 'asia-common/src/vtj/koodisto/toimenpide-enums'
import { Vireilletulokanava } from 'asia-common/src/vtj/koodisto/vireilletulokanava-enums'

export const vanhemmuusJaHuoltoUlkomainenMetadata: AsiaMetadata = {
  asiakirjaMetadata: [],
  huomioMetadata: [
    {
      huomioTypeId: HuomioType.TURVAKIELTO,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIANOSAINEN_KUOLLUT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ODOTTAA_SELVITYSTA_MANUAALINEN,
      postDueDateHuomioTypeId: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
    },
    {
      huomioTypeId: HuomioType.SELVITYKSEN_MAARAAIKA_YLITTYNYT_MANUAALINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.KIIREELLINEN,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.SAHKOISEN_ASIAKIRJAN_VARMENNUS,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.MUU_TALLENNUS,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.VAALIT,
      postDueDateHuomioTypeId: null,
    },
    {
      huomioTypeId: HuomioType.ASIAKIRJA_PALAUTUNUT_VIRASTOLLE,
      postDueDateHuomioTypeId: null,
    },
  ],
  organizationMetadata: {
    personRoleIds: [],
    organizationTypeIds: [],
  },
  personRoleMetadata: [AsiaPersonRole.ASIAKAS, AsiaPersonRole.ILMOITTAJA],
  personTypeMetadata: [AsiaPersonType.IHMINEN],
  ratkaisuMetadata: {
    defaultRatkaisuTypeId: RatkaisuType.REKISTERIIN_MERKINTA,
    manualRatkaisuTypeIds: [
      RatkaisuType.REKISTERIIN_MERKINTA,
      RatkaisuType.KIELTEINEN_PAATOS,
      RatkaisuType.RAUENNUT,
      RatkaisuType.OSITTAIN_KIELTEINEN,
      RatkaisuType.SILLENSA_JATETTY,
      RatkaisuType.ASIAN_SIIRTO,
    ],
    automaticRatkaisuTypeIds: [],
  },
  specifierMetadata: {
    multipleSpecifiers: false,
    mandatorySpecifier: false,
    specifierTypeIds: [],
  },
  toimenpideMetadata: [],
  vireilletulokanavaMetadata: [Vireilletulokanava.ELSA],
  asiaStatusMetadata: [
    {
      asiaStatusId: AsiaStatus.ALOITTAMATTA,
      toimenpideTypeId: InternalToimenpideType.VIREILLETULO,
      handlerRoleId: null,
      defaultStartableStatusId: AsiaStatus.KASITTELYSSA,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.KASITTELYSSA,
      toimenpideTypeId: InternalToimenpideType.VALMISTELU,
      handlerRoleId: AsiaHandlerRole.KASITTELIJA,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
    {
      asiaStatusId: AsiaStatus.VALMIS,
      toimenpideTypeId: null,
      handlerRoleId: null,
      defaultStartableStatusId: null,
      defaultMovableStatusId: null,
    },
  ],
  restrictionMetadata: elamantapahtumatSalassaPidettava100VuottaK32HenkilotietojaRestriction,
  retentionMetadata: elamantapahtumatPysyvaRetention,
  emailMetadata: {
    fromAddress: null,
    useUserEmailIfPossible: false,
  },
}
