import { observer } from 'mobx-react-lite'
import React, { PropsWithChildren } from 'react'
import { styled, css } from 'styled-components'
import {
  InlineAlert,
  InlineAlertProps,
  suomifiDesignTokens,
} from 'suomifi-ui-components'

interface AsiointiInlineAlertProps {
  'data-test-id'?: string
  tarkastusmerkinta?: boolean
  title?: string
}

const AriaFriendlyInlineAlert: React.FC<
  PropsWithChildren & InlineAlertProps & AsiointiInlineAlertProps
> = observer((props) => {
  const {
    children,
    'data-test-id': dataTestId,
    title,
    status,
    tarkastusmerkinta,
    ...rest
  } = props
  return (
    <CustomAlert
      labelText={title}
      status={tarkastusmerkinta ? 'neutral' : status}
      ariaLiveMode="off"
      data-test-id={dataTestId}
      $tarkastusmerkinta={tarkastusmerkinta}
      {...rest}
    >
      {children}
    </CustomAlert>
  )
})

export default AriaFriendlyInlineAlert

const tarkastusmerkintaColors = css`
  background: ${suomifiDesignTokens.colors.accentTertiaryLight1};
  padding: 5px 10px 5px 30px;
  border-color: ${suomifiDesignTokens.colors.accentTertiaryDark1};
`

const CustomAlert = styled(InlineAlert)<{ $tarkastusmerkinta?: boolean }>`
  &&& {
    ${(props) => props.$tarkastusmerkinta && tarkastusmerkintaColors}
  }
`
