import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { Block, Heading } from 'suomifi-ui-components'
import { EiIlmoitettujaTietoja } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/YhteenvetoGroup'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const Lisatiedot: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTilintarkastusStore().lomake.tili
  return (
    <>
      <Heading variant="h3">{t('lisatiedot')}</Heading>
      {tili.lisatiedot ? (
        <Block mt="xs">
          <Heading variant="h5" as="h4">
            {t('lisatietojaTextInputLabel')}
          </Heading>
          <MultiParagraphText text={tili.lisatiedot} />
        </Block>
      ) : (
        <EiIlmoitettujaTietoja />
      )}
    </>
  )
})

export default Lisatiedot
