import { observer } from 'mobx-react'
import React, { FC } from 'react'
import { styled } from 'styled-components'
import {
  Block,
  Button,
  Heading,
  IconRemove,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import { ChildTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { useTranslation } from 'react-i18next'
import { IconPlus } from 'suomifi-icons'
import { useFormContext, useFormState } from 'react-hook-form'
import FormTextInput from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextInput'
import { action, observable } from 'mobx'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'

export interface FormSimpleListInputProps {
  dataTestIdPrefix: string
  heading: string
  description: string
  state: { id: string; value: string; isEsitaytetty?: boolean }[] // observable array
}

const FormSimpleListInput: FC<FormSimpleListInputProps> = observer(
  ({ dataTestIdPrefix, description, heading, state }) => {
    const mkTestId = (...arg: ChildTestId[]) =>
      `${[dataTestIdPrefix, ...arg].join('-')}`

    const { t } = useTranslation()
    const { errors } = useFormState()
    const { unregister } = useFormContext()

    const items = state.map((item, index) => {
      const testId = mkTestId(`list-item-${item.id}`)

      return (
        <ListItem key={item.id}>
          <StyledTextInput
            required
            labelText={`${t('omaisuus')} ${index + 1}`}
            data-test-id={testId}
            data-test-error-message={(errors[testId]?.message || '') as string}
            defaultValue={item.value}
            requiredLabel={t('kirjoitaLyhytKuvaus')}
            validate={commonXssValidator(t)}
            updateValue={action((v: string) => (item.value = v))}
          />
          <StyledRemoveButton
            data-test-id={mkTestId(`list-remove-item-${index}`)}
            icon={<IconRemove />}
            variant="secondaryNoBorder"
            onClick={action(() => {
              if (state.length === 1) {
                state[0] = {
                  value: '',
                  id: crypto.randomUUID(),
                  isEsitaytetty: undefined,
                }
              } else {
                unregister(testId)
                state.splice(index, 1)
              }
            })}
          >
            {t('poista')}
          </StyledRemoveButton>
        </ListItem>
      )
    })

    return (
      <>
        <Container data-test-id={mkTestId('form-container')}>
          <Content>
            <Heading variant="h4">{heading}</Heading>
            <Block mt="s" />
            <Text>{description}</Text>
            <Block mt="s" />
            <List>{items}</List>
            <Block mt="l" />
            <div>
              <Button
                data-test-id={mkTestId('list-add-item')}
                icon={<IconPlus />}
                variant="secondary"
                onClick={action(() =>
                  state.push(observable({ id: crypto.randomUUID(), value: '' }))
                )}
              >
                {t('lisaaRivi')}
              </Button>
            </div>
          </Content>
        </Container>
      </>
    )
  }
)

export default FormSimpleListInput

const Container = styled.div`
  background-color: ${suomifiDesignTokens.colors.highlightLight4};
  padding: ${suomifiDesignTokens.spacing.l};
  border: 1px solid ${suomifiDesignTokens.colors.depthLight1};
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: ${suomifiDesignTokens.spacing.xl};
`

const ListItem = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
`

const StyledTextInput = styled(FormTextInput)`
  & {
    max-width: 700px;
    padding-bottom: 0;
  }
`

const StyledRemoveButton = styled(Button)`
  margin-top: 34px;
  padding: ${suomifiDesignTokens.spacing.xs};
  min-width: 125px;
`
