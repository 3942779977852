import { Block, Paragraph, Text } from 'suomifi-ui-components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { DiscList } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/front-page/common/common'

export const PaatostiliAsiakirjatOhje: React.FC = () => {
  const [t] = useTranslation()
  return (
    <>
      <Paragraph mt="m" mb="xxs">
        <Text variant="bold">{t('paatostilinTarvittavatTiedotHead')}</Text>
      </Paragraph>
      <DiscList>
        <li>
          <Text>{t('tarvittavatTiedot_sopimukset')}</Text>
        </li>
        <li>
          <Text>{t('tarvittavatTiedot_veroilmoitus')}</Text>
        </li>
        <li>
          <Text>{t('tarvittavatTiedot_talletukset_sijoitukset')}</Text>
        </li>
        <li>
          <Text>{t('tarvittavatTiedot_lainat_takaukset')}</Text>
        </li>
        <li>
          <Text>{t('tarvittavatTiedot_perukirjat')}</Text>
        </li>
        <li>
          <Text>{t('tarvittavatTiedot_valtakirja')}</Text>
        </li>
      </DiscList>
      <Block mt="s" />
      <Text>{t('paatostilinTarvittavatTiedotFooter')}</Text>
    </>
  )
}
