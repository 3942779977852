import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/auto-focus'
import {
  BulletList,
  ResponsiveBorderedContentBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import {
  Block,
  Button,
  ExternalLink,
  Heading,
  IconArrowRight,
  IconCheckCircleFilled,
  InlineAlert,
  Paragraph,
  Text,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import {
  getTiliFeedbackUrl,
  kasittelyAjatUrl,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import DividerLine from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/DividerLine'
import { mkValmisTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import {
  ButtonBlock,
  FocusableHeading,
  ValmisBlock,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/components'
import { useNavigate } from 'react-router-dom'
import { getAsioiPathByPersonId } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/asiointi-routes'
import { HolhousAsiointiLanguageCode } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/holhous-asiointi-language'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { TulostaYhteenvetoModal } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/lomake/yhteenveto/TulostaYhteenvetoModal'
import { MultiParagraphText } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MultiParagraphText'

const ValmisPage: React.FC = observer(() => {
  const [t, i18n] = useTranslation()
  const userStore = useAsiointiUserStore()
  const headingRef = useAutoFocus<HTMLHeadingElement>()
  const navigate = useNavigate()
  const { vaatimus } = getTilintarkastusStore()
  const openFrontPage = () =>
    navigate(getAsioiPathByPersonId(vaatimus.personId))
  const verticalMarginToken = 'xl'
  const { asiaType } = getTilintarkastusStore().vaatimus

  const [yhteenvetoModalVisible, setYhteenvetoModalVisible] = useState(false)

  const TiliSection: React.FC = () => {
    return (
      <>
        <Block variant="section" mt={verticalMarginToken}>
          <Block mt={verticalMarginToken} />
          <Heading variant="h3">{t('mitaSeuraavaksiTapahtuu')}</Heading>
          <Block mt="xs" />
          <BulletList>
            <li>
              <Text>{t('virkailijaOttaaPaatostilinKasittelyyn')}</Text>
            </li>
            <li>
              <Text>
                {t('virkailijaOnSinuunYhteydessaJosTarvitaanLisatietoja')}{' '}
              </Text>
            </li>
            <li>
              <Text>{t('virkailijaTarkastaaPaatostilin')} </Text>
            </li>
          </BulletList>
        </Block>
        <Block variant="section" mt={verticalMarginToken}>
          <Heading variant="h3">{t('kasittelyaika')}</Heading>
          <Block mt="xs">
            <ExternalLink
              href={kasittelyAjatUrl(
                i18n.language as HolhousAsiointiLanguageCode
              )}
              variant="accent"
              underline="initial"
              toNewWindow
              labelNewWindow={t('avautuuUuteenValilehteen')}
            >
              {t('tarkistaHenkiloasiakkaidenKasittelyajatDvvnSivuilta')}
            </ExternalLink>
          </Block>
        </Block>
      </>
    )
  }

  return (
    <ResponsiveBorderedContentBlock data-test-id={mkValmisTestId('container')}>
      <TulostaYhteenvetoModal
        setVisible={setYhteenvetoModalVisible}
        visible={yhteenvetoModalVisible}
        vaatimus={vaatimus}
      />
      <ValmisBlock mb="s">
        <IconCheckCircleFilled fill={suomifiDesignTokens.colors.successDark1} />{' '}
        {t('valmis')}
      </ValmisBlock>
      <FocusableHeading mb="s" variant="h2" ref={headingRef} tabIndex={-1}>
        {t(translations[asiaType].kiitosHeading)}
      </FocusableHeading>
      <Paragraph>{t(translations[asiaType].tositteetInfo)}</Paragraph>
      <Block mb={verticalMarginToken} />
      <InlineAlert labelText={t('otaYhteenvetoTalteen')}>
        <Paragraph mb={verticalMarginToken}>
          {t('lahetysPagePrintInfoText')}
        </Paragraph>
        <Button
          onClick={() => setYhteenvetoModalVisible(true)}
          variant="secondary"
        >
          {t('avaaYhteenveto')}
        </Button>
      </InlineAlert>
      {!isOmaisuusluettelo() && <TiliSection />}
      <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
      <Heading variant="h3" mb="s">
        {t('annaPalautetta')}
      </Heading>
      <MultiParagraphText mb="s" text={t(translations[asiaType].palauteInfo)} />
      <Block mb="s">
        <ExternalLink
          href={getTiliFeedbackUrl(userStore.lang)}
          data-test-id={mkValmisTestId('tili-feedback-link-button')}
          variant="accent"
          underline="initial"
          toNewWindow
          labelNewWindow={t('avautuuUuteenValilehteen')}
        >
          {t('annaPalautettaPalvelusta')}
        </ExternalLink>
      </Block>

      <DividerLine mt={verticalMarginToken} mb={verticalMarginToken} />
      <Block variant="section">
        <Heading variant="h3" mb="s">
          {t('seuraavaksi')}
        </Heading>
        <MultiParagraphText
          mb="s"
          text={t('voitPalataEtusivulleTaiKirjautuaUlos')}
        />
        <ButtonBlock variant="section">
          <Button
            onClick={openFrontPage}
            variant={'secondary'}
            iconRight={<IconArrowRight />}
            data-test-id={mkValmisTestId('asiointi-front-page-link-button')}
          >
            {t('siirryPalvelunEtusivulle')}
          </Button>
        </ButtonBlock>
      </Block>
    </ResponsiveBorderedContentBlock>
  )
})

export default ValmisPage

const translations = {
  OMAISUUSLUETTELO: {
    kiitosHeading: 'kiitosOmaisuusluetteloOnLahetetty',
    tositteetInfo: 'omaisuusluetteloLahetettyInfo1',
    palauteInfo: 'omaisuusluetteloAnnaPalautettaInfo1',
  },
  VUOSITILI: {
    kiitosHeading: 'kiitosVuositiliOnLahetetty',
    tositteetInfo: 'muistaTositteetInfo',
    palauteInfo: 'vuositiliAnnaPalautettaInfo',
  },
  PAATOSTILI: {
    kiitosHeading: 'kiitosPaatostiliOnLahetetty',
    tositteetInfo: 'muistaTositteetInfo',
    palauteInfo: 'paatostiliAnnaPalautettaInfo',
  },
}
