import React, { useId } from 'react'
import { styled } from 'styled-components'
import {
  Block,
  Button,
  IconPlus,
  IconRemove,
  Link,
  LoadingSpinner,
  Paragraph,
  StatusText,
  suomifiDesignTokens,
  Text,
  VisuallyHidden,
} from 'suomifi-ui-components'
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone'
import { filesize, FileSizeOptionsString } from 'filesize'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Extension, MimeType } from 'common/src/vtj/MimeType.enum'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { FileIcon } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/file/FileIcon'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'

export type FileBoxFile = {
  id: string
  name: string
  sizeBytes: number
  mimeType: MimeType
  statusText?: string
  downloadHref?: string
  status: 'success' | 'loading' | 'failed'
  errorMessage?: string
  isRemovable?: boolean
}

const FileBox: React.FC<{
  files: FileBoxFile[]
  onAdd: (
    acceptedFiles: File[],
    // Note that file size rejections only appear here. They are not detected during dragging.
    fileRejections: FileRejection[],
    event: DropEvent
  ) => unknown
  onRemove: (file: FileBoxFile) => unknown
  instructions?: string | React.ReactNode
  errorMessage?: string
  allowedMimeTypes?: MimeType[]
  minFileSizeBytes?: number
  maxFileSizeBytes?: number
  htmlId?: string
  className?: string
  'data-test-id'?: string
}> = ({
  files,
  onAdd,
  onRemove,
  instructions,
  errorMessage,
  allowedMimeTypes,
  minFileSizeBytes,
  maxFileSizeBytes,
  htmlId,
  className,
  'data-test-id': rootTestId,
}) => {
  const [t, i18n] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const generalErrorTextId = useId()
  const mkTestId = getTestIdFn(rootTestId)

  const accept = allowedMimeTypes
    ? allowedMimeTypes.reduce((acc, mimeType) => {
        acc[mimeType] = [Extension[mimeType]]
        return acc
      }, {} as Record<string, string[]>)
    : undefined

  const { getRootProps, getInputProps, open, isDragActive, isDragReject } =
    useDropzone({
      multiple: true,
      onDrop: onAdd,
      noClick: true,
      noKeyboard: true,
      accept,
      minSize: minFileSizeBytes,
      maxSize: maxFileSizeBytes,
    })

  let instructionsNode: React.ReactNode
  if (typeof instructions === 'string' || !instructions) {
    instructionsNode = (
      <Paragraph>
        <Text smallScreen>
          {instructions ??
            getDefaultInstructions(t, {
              allowedMimeTypes,
              maxFileSizeBytes,
              language: i18n.language,
            })}
        </Text>
      </Paragraph>
    )
  } else if (instructions) {
    instructionsNode = instructions
  }

  const isErrorGiven = Boolean(errorMessage)
  const isAnyError = isErrorGiven || isDragReject

  const boxClassNames: string[] = []
  if (isDragActive) {
    boxClassNames.push('file-box--drag')
  }
  if (isErrorGiven) {
    boxClassNames.push('file-box--error')
  }
  if (isDragReject) {
    boxClassNames.push('file-box--drag-reject')
  }

  return (
    <section
      id={htmlId}
      className={className}
      data-test-id={mkTestId()}
      data-test-error-message={errorMessage}
    >
      <OuterBox
        {...getRootProps({
          className: boxClassNames.join(' '),
          'aria-describedby': generalErrorTextId,
          'data-test-id': mkTestId('laatikko'),
        })}
      >
        <InstructionsLabel>
          {t('liitaTaiRaahaaTiedostotTahan')}
        </InstructionsLabel>
        {instructionsNode && (
          <Block mt="xxs" data-test-id={mkTestId('ohjeet')}>
            {instructionsNode}
          </Block>
        )}
        {files.map((file, index) => (
          <React.Fragment key={file.id}>
            <Block mt="m" />
            <FileComponent
              file={file}
              onRemove={() => onRemove(file)}
              mkTestId={(...ids) => mkTestId('tiedosto', index, ...ids)}
            />
          </React.Fragment>
        ))}
        <Block mt="m">
          <input {...getInputProps()} data-test-id={mkTestId('file-input')} />
          <Button
            onClick={open}
            variant="secondary"
            icon={<IconPlus />}
            fullWidth={!isTablet}
            data-test-id={mkTestId('lisaa-tiedosto-painike')}
          >
            {t('lisaaTiedosto')}
          </Button>
        </Block>
      </OuterBox>
      <div>
        {isAnyError && <Block mt="xxs" />}
        <StatusText
          ariaLiveMode="off"
          id={generalErrorTextId}
          status={isAnyError ? 'error' : 'default'}
          data-test-id={mkTestId('virheviesti')}
        >
          {isDragReject
            ? t('oletRaahaamassaTiedostojaJoitaEiHyvaksyta')
            : errorMessage}
        </StatusText>
      </div>
    </section>
  )
}

export default FileBox

type TestIdFn = (...ids: Array<string | number>) => string | undefined
const getTestIdFn = (rootId: string | undefined): TestIdFn =>
  !rootId
    ? () => undefined
    : (...ids: Array<string | number>) => `${[rootId, ...ids].join('-')}`

const getDefaultInstructions = (
  t: TFunction,
  {
    allowedMimeTypes,
    maxFileSizeBytes,
    language,
  }: {
    allowedMimeTypes?: MimeType[]
    maxFileSizeBytes?: number
    language: string
  }
): string => {
  const texts: string[] = []
  if (maxFileSizeBytes) {
    texts.push(
      t('yksittaisenTiedostonMaksimikokoOnX', {
        size: filesize(maxFileSizeBytes, {
          ...getFilesizeOptions(language),
          round: 0,
          roundingMethod: 'floor',
        }),
      })
    )
  }
  if (allowedMimeTypes) {
    const formats = allowedMimeTypes
      .map((mimeType) => Extension[mimeType].replace('.', ''))
      .join(', ')
    texts.push(
      t('sallitutTiedostomuodotOvatX', {
        formats,
      })
    )
  }
  return texts.join(' ')
}

const getFilesizeOptions = (language: string): FileSizeOptionsString => {
  return {
    standard: 'jedec',
    locale: language === 'sv' ? 'sv-SE' : 'fi-FI',
    output: 'string',
    round: 0,
  }
}

const OuterBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${suomifiDesignTokens.spacing.m};

  background-color: ${suomifiDesignTokens.colors.highlightLight4};
  border: 1px dashed ${suomifiDesignTokens.colors.highlightBase};
  border-radius: 2px;

  &.file-box--drag {
    border-width: 2px;
    background-color: ${suomifiDesignTokens.colors.highlightLight3};
  }

  &.file-box--error {
    border: 2px solid ${suomifiDesignTokens.colors.alertBase};
  }

  &.file-box--drag-reject {
    border: 2px dashed ${suomifiDesignTokens.colors.alertBase};
    background-color: ${suomifiDesignTokens.colors.alertLight1};
  }
`

const InstructionsLabel = styled(Text)`
  font-size: 16px;
  line-height: 1.25;
  font-weight: 600;
`

const FileComponent: React.FC<{
  file: FileBoxFile
  onRemove: () => unknown
  mkTestId: TestIdFn
}> = ({ file, onRemove, mkTestId }) => {
  const [t, i18n] = useTranslation()
  const fileErrorTextId = useId()
  const isTablet = useDeviceContext().tablet
  const isRemovable = file.isRemovable ?? file.status !== 'loading'

  return (
    <section data-test-id={mkTestId()} data-file-id={file.id}>
      <FileContainer status={file.status} aria-describedby={fileErrorTextId}>
        {isTablet && (
          <FileIconContainer>
            <FileIcon mimeType={file.mimeType} />
          </FileIconContainer>
        )}
        <FileInfoContainer>
          <div>
            {file.downloadHref ? (
              <Link
                href={file.downloadHref}
                target="_blank"
                underline="hover"
                data-test-id={mkTestId('linkki')}
              >
                <span data-test-id={mkTestId('nimi')}>{file.name}</span>{' '}
                <VisuallyHidden>{t('opensInNewWindow')}</VisuallyHidden>
              </Link>
            ) : (
              <span data-test-id={mkTestId('nimi')}>{file.name}</span>
            )}
          </div>
          <div>
            <Text
              variant="body"
              smallScreen
              color="depthDark1"
              data-test-id={mkTestId('koko')}
            >
              {filesize(file.sizeBytes, getFilesizeOptions(i18n.language))}
            </Text>
          </div>
          <div
            aria-live="assertive"
            aria-busy={file.status === 'loading'}
            data-test-id={mkTestId('indikaattori')}
          >
            <StyledLoadingSpinner
              variant="small"
              status={file.status}
              textAlign="right"
              text={getLoadingStatus(t, file)}
            />
          </div>
        </FileInfoContainer>
        {isRemovable && (
          <RemoveButtonContainer>
            <RemoveButton
              onClick={onRemove}
              variant="secondaryNoBorder"
              icon={<IconRemove />}
              data-test-id={mkTestId('poista-painike')}
            >
              {t('poista')}
            </RemoveButton>
          </RemoveButtonContainer>
        )}
      </FileContainer>
      <div>
        {file.status === 'failed' && <Block mt="xs" />}
        <StatusText
          id={fileErrorTextId}
          status={file.status === 'failed' ? 'error' : 'default'}
          data-test-id={mkTestId('virheviesti')}
        >
          {file.status === 'failed' ? file.errorMessage : ''}
        </StatusText>
      </div>
    </section>
  )
}

const getLoadingStatus = (t: TFunction, file: FileBoxFile): string => {
  if (file.statusText) {
    return file.statusText
  } else if (file.status === 'loading') {
    return t('tiedostoaUploadataan')
  } else if (file.status === 'failed') {
    return t('uploadEpaonnistui')
  } else {
    return t('tiedostoLisatty')
  }
}

const FileContainer = styled.div<{
  status: FileBoxFile['status']
}>`
  padding: ${suomifiDesignTokens.spacing.xs};
  background-color: ${suomifiDesignTokens.colors.depthLight3};
  border: ${(props) =>
    props.status === 'failed'
      ? `2px solid ${suomifiDesignTokens.colors.alertBase}`
      : `1px solid ${suomifiDesignTokens.colors.depthLight1}`};

  @media screen and ${device.tablet} {
    display: flex;
    flex-direction: row;
    column-gap: ${suomifiDesignTokens.spacing.xs};
  }
`

const StyledLoadingSpinner = styled(LoadingSpinner)`
  margin: ${suomifiDesignTokens.spacing.insetS} 0;

  &.fi-loadingSpinner.fi-loadingSpinner--small {
    .fi-loadingSpinner_text {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
    }
  }
`

const FileIconContainer = styled.div`
  @media screen and ${device.tablet} {
    padding-top: 3px; // Artesanal, aesthetically pleasing alignment with file name
  }
`

const FileInfoContainer = styled.div`
  @media screen and ${device.tablet} {
    margin-right: auto;
  }
`

const RemoveButton = styled(Button)`
  padding: ${suomifiDesignTokens.spacing.insetS} 0;

  @media screen and ${device.tablet} {
    display: inline-flex;
    flex-direction: column;
    padding: ${suomifiDesignTokens.spacing.xs} ${suomifiDesignTokens.spacing.m};

    .fi-button_icon {
      margin: 0 auto ${suomifiDesignTokens.spacing.insetM} auto;
      & > .fi-icon {
        margin: 0;
      }
    }
  }
`

const RemoveButtonContainer = styled.div``
