import React from 'react'
import { useTranslation } from 'react-i18next'
import { styled } from 'styled-components'
import {
  Block,
  ExternalLink,
  suomifiDesignTokens,
  Text,
} from 'suomifi-ui-components'
import { observer } from 'mobx-react'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import {
  cookiePageUrl,
  dvvContactInfoUrl,
  getEdunvalvonnanAsiointiFeedbackUrl,
  saavutettavuusselosteUrl,
  tietosuojaselosteUrl,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/links'
import { useAsiointiUserStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import { CenteredWidthLimitedDiv } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import logoFiUrl from 'common-ui-components/src/assets/images/logo-dvv-white-fi.svg'
import logoSvUrl from 'common-ui-components/src/assets/images/logo-dvv-white-sv.svg'

const MainFooter: React.FC = observer(() => {
  const { t, i18n } = useTranslation()
  const userStore = useAsiointiUserStore()
  const logoUrl = i18n.language === 'sv' ? logoSvUrl : logoFiUrl

  const mkFooterTestId = (...ids: ChildTestId[]): string =>
    mkTestId('footer', ...ids)

  return (
    <FooterBackground>
      <ColorBar />
      <FooterContent data-test-id={mkFooterTestId('main')}>
        <DvvLogo src={logoUrl} alt={t('dvv')} />
        <Block my="m">
          <Text color="whiteBase">{t('serviceOwners')}</Text>
          <Block mt="m">
            <StyledExternalLink
              data-test-id={mkFooterTestId('feedback-link')}
              labelNewWindow={t('avautuuUuteenValilehteen')}
              href={getEdunvalvonnanAsiointiFeedbackUrl(userStore.lang)}
            >
              {t('annaPalautettaPalvelusta')}
            </StyledExternalLink>
          </Block>
        </Block>
        <Ruler />
        <Block my="m">
          <Text variant="bold" color="whiteBase">
            {t('yhteystiedot')}
          </Text>
          <Block mt="m">
            <StyledExternalLink
              data-test-id={mkFooterTestId('dvv-contacts-link')}
              labelNewWindow={t('avautuuUuteenValilehteen')}
              href={dvvContactInfoUrl(userStore.lang)}
            >
              {t('digiJaVaestotietovirastonYhteystiedot')}
            </StyledExternalLink>
          </Block>
          <Block mt="m">
            <StyledExternalLink
              data-test-id={mkFooterTestId('data-privacy-link')}
              labelNewWindow={t('avautuuUuteenValilehteen')}
              href={tietosuojaselosteUrl(userStore.lang)}
            >
              {t('tietosuoja')}
            </StyledExternalLink>
          </Block>
          <Block mt="m">
            <StyledExternalLink
              data-test-id={mkFooterTestId('accessibility')}
              labelNewWindow={t('avautuuUuteenValilehteen')}
              href={saavutettavuusselosteUrl(userStore.lang)}
            >
              {t('saavutettavuus')}
            </StyledExternalLink>
          </Block>
          <Block mt="m">
            <StyledExternalLink
              data-test-id={mkFooterTestId('cookies')}
              labelNewWindow={t('avautuuUuteenValilehteen')}
              href={cookiePageUrl}
            >
              {t('evasteet')}
            </StyledExternalLink>
          </Block>
        </Block>
      </FooterContent>
    </FooterBackground>
  )
})

export default MainFooter

type ChildTestId = string | number

const mkTestId = (...ids: ChildTestId[]): string => {
  return `asiointi-frame-${ids.join('-')}`
}

const FooterBackground = styled.footer`
  background-color: ${suomifiDesignTokens.colors.brandBase};
`

const FooterContent = styled(CenteredWidthLimitedDiv)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0;

  @media screen and ${device.tablet} {
    flex-direction: row;
    gap: ${suomifiDesignTokens.spacing.xxl};
  }
`

const ColorBar = styled.div`
  display: flex;
  width: 100%;
  height: 10px;

  &:before {
    content: '';
    display: inline-block;
    background-color: #69d8d7;
    width: 70%;
  }
  &:after {
    content: '';
    display: inline-block;
    background-color: #e30450;
    width: 30%;
  }
`

const DvvLogo = styled.img`
  width: 160px;
  height: 100%;
  @media screen and ${device.tablet} {
    width: 200px;
  }
`

const StyledExternalLink = styled(ExternalLink).attrs(() => ({
  underline: 'initial',
  toNewWindow: true,
}))`
  &&& {
    color: ${suomifiDesignTokens.colors.whiteBase};
    &:visited {
      color: ${suomifiDesignTokens.colors.whiteBase};
    }
  }
`

const Ruler = styled.div`
  border-top: 1px solid ${suomifiDesignTokens.colors.whiteBase};
  align-self: stretch;

  @media screen and ${device.tablet} {
    border-top: none;
    border-left: 1px solid ${suomifiDesignTokens.colors.whiteBase};
  }
`
