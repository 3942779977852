import React from 'react'
import { useTranslation } from 'react-i18next'
import { observer } from 'mobx-react'
import { getTili } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import { formatNumberWithComma } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/input-output.util'
import AttachmentList from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FileList'
import { Block, Heading, Paragraph } from 'suomifi-ui-components'
import { mkYhteenvetoTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import { EdunvalvontasuhdeType } from 'tilintarkastus-common/src/vtj/types/edunvalvontasuhde/edunvalvontasuhde-enums'

const Palkkio: React.FC = observer(() => {
  const [t] = useTranslation()
  const tili = getTili()
  const {
    vaatimus: { guardianshipType },
  } = getTilintarkastusStore()
  const {
    palkkio: {
      veloitatkoPalkkiota,
      palkkioMaara,
      kulukorvausMaara,
      veloitatkoKulukorvauksia,
      kululaskelma,
    },
  } = tili

  return (
    <section data-test-id={mkYhteenvetoTestId('palkkio-container')}>
      <Heading variant="h3">{t('palkkio')}</Heading>
      {EdunvalvontasuhdeType.EDUNVALVONTA_LAKIMAARAINEN !==
        guardianshipType && (
        <Block mt="xs">
          <Heading variant="h5" as="h4">
            {t('veloitatkoPalkkiotaTaltaTilikaudelta')}
          </Heading>
          <Paragraph>
            {veloitatkoPalkkiota
              ? `${t('kylla')}: ${
                  palkkioMaara || palkkioMaara === 0
                    ? formatNumberWithComma(palkkioMaara)
                    : ''
                } €`
              : t('eiLuovunPalkkiostaTilikaudella')}
          </Paragraph>
        </Block>
      )}

      <Block mt="xs">
        <Heading variant="h5" as="h4">
          {t('veloitatkoKulukorvauksiaTaltaTilikaudelta')}
        </Heading>
        <Paragraph>
          {veloitatkoKulukorvauksia
            ? `${t('kylla')}: ${
                kulukorvausMaara || kulukorvausMaara === 0
                  ? formatNumberWithComma(kulukorvausMaara)
                  : ''
              } €`
            : t('eiLuovunkorvauksistaTilikaudella')}
        </Paragraph>
      </Block>
      <AttachmentList attachments={kululaskelma} />
    </section>
  )
})

export default Palkkio
