import { styled } from 'styled-components'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react'
import { throttle } from 'lodash'
import LupaNavigation from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/LupaNavigation'
import {
  Block,
  Heading,
  InlineAlert,
  suomifiDesignTokens,
} from 'suomifi-ui-components'
import NavigationFooter, {
  FooterPosition,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/footer/NavigationFooter'
import {
  isApplicationStep,
  pathByViewId,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-application-routes'
import {
  canAccessPath,
  getDefaultView,
  getStore,
  initAsiointiStore,
  isStoreInitialized,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/store/asiointi.store'
import { mkHakemusTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/lupa-asiointi-test-id'
import { device } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/breakpoints'
import { useTranslation } from 'react-i18next'
import {
  useAsiointiUser,
  useAsiointiUserStore,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/ui/store/holhous-asiointi-user-store'
import MainFooter from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/MainFooter'
import { useElementSizeObserver } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/element-size-observer'
import {
  centeredWidthLimited,
  focusOutline,
} from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/containers'
import PageLoading from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/PageLoading'
import UnexpectedError from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/UnexpectedError'
import { useAutoFocus } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/auto-focus'
import { useRedirectUnauthenticatedToLogIn } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/login-redirect'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { isInsideBrowser } from 'common/src/vtj/browser/browser-util'
import { Country } from 'holhous-common/src/vtj/country/country.type'
import { AsiointiProductCatalog } from 'lupa-backend/src/vtj/asiointi/product-catalog/asiointi-product-catalog-api.type'
import { getCountries } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/api/country-api'
import { getCurrentProductCatalog } from 'edunvalvonta-asiointi/src/vtj/asiointi/luvat/ui/api/product-catalog-api'

const LupaApplicationPage = observer(() => {
  const [isLoading, setLoading] = useState(!isStoreInitialized())
  const [initError, setInitError] = useState<Error | undefined>()
  const { lang } = useAsiointiUserStore()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const isRedirected = useRedirectUnauthenticatedToLogIn()
  if (isRedirected) {
    return null
  }
  const user = useAsiointiUser()

  useEffect(() => {
    const initAndHandleErrors = async () => {
      setLoading(true)
      try {
        const [countries, productCatalog]: [Country[], AsiointiProductCatalog] =
          await Promise.all([getCountries(), getCurrentProductCatalog()])
        await initAsiointiStore(user, lang, countries, productCatalog)
        redirectIfNotCanAccessStep()
      } catch (err) {
        setInitError(
          err instanceof Error ? err : new Error('Store initialization failed')
        )
        setLoading(false)
      }
    }

    const redirectIfNotCanAccessStep = () => {
      if (!canAccessPath(pathname)) {
        const defaultStep = getDefaultView()
        setLoading(true)
        navigate(pathByViewId[defaultStep])
      } else {
        setLoading(false)
      }
    }

    // useEffect is only called in a browser(-like) environment. If asiointi store initialization
    // is ever done server-side, we must take care to initialize the store from scratch for every request
    // to avoid mixing up different users' data (and the same user's data from different devices/tabs).
    if ((!isInsideBrowser() || !isStoreInitialized()) && !initError) {
      void initAndHandleErrors()
    } else if (isStoreInitialized()) {
      redirectIfNotCanAccessStep()
    } else {
      setLoading(false)
    }
  }, [lang, user, initError, navigate, pathname])

  if (initError) {
    return (
      <UnexpectedError
        error={initError}
        data-test-id={mkHakemusTestId('init-error')}
      />
    )
  }

  if (isLoading) {
    return <PageLoading data-test-id={mkHakemusTestId('loader')} />
  }

  return <LoadedApp />
})

const LoadedApp: React.FC = observer(() => {
  const [t] = useTranslation()
  const { pathname } = useLocation()
  const isOnApplicationStep = isApplicationStep(pathname)
  const isTablet = useDeviceContext().tablet
  const store = getStore()
  const mainHeadingRef = useAutoFocus<HTMLHeadingElement>()

  const [footerHeight, setFooterHeight] = useState(0)
  const [footerPosition, setFooterPosition] = useState<'fixed' | 'static'>(
    getFooterPosition()
  )

  const footerSizeObserver = useCallback(
    (entry: ResizeObserverEntry | null) => {
      const newFooterHeight = entry?.contentRect.height ?? 0
      setFooterHeight(newFooterHeight)
    },
    []
  )
  useElementSizeObserver('#lupa-navigation-footer', footerSizeObserver)

  const windowSizeListener = useMemo(
    () =>
      throttle(() => setFooterPosition(getFooterPosition()), 100, {
        leading: true,
      }),
    []
  )
  useEffect(() => {
    window.addEventListener('resize', windowSizeListener)
    return () => {
      window.removeEventListener('resize', windowSizeListener)
    }
  }, [windowSizeListener])

  return (
    <App footerHeight={footerHeight} footerPosition={footerPosition}>
      <Main
        footerHeight={footerHeight}
        footerPosition={footerPosition}
        data-test-id={mkHakemusTestId('main')}
      >
        <MainHeading
          variant="h1"
          smallScreen={!isTablet}
          // Initial page load's focus on main heading
          ref={store.visitedSteps.size === 0 ? mainHeadingRef : undefined}
          tabIndex={-1}
          data-test-id={mkHakemusTestId('paaotsikko')}
        >
          {t('luvanHakeminen')}
        </MainHeading>
        <MainContent>
          {isOnApplicationStep && (
            <NavigationAndNotifications
              data-test-id={mkHakemusTestId('vaiheet-ja-ilmoitukset')}
            >
              <LupaNavigation />
              <Block mt={isTablet ? 'xs' : 'm'} />
              <InlineAlert
                id="cannotBeSavedAlert"
                status="neutral"
                ariaLiveMode="off"
                smallScreen={!isTablet}
                labelText={t('keskeneraistaHakemustaEiVoiTallentaaLabel')}
              >
                {t('keskeneraistaHakemustaEiVoiTallentaaBodyText')}
              </InlineAlert>
            </NavigationAndNotifications>
          )}
          <ApplicationDetails>
            <Outlet />
          </ApplicationDetails>
        </MainContent>
      </Main>
      {isOnApplicationStep ? (
        <NavigationFooter position={footerPosition} />
      ) : (
        <MainFooter />
      )}
    </App>
  )
})

// This assumes that the max height is known at various viewport widths.
// We don't include the footer height in the actual calculation because the footer
// height is dynamic. Calculation using actual footer height would cause footer jumping in the UI.
const getFooterPosition = (): FooterPosition => {
  let footerMaxHeight: number
  if (window.innerWidth < 560) {
    footerMaxHeight = 112 // Buttons on two rows
  } else {
    footerMaxHeight = 62 // Buttons on one row
  }

  // The actual footer height is dynamic and depends on visible buttons in the footer.
  // We use max footer height instead of actual footer height to prevent footer jumping in the UI
  // when moving between stages and when adding lupas. Now the footer jumps only when window size is changed.
  return window.innerHeight < 5 * footerMaxHeight ? 'static' : 'fixed'
}

const App = styled.div<{
  footerHeight: number
  footerPosition: FooterPosition
}>`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
  margin-bottom: ${({ footerHeight, footerPosition }) =>
    footerPosition === 'fixed' ? footerHeight : 0}px;
`

const Main = styled.main<{
  footerHeight: number
  footerPosition: FooterPosition
}>`
  ${centeredWidthLimited};

  padding: ${suomifiDesignTokens.spacing.s} 0;

  @media ${device.tablet} {
    padding: ${suomifiDesignTokens.spacing.xl} ${suomifiDesignTokens.spacing.xl};
  }

  ${({ footerHeight, footerPosition }) => {
    const minScrollMargin = 15
    const scrollMarginBottom =
      footerPosition === 'fixed'
        ? minScrollMargin + footerHeight
        : minScrollMargin
    // Ensure elements are scrolled to view on focus so that the nav footer does not cover them
    return `& * {
      scroll-margin-top: ${minScrollMargin}px;
      scroll-margin-bottom:  ${scrollMarginBottom}px
    }`
  }}
`

const MainHeading = styled(Heading)`
  ${focusOutline};

  margin: 0 ${suomifiDesignTokens.spacing.s} ${suomifiDesignTokens.spacing.xs}
    ${suomifiDesignTokens.spacing.s};

  @media ${device.tablet} {
    margin: 0 0 ${suomifiDesignTokens.spacing.xl} 0;
  }
`

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${suomifiDesignTokens.spacing.m};

  @media screen and ${device.tablet} {
    flex-direction: row;
    gap: ${suomifiDesignTokens.spacing.s};
  }
`

const NavigationAndNotifications = styled.div`
  margin: 0 ${suomifiDesignTokens.spacing.s};

  @media ${device.tablet} {
    max-width: 330px;
    margin: 0;
  }
`

const ApplicationDetails = styled.section`
  flex: 1;
`

export default LupaApplicationPage
