import React from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import { useDeviceContext } from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/breakpoints/device-context'
import { Block, Heading, InlineAlert, Paragraph } from 'suomifi-ui-components'
import { mkYleiskatsausTestId } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/tilintarkastus-asiointi-test-id'
import { commonXssValidator } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/form-validator.util'
import { isOmaisuusluettelo } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tili.store'
import FormTextArea from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormTextArea'
import FormAttachmentFileBox from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormAttachmentFileBox'
import { TiliAsiakirjaType } from 'tilintarkastus-common/src/vtj/types/attachment.type'
import { action } from 'mobx'
import FormRadioButtonGroup from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/common/FormRadioButtonGroup'
import { getTilintarkastusStore } from 'edunvalvonta-asiointi/src/vtj/asiointi/tilintarkastus/ui/store/tilintarkastus.store'
import AriaFriendlyInlineAlert from 'edunvalvonta-asiointi/src/vtj/asiointi/common/ui/components/AriaFriendlyInlineAlert'

const HallintaoikeudetForm: React.FC = observer(() => {
  const [t] = useTranslation()
  const isTablet = useDeviceContext().tablet
  const verticalMarginToken = isTablet ? 'm' : 's'

  const {
    lomake: { tili, lomakkeelleValitutTiedot, tarkistetutEsitaytetytTiedot },
  } = getTilintarkastusStore()
  const { hallintaoikeudet } = tili.sopimustiedot

  return (
    <section data-test-id={mkYleiskatsausTestId('hallintaoikeudet-container')}>
      <Heading variant="h3" mb="xs">
        {t('hallintaoikeudet')}
      </Heading>
      <Paragraph>
        {isOmaisuusluettelo()
          ? t('ohjeOnkoHallintaoikeuksia')
          : t('ohjeIlmoitaMitaHallintaoikeuksia')}
      </Paragraph>

      {tarkistetutEsitaytetytTiedot?.['hallintaoikeudet'] === false && (
        <AriaFriendlyInlineAlert
          status="warning"
          mt="s"
          mb="s"
          data-test-id={mkYleiskatsausTestId('tarkista-esitaytetyt-tiedot')}
        >
          {t('tarkistaEsitaytetytTiedot')}
        </AriaFriendlyInlineAlert>
      )}

      <Block mt={verticalMarginToken}>
        <FormRadioButtonGroup
          required
          value={lomakkeelleValitutTiedot.hallintaoikeudet?.toString()}
          updateValue={action((value) => {
            lomakkeelleValitutTiedot.hallintaoikeudet = value === 'true'
          })}
          labelText={
            isOmaisuusluettelo()
              ? t('onkoHallintaoikeusiaToisenOmaisuuteen')
              : t('onkoOllutSovittujaHallintaoikeuksia')
          }
          data-test-id={mkYleiskatsausTestId('onko-hallintaoikeuksia-radio')}
          radioButtons={[
            { value: 'false', labelText: t('ei') },
            { value: 'true', labelText: t('kylla') },
          ]}
        />
      </Block>

      {lomakkeelleValitutTiedot.hallintaoikeudet && (
        <>
          <Block mt={verticalMarginToken}>
            <FormTextArea
              data-test-id={mkYleiskatsausTestId(
                'miten-hallintaoikeuksista-on-sovittu'
              )}
              labelText={t('mitenHallintaoikeuksistaOnSovittu')}
              maxLength={1000}
              required
              value={hallintaoikeudet.mitenSovittu}
              validate={commonXssValidator(t)}
              updateValue={action((value) => {
                hallintaoikeudet.mitenSovittu = value
              })}
            />
          </Block>
          <Block mt={verticalMarginToken}>
            <FormAttachmentFileBox
              observableAttachments={hallintaoikeudet.sopimukset}
              asiakirjaTypeId={TiliAsiakirjaType.SOPIMUS}
              data-test-id={mkYleiskatsausTestId('hallintaoikeus-sopimukset')}
              title={t('sopimusHallintaoikeudesta')}
            />
          </Block>
        </>
      )}
      {lomakkeelleValitutTiedot.hallintaoikeudet === false &&
        (!!hallintaoikeudet.mitenSovittu ||
          hallintaoikeudet.sopimukset.length > 0) && (
          <InlineAlert mt={'s'} status={'warning'}>
            {t('valittuEiInlineAlert')}
          </InlineAlert>
        )}
    </section>
  )
})

export default HallintaoikeudetForm
