import keyMirror from 'keymirror'

export const SeurantaAsiaVaatimusTila = keyMirror({
  VOIMASSA: null,
  PAATETTY: null,
  MITATOITY: null,
  MAARITTELEMATON: null,
})

export type SeurantaAsiaVaatimusTilaId =
  (typeof SeurantaAsiaVaatimusTila)[keyof typeof SeurantaAsiaVaatimusTila]
